<template>
  <div class="">
    <div class="news-title">{{ info.title }}</div>
    <div class="news-time">{{ info.createtime }}</div>
    <div class="news-content" v-html='info.content'></div>
  </div>
</template>

<script>
import { afficheDetail } from '@/api/ltl/news'
export default {
  props: {
    id: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      info: {}
    }
  },
  mounted(){
    this.getAfficheDetail()
  },
  methods: {
    getAfficheDetail(){
      afficheDetail({
        id: this.id,
        u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id
      }).then(res => {
        this.info = res.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .news-title{
    font-size: 18px;
    text-align: center;
    width: 100%;
    line-height: 36px;
  }
  .news-time{
    font-size: 14px;
    text-align: right;
    width: 100%;
    color: #ccc;
    margin-bottom: 10px;
  }
</style>