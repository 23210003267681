<template>
  <el-container>
    <el-aside :style="{width: isCollapse ? '60px': '230px'}" style="background-color: rgb(238, 241, 246)">
      <Side ref="sideChild"></Side>
    </el-aside>
    <el-container class="x-contets">
      <el-header style="text-align: right; font-size: 12px">
        <Top :triggerMethod="triggerSideMethod" @calc="calc"></Top>
      </el-header>
      <el-main>
        <router-view/>
      </el-main>
    </el-container>
    <!-- 提示 -->
    <!-- <div class="tips">
      <Tip></Tip>
    </div> -->
    <!-- 计算器 -->
    <el-dialog
      title="计算器"
      append-to-body
      :visible.sync="calcVisible"
      width="1280px"
    >
      <Calc
        v-if="calcVisible"
        @closeCalc='closeCalc'
      />
    </el-dialog>
  </el-container>
</template>

<script>
import Top from './top/top'
import Side from './sidebar/side'
import Calc from '@/components/calc'
// import Tip from '@/components/tip'
export default {
  components: {
    Top,
    Side,
    Calc
    // Tip
  },
  data() {
    return {
      isCollapse: false,
      calcVisible: false,

    };
  },
  methods: {
    triggerSideMethod(e) {
      this.isCollapse = e
      // this.$refs.sideChild.childSideMethod(e)
      const childComponent = this.$refs.sideChild;
      childComponent.receiveData(e);
    },
    // 打开计算器
    calc(){
      this.calcVisible = true
    },
    // 关闭计算器
    closeCalc(){
      location.reload();
      this.calcVisible = false
    }
  }
};
</script>
<style lang='scss' scoped>
.el-header {
  line-height: 60px;
  height: 50px !important;
  padding: 0 !important;
}
.x-contets{
  height: 100vh;
}
.el-aside {
  color: #333;
}
.el-main{
  background-color: #F0F2F5;
  box-sizing: border-box;
  padding: 10px !important; 
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  // overflow: auto;
}
  // .tips{
  //   position: fixed;
  //   right: 0px;
  //   bottom: 140px;
  // }
</style>