<template>
  <div class="main">
    <el-row :gutter="20">
      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" v-for="item in data" :key="item.name">
        <div class="card" :style="'background-color: ' + item.color" @click="goToIndex(item.url)">
          <div class="name">{{ item.title }}</div>
          <!-- <el-statistic class="txt" :value="item.count" size="20" /> -->
          <div class="key">{{ item.key }}</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { packageType, platform } from "@/api/ltl/common";
export default {
  data() {
    return {
      data: [
        {
          count: 1,
          color: "rgba(110,79,237,0.5)",
          title: "LTL订单管理",
          key: "订",
          url: "/ltl/orderList",
        },
        {
          count: 1,
          color: "rgba(237,79,214,0.5)",
          title: "LTL报价管理",
          key: "报",
          url: "/ltl/rateList",
        },
        {
          count: 1,
          color: "rgba(79,134,237,0.5)",
          title: "LTL地址管理",
          key: "地",
          url: "/ltl/address",
        },
        {
          count: 1,
          color: "rgba(190,241,99,0.9)",
          title: "快递订单管理",
          key: "订",
          url: "/bill/list",
        },
        {
          count: 1,
          color: "rgba(241,218,99,0.8)",
          title: "快递地址管理",
          key: "地",
          url: "/bill/address",
        },
        {
          count: 1,
          color: "rgba(237,122,79,0.5)",
          title: "个人中心",
          key: "个",
          // url: '/ltl/user'
          url: "",
        },
      ],
    };
  },
  created() {
    this.getPlatform();
    this.getPackageType();
  },
  methods: {
    // 点击跳转
    goToIndex(url) {
      this.$router.push({ path: url });
    },
    // 获取用户所属平台
    getPlatform() {
      platform({
        u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
      }).then((res) => {
        if (res.code == 1) {
          window.localStorage.setItem("platform_name", res.data.name);
          window.localStorage.setItem("platform_id", res.data.id);
        }
      });
    },
    // 获取打托类型
    getPackageType() {
      packageType().then((res) => {
        if (res.code == 1) {
          window.localStorage.setItem("RapiddealsPallet", JSON.stringify(res.data.Rapiddeals));
          window.localStorage.setItem("Priority1Pallet", JSON.stringify(res.data.Priority1));
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  padding: 10px !important;
  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    margin-bottom: 20px;
  }
  .card {
    position: relative;
    height: 100px;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 12px;
    color: white;
    cursor: pointer;
    .name {
      margin: 0;
      font-size: 22px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .txt {
      margin: 0;
      font-size: 32px;
      line-height: 60px;
      font-weight: 700;
    }
    ::v-deep(.el-statistic__number) {
      font-size: 32px;
      line-height: 60px;
      font-weight: 700;
      color: #fff;
    }
    .key {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 40px;
      height: 40px;
      top: 10px;
      right: 10px;
      border: 2px solid #fff;
      border-radius: 100%;
      font-size: 30px;
      transform: rotate(-40deg);
      opacity: 0.3;
    }
    .key-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }
}
</style>
