<template>
  <div class="check">
    <p>经过校验，类型：{{ checkInfo.check_result == 2 ? '商业地址' : '住宅地址' }}</p>
    <div class="check-btn">
      <el-button type="primary" @click="handleSure" size='small'>确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checkInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      
    }
  },
  methods: {
    handleSure(){
      this.$emit('closeCheck')
    }
  }
}
</script>
<style lang="scss" scoped>
  .check{
    font-size: 14px;
    p{
      font-size: 14px;
      line-height: 16px;
    }
  }
  .check-btn{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
  }
</style>