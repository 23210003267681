<template>
  <div class="">
    <!-- FBA报价 -->
    <el-card class="box-card">
      <div class="search-form" style="text-align: left">
        <el-form
          ref="searchForm"
          :inline="true"
          size="small"
          :model="searchForm"
          label-width="80px"
          label-position="left"
          class="demo-form-inline"
          :rules="rules"
        >
          <el-form-item label="起始地" label-width="60px">
            <el-input
              clearable
              size="small"
              disabled
              placeholder="1101 S Milliken Ave Suite E, Ontario, CA 91761"
              style="width: 360px"
            ></el-input>
          </el-form-item>
          <el-form-item label="	仓库代码" prop="fba_code" label-width="90px">
            <el-input
              clearable
              size="small"
              v-model="searchForm.fba_code"
              placeholder="请输入仓库代码"
            ></el-input>
          </el-form-item>
          <el-form-item label="	托盘数" prop="pallets" label-width="70px">
            <el-input
              clearable
              size="small"
              v-model="searchForm.pallets"
              type='number' 
              :controls='false'
              :min="0"
              placeholder="请输入托盘数"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" size="small" @click="handleCalc('searchForm')">计算运费</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="calc-info">
        <span class="calc-title">亚马逊卡派运费</span>
        <div class="calc-box">
          <div class="calc-item">
            <div class="calc-item-title">仓库代码</div>
            <div class="calc-item-name">Warehouse Code</div>
            <div class="calc-item-value">{{ priceInfo.fba_code }}</div>
          </div>
          <div class="calc-item">
            <div class="calc-item-title">托盘数</div>
            <div class="calc-item-name">Pallets</div>
            <div class="calc-item-value">{{ priceInfo.pallets }}</div>
          </div>
          <div class="calc-item">
            <div class="calc-item-title">卡车运费</div>
            <div class="calc-item-name">Trucking fee</div>
            <div class="calc-item-value">${{ priceInfo.price ? priceInfo.price : 0 }}</div>
          </div>
        </div>
      </div>
      <div class="table-top">
        <span>TO:{{ info.company_name }}</span>
        <span>报价截止时间:{{ info.fba_end_time }}</span>
      </div>
      <div class="table-top" style="margin: 5px 0 10px 0;">
        <span>托盘尺寸要求:{{ info.fba_size_request }}</span>
      </div>
      <el-table :data="dataList" border size="small" height="600">
        <el-table-column type="index" :index="indexMethod" label="序号" width="60">
        </el-table-column>
        <el-table-column prop="fba" label="FBA" show-overflow-tooltip></el-table-column>
        <el-table-column
          prop="one_tray_price"
          label="40*48*72/板"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="vehicle_price"
          label="FTL Rate(28 Pallets)"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="warehouse_time"
          label="进仓时间"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { fbaData, fbaPrice } from "@/api/ltl/price";
export default {
  data() {
    return {
      searchForm: {},
      dataList: [],
      info: {},
      priceInfo: {},
      rules: {
        fba_code: [{ required: true, message: "请输入仓库代码", trigger: "blur" }],
        pallets: [{ required: true, message: "请输入托盘数", trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    // 获取列表
    init() {
      fbaData({
        u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
      }).then((res) => {
        if (res.code == 1) {
          this.dataList = [];
          this.dataList = res.data.list;
          this.info = res.data;
        }
      });
    },
    // table首列序号
    indexMethod(index) {
      return index + 1;
    },
    // 页数变化
    currentChange(current) {
      this.current = current;
      this.init();
    },
    // 重置筛选
    handleCalc(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = this.searchForm
          fbaPrice({
            u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
            ...form
          }).then(res => {
            if(res.code == 1){
              this.$message.success(res.msg)
              this.priceInfo = res.data
            }else{
              this.$message.error(res.msg)
            }
          }).catch(err => {
            this.$message.error(err.msg)
          })
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.pagization-box {
  overflow: hidden;
  margin-top: 10px;

  .el-pagination {
    float: right;
  }
}
/deep/ {
  .el-table {
    thead {
      color: #fff;
      font-weight: 500;
      background: #f5f7fa;
      color: #606266;
      & th {
        background-color: transparent;
      }
      & tr {
        background-color: transparent;
      }
    }
  }
}
.order-info {
  p {
    padding: 0;
    margin: 0;
  }
}
.table-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 12px;
  }
}
// 
.calc-info{
  .calc-title{
    font-size: 20px;
    font-weight: bold;
    display: block;
    text-align: center;
    line-height: 40px;
  }
  .calc-box{
    width: 500px;
    background: red;
    height: 100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .calc-item{
      flex: 1;
      flex-shrink: 0;
      div{
        height: 30px;
        background: #E8E9EF;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #878787;
        font-size: 12px;
        &:last-child{
          background: #ECF1F6;
          height: 40px;
          font-size: 20px;
          color: #409EFF;
        }
      }
    }
  }
}
  /deep/ input::-webkit-outer-spin-button,
  /deep/ input::-webkit-inner-spin-button {
    -webkit-appearance: none!important;
  }
</style>
