<template>
  <div class="">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      size="small"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="收发货地">
            <el-select v-model="value1" placeholder="请选择收发货地" style="width: 100%">
              <el-option label="Origin Location" :value="1"></el-option>
              <el-option label="Destination Location" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="地址类型" prop="addressFunction">
            <el-select
              v-model="ruleForm.addressFunction"
              placeholder="请选择地址类型"
              style="width: 100%"
            >
              <el-option label="发货地" value="0"></el-option>
              <el-option label="收货地" value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系人" prop="userName">
            <el-input v-model="ruleForm.userName" placeholder="请输入联系人"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系邮箱" prop="email">
            <el-input v-model="ruleForm.email" placeholder="请输入联系邮箱"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系电话" prop="phone">
            <el-input
              v-model="ruleForm.phone"
              placeholder="请输入联系电话"
              class="input-with-select"
            >
              <el-select v-model="ruleForm.phoneCode" slot="prepend" placeholder="请选择">
                <el-option label="United States" value="US"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="地址名称" prop="addressName">
            <el-input v-model="ruleForm.addressName" placeholder="请输入地址名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="地址1" prop="address1">
            <el-input v-model="ruleForm.address1" placeholder="请输入地址1"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="地址2" prop="address2">
            <el-input v-model="ruleForm.address2" placeholder="请输入地址2"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="邮编" prop="zipCode">
            <el-input
              v-model="ruleForm.zipCode"
              placeholder="请输入邮编"
              @blur="changeZipCode"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="城市" prop="city">
            <el-input v-model="ruleForm.city" placeholder="请输入城市"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="州/省" prop="province">
            <el-select v-model="ruleForm.province" placeholder="请选择省州/省" style="width: 100%">
              <el-option
                v-for="(item, index) in proList"
                :key="index"
                :label="`${item.province}-${item.province_code}`"
                :value="item.province_code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="国家" prop="country">
            <el-input v-model="ruleForm.country" placeholder="请选择省国家" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item prop="isDefault">
        <el-checkbox
          v-model="ruleForm.isDefault"
          :checked="ruleForm.isDefault == 0 ? true : false"
          true-label="0"
          false-label="1"
          name="isDefault"
          >设置成默认地址</el-checkbox
        >
      </el-form-item>
      <el-form-item style="text-align: right">
        <el-button size="small" type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button size="small" @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addressAdd, addressUpdate, provinceList, zipCode } from "@/api/ltl/address";
import { validatenull } from "@/common/common.js";
export default {
  props: {
    record: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      value1: "", // 没有传参的占位数据
      ruleForm: {
        country: "US",
        phoneCode: "US",
      },
      rules: {
        addressFunction: [{ required: true, message: "请选择地址类型", trigger: "change" }],
        userName: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        email: [{ required: true, message: "请输入联系邮箱", trigger: "blur" }],
        phoneCode: [{ required: true, message: "请选择电话区间", trigger: "change" }],
        phone: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
        addressName: [{ required: true, message: "请输入地址名称", trigger: "blur" }],
        address1: [{ required: true, message: "请输入地址1", trigger: "blur" }],
        zipCode: [{ required: true, message: "请输入邮编", trigger: "blur" }],
        city: [{ required: true, message: "请输入城市", trigger: "change" }],
        province: [{ required: true, message: "请选择省州/省", trigger: "change" }],
      },
      proList: [], // 国家-城市
      id: "",
    };
  },
  mounted() {
    this.getProvinceList();
    if (!validatenull(this.record)) {
      this.id = this.record.id;
      this.ruleForm = {
        ...this.record,
      };
    }
  },
  methods: {
    // 获取省
    getProvinceList() {
      provinceList().then((res) => {
        this.proList = res.data;
      });
    },

    // 通过邮编获取州城市
    changeZipCode(num) {
      zipCode({
        zipCode: this.ruleForm.zipCode,
      }).then((res) => {
        if (res.code == 1) {
          this.ruleForm = {
            ...this.ruleForm,
            province: res.data.province,
            city: res.data.city,
          };
        } else {
          this.ruleForm.province = "";
          this.ruleForm.city = "";
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = this.ruleForm;
          if (this.id) {
            addressUpdate({
              address_id: this.id,
              u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
              ...form,
              isDefault: form.isDefault ? form.isDefault : "1",
            })
              .then((res) => {
                if (res.code == 1) {
                  this.$message.success(res.msg);
                  this.$emit("ok");
                } else {
                  this.$message.error(res.msg);
                }
              })
              .catch((err) => {
                this.$message.error(err.msg);
              });
          } else {
            addressAdd({
              u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
              ...form,
              isDefault: form.isDefault ? form.isDefault : "1",
            })
              .then((res) => {
                if (res.code == 1) {
                  this.$message.success(res.msg);
                  this.$emit("ok");
                } else {
                  this.$message.error(res.msg);
                }
              })
              .catch((err) => {
                this.$message.error(err.msg);
              });
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.ruleForm = {
        country: "US",
        phoneCode: "US",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.code {
  font-size: 14px;
  cursor: pointer;
  color: #409eff;
  margin-left: 10px;
  flex: 1;
  text-align: right;
  width: 100px;
  float: right;
}
.input-with-select /deep/.el-input-group__prepend {
  width: 90px;
  background-color: #fff;
}
</style>
