<template>
  <div class="">
    <el-card>
      <el-tabs v-model="active" @tab-click="handleClick">
        <el-tab-pane label="零担" name="1">
          <OlPlace :id='id' v-if='active==1'></OlPlace>
        </el-tab-pane>
        <el-tab-pane label="整车" name="2">
          <ZlPlace :id='id'  v-if='active==2'></ZlPlace>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import OlPlace from './components/olPlace.vue';
import ZlPlace from "./components/zlPlace.vue";
export default {
  components: {
    OlPlace,
    ZlPlace,
  },
  data() {
    return {
      active: "1",
      id: ''
    };
  },
  created(){
    this.active = this.$route.query.active || '1'
    this.id = this.$route.query.id || ''
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>
<style lang="scss" scoped></style>
