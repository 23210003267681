import NProgress from 'nprogress';
import router from './index';
import 'nprogress/nprogress.css';
import { validatenull } from "@/common/common.js"
NProgress.configure({ showSpinner: false });
const whiteList = [
  '/home',
  '/help',
  '/company',
  '',
  '/',
  '/news',
  '/newsDetail',
  '/contactUs',
  '/login',
  '/register',
  '/trace',
];

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (JSON.parse(window.localStorage.getItem("userInfo1"))) {
    if (to.path === '/login') {
      next({ path: '/' })
      document.title = '联合快递LTL系统'
      NProgress.done()
    }else if(to.path == '/ltl/index'){
      if(!validatenull(to.query)){
        let data = JSON.parse(to.query.data)
        window.localStorage.setItem("userInfo1",JSON.stringify(data.data))
        next()
      }else{
        document.title = to.meta.title
        next()
      }
    }else {
      document.title = to.meta.title
      next()
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      document.title = to.meta.title
      next()
    } else if(to.path == '/ltl/index'){
      if(!validatenull(to.query)){
        let data = JSON.parse(to.query.data)
        window.localStorage.setItem("userInfo1",JSON.stringify(data.data))
        next()
      }else{
        next({ path: '/login' })
        document.title = '登录'
        NProgress.done()
      }
    }else{
      next({ path: '/login' })
      document.title = '登录'
      NProgress.done()
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
