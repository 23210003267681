<template>
  <div class="">
    <!-- 公告列表 -->
    <el-card class="box-card">
      <div class="search-form" style="text-align: left">
        <el-form ref="searchForm" :inline="true" size="small" :model="searchForm" label-width="80px" label-position="left"
          class="demo-form-inline">
          <el-form-item label="工单号" label-width="60px">
            <el-input clearable size="small" v-model="searchForm.order_sn" placeholder="请输入工单号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
            <el-button icon="el-icon-refresh-right" type="primary" size="small" @click="resetSearch">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="dataList" border size="small" height="600">
        <el-table-column type="index" :index="indexMethod" label="序号" width="60"></el-table-column>
        <el-table-column prop="P_Code" label="发票号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="orderId" label="平台订单号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createtime" label="发货地" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.originStateCode }}，{{ scope.row.originCityName }}，{{ scope.row.originAddressLine1 }}
          </template>
        </el-table-column>
        <el-table-column prop="createtime" label="收货地" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.destinationStateCode }}，{{ scope.row.destinationCityName }}，{{ scope.row.destinationAddressLine1 }}
          </template>
        </el-table-column>
        <el-table-column prop="Branch" label="发票金额" show-overflow-tooltip></el-table-column>
        <el-table-column prop="action" label="操作" width='120' show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              <span style="color: #409eff; cursor: pointer" @click="handleView(scope.row.invoice)">查看</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagization-box">
        <CustomPagization
          :background="true"
          :currentPage="current"
          :total="total"
          :pageSize="size"
          @current-change="currentChange"
        />
      </div>
    </el-card>
  </div>
</template>

<script>
import { invoiceList } from '@/api/ltl/finance'
export default {
  data() {
    return {
      detailVisible: false,
      searchForm: {},
      current: 1,
      size: 10,
      total: 1,
      dataList: [],
      id: ''
    };
  },
  mounted(){
    this.init()
  },
  methods: {
    // 打开详情
    handleView(file){
      window.open(file, '_blank')
    },
    // 关闭详情
    closeDialog() {
      this.detailVisible = false;
    },
    // 获取列表
    init(){
      invoiceList({
        u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
        page: this.current,
        limit: this.size,
        ...this.searchForm
      }).then(res => {
        if(res.code == 1){
          this.dataList = []
          this.dataList = res.data.data
          this.total = res.data.total
        }
      })
    },
    // table首列序号
    indexMethod(index) {
      return (this.current - 1) * this.size + index + 1;
    },
    // 页数变化
    currentChange(current) {
      this.current = current;
      this.init();
    },
    // 重置筛选
    resetSearch() {
      this.$refs["searchForm"].resetFields();
      this.searchForm = {}
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.handleSearch();
    },
    // 去筛选
    handleSearch() {
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
.pagization-box {
  overflow: hidden;
  margin-top: 10px;

  .el-pagination {
    float: right;
  }
}
/deep/ {
  .el-table {
    thead {
      color: #fff;
      font-weight: 500;
      background: #F5F7FA;
      color: #606266;
      & th {
        background-color: transparent;
      }
      & tr {
        background-color: transparent;
      }
    }
  }
}
</style>
