<template>
  <div class="">
    <!-- 我的工单 -->
    <el-card class="box-card">
      <div class="search-form" style="text-align: left">
        <el-form ref="searchForm" :inline="true" size="small" :model="searchForm" label-width="80px" label-position="left"
          class="demo-form-inline">
          <el-form-item label="工单号" label-width="60px">
            <el-input clearable size="small" v-model="searchForm.order_sn" placeholder="请输入工单号"></el-input>
          </el-form-item>
          <el-form-item label="工单状态">
            <el-select v-model="searchForm.status" placeholder="请选择工单状态">
              <el-option label="进行中" :value="1"></el-option>
              <el-option label="已解决" :value="2"></el-option>
              <el-option label="已闭合" :value="3"></el-option>
              <el-option label="已回复" :value="4"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
            <el-button icon="el-icon-refresh-right" type="primary" size="small" @click="resetSearch">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="" style="margin-bottom: 15px;text-align:left">
        <el-button type="primary" icon="el-icon-plus" size="small" @click="handleAdd">新增工单</el-button>
      </div>
      <el-table :data="dataList" border size="small" height="600">
        <el-table-column type="index" :index="indexMethod" label="序号" width="60"></el-table-column>
        <el-table-column prop="order_sn" label="工单号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="title" label="工单标题" show-overflow-tooltip></el-table-column>
        <el-table-column prop="describe" label="描述信息" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createtime" label="创建时间" show-overflow-tooltip></el-table-column>
        <el-table-column prop="status" label="工单状态" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ status(scope.row.status) }}
          </template>
        </el-table-column>
        <el-table-column prop="action" label="操作" show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              <span style="color: #409eff; cursor: pointer" @click="handleView(scope.row)">查看</span>
            </div>
          </template>
        </el-table-column>

      </el-table>
      <div class="pagization-box">
        <CustomPagization
          :background="true"
          :currentPage="current"
          :total="total"
          :pageSize="size"
          @current-change="currentChange"
        />
      </div>
    </el-card>
    <!-- 表单弹窗 -->
    <el-dialog
      title="新增工单"
      append-to-body
      :visible.sync="formVisible"
      width="700px"
      :before-close="closeDialog"
    >
      <Form
        ref="formModal"
        v-if="formVisible"
        @ok="editSuccess"
        append-to-body
      />
    </el-dialog>
    <el-dialog
      title="工单详情"
      append-to-body
      :visible.sync="viewVisible"
      width="1000px"
      :before-close="closeViewDialog"
    >
      <WorkView
        ref="formModal"
        v-if="viewVisible"
        :id='id'
        @ok="viewSuccess"
        append-to-body
      />
    </el-dialog>
  </div>
</template>

<script>
import { workList } from '@/api/ltl/work'
import Form from "./form";
import WorkView from "./workView";
export default {
  components: {
    Form,
    WorkView
  },
  data() {
    return {
      searchForm: {},
      current: 1,
      size: 10,
      total: 1,
      dataList: [],
      formVisible: false,
      viewVisible: false,
      id:''
    };
  },
  computed: {
    status() {
      return (status) => {
        switch (status) {
          case "1":
            return "进行中";
          case "2":
            return "已解决";
          case "3":
            return "已闭合";
          case "4":
            return "已回复";
        }
      };
    }
  },
  mounted(){
    this.init()
  },
  methods: {
    // 关闭弹框
    closeDialog() {
      this.formVisible = false;
    },
    //新增、修改成功的回调
    editSuccess() {
      this.formVisible = false;
      this.init();  //刷新列表
    },
    viewSuccess(){
      this.init();  //刷新列表
    },
    //视频监控站点新增
    handleAdd() {
      // this.title = "新增地址"
      // this.record = {}
      this.formVisible = true
    },
    // 关闭详情弹框
    closeViewDialog() {
      this.viewVisible = false;
    },
    //视频监控站点新增
    handleView(row) {
      this.id = row.id
      this.viewVisible = true
    },
    // 获取列表
    init(){
      workList({
        u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
        page: this.current,
        limit: this.size,
        ...this.searchForm
      }).then(res => {
        if(res.code == 1){
          this.dataList = []
          this.dataList = res.data.data
          this.total = res.data.total
        }
      })
    },
    // table首列序号
    indexMethod(index) {
      return (this.current - 1) * this.size + index + 1;
    },
    // 页数变化
    currentChange(current) {
      this.current = current;
      this.init();
    },
    // 重置筛选
    resetSearch() {
      this.$refs["searchForm"].resetFields();
      this.searchForm = {}
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.handleSearch();
    },
    // 去筛选
    handleSearch() {
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
.pagization-box {
  overflow: hidden;
  margin-top: 10px;

  .el-pagination {
    float: right;
  }
}
/deep/ {
  .el-table {
    thead {
      color: #fff;
      font-weight: 500;
      background: #F5F7FA;
      color: #606266;
      & th {
        background-color: transparent;
      }
      & tr {
        background-color: transparent;
      }
    }
  }
}
/deep/.el-dialog__body{
  padding: 0 20px 20px !important;
}
</style>
