<template>
  <div class="check" style="padding-bottom: 10px">
    <p class="item" v-for="(item,index) in msgList" :key="index">{{ item }}</p>
    <div class="check-btn">
      <el-button type="primary" @click="handleSure" size='small'>确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    msgList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      
    }
  },
  methods: {
    handleSure(){
      this.$emit('msgBack')
    }
  }
}
</script>
<style lang="scss" scoped>
  .check{
    font-size: 14px;
    p{
      font-size: 14px;
      line-height: 16px;
    }
  }
  .check-btn{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
  }
</style>