<template>
  <div class="">
    <!-- 服务商列表 -->
    <el-card class="box-card">
      <el-table :data="dataList" border size="small" height="600">
        <el-table-column type="index" label="序号" width="60"></el-table-column>
        <el-table-column prop="service_type" label="服务商" show-overflow-tooltip></el-table-column>
        <el-table-column prop="explain" label="服务商说明" show-overflow-tooltip></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { facilitatorList } from '@/api/facilitator'
export default {
  data() {
    return {
      dataList: [],
    };
  },
  mounted(){
    this.getFacilitatorList()
  },
  methods: {
    // 获取列表
    getFacilitatorList(){
      facilitatorList({
        user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code,
      }).then(res => {
        if(res.code == 1){
          this.dataList = []
          this.dataList = res.data
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.pagization-box {
  overflow: hidden;
  margin-top: 10px;

  .el-pagination {
    float: right;
  }
}
/deep/ {
  .el-table {
    thead {
      color: #fff;
      font-weight: 500;
      background: #F5F7FA;
      color: #606266;
      & th {
        background-color: transparent;
      }
      & tr {
        background-color: transparent;
      }
    }
  }
}
</style>
