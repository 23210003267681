<template>
  <div class="info">
    <div class="info-user">
      <img class="info-user-img"
           :src="info.avatar">
    </div>
    <div class="info-mes">
      <el-descriptions :column='2'>
        <el-descriptions-item label="会员姓名" span='2'>{{ info.username }}</el-descriptions-item>
        <el-descriptions-item label="个人简介" span='2'>{{ info.nickname }}</el-descriptions-item>
        <el-descriptions-item label="剩余金额">{{ info.money }}</el-descriptions-item>
        <el-descriptions-item label="所有积分"></el-descriptions-item>
        <el-descriptions-item label="登录时间"></el-descriptions-item>
        <el-descriptions-item label="最后登录"></el-descriptions-item>
      </el-descriptions>
    </div>

  </div>
</template>

<script>
import { usersInfo } from '@/api/user'
export default {
  data() {
    return {
      info:{}
    };
  },
  created(){
    this.getUsersInfo()
  },
  methods: {
    // 获取用户信息
    getUsersInfo(){
      usersInfo({user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code}).then(res => {
        if(res.code == 1){
          this.info = res.data.userinfo
        }
      })
    },
  },
};
</script>
<style lang="scss" scoped>
  .info{
    background: #fff;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    .demo-ruleForm{
      width: 50%;
    }
  }
  /deep/.el-form-item__content{
    text-align: left;
  }
  .info-title{
    width: 100%;
    font-size: 16px;
    line-height: 32px;
    text-align: left;
    font-weight: bold;
  }
  .info-user{
    flex-shrink: 0;
    height: 180px;
    width: 180px;
    display: flex;
    align-items: center;
    .info-user-img{
      margin: 0 20px 0 10px;
      padding: 2px;
      width: 130px;
      height: 130px;
      border-radius: 100%;
      box-sizing: border-box;
      border: 1px solid #eee;
      vertical-align: middle;
    }
    .info-user-box{
      flex: 1;
      p{
        font-size: 14px;
        line-height: 28px;
        text-align: left;
        margin: 0;
        padding: 0;
      }
    }
  }
  .info-mes{
    flex: 1;
    margin-top: 20px;
  }
</style>