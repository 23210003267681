<template>
  <div class="">
    <!-- 我的工单 -->
    <el-card class="box-card">
      <div class="search-form" style="text-align: left">
        <el-form
          ref="searchForm"
          :inline="true"
          size="small"
          :model="searchForm"
          label-width="80px"
          label-position="left"
          class="demo-form-inline"
        >
          <el-form-item label="充值金额">
            <el-input
              clearable
              size="small"
              v-model="searchForm.money"
              placeholder="请输入充值金额"
            ></el-input>
          </el-form-item>
          <el-form-item label="审核状态">
            <el-select v-model="searchForm.status" placeholder="请选择审核状态">
              <el-option label="待审核" :value="0"></el-option>
              <el-option label="审核通过" :value="1"></el-option>
              <el-option label="审核拒绝" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间" label-width="40px">
            <el-date-picker
              style="width: 320px"
              v-model="searchForm.createtime"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch"
              >查询</el-button
            >
            <el-button icon="el-icon-refresh-right" type="primary" size="small" @click="resetSearch"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="" style="margin-bottom: 15px; text-align: left">
        <el-button type="primary" icon="el-icon-plus" size="small" @click="handleAdd">充值</el-button>
      </div>
      <el-table :data="dataList" border size="small" height="600">
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          width="60"
        ></el-table-column>
        <el-table-column prop="money" label="金额" width='80' show-overflow-tooltip></el-table-column>
        <el-table-column prop="name" label="汇款人" width='80' show-overflow-tooltip></el-table-column>
        <el-table-column prop="bank" label="汇款银行" show-overflow-tooltip></el-table-column>
        <el-table-column prop="account" label="汇款账号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="notarize" label="电汇确认号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="date" label="汇款日期" width='100' show-overflow-tooltip></el-table-column>
        <el-table-column prop="remark" label="备注" show-overflow-tooltip></el-table-column>
        <el-table-column prop="file" label="文件" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createtime" label="申请日期" width='140' show-overflow-tooltip></el-table-column>
        <el-table-column prop="status" label="审核状态" width='80' show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="color: #409EFF" v-if="scope.row.status == 0">待审核</span>
            <span style="color: #67C23A" v-if="scope.row.status == 1">审核通过</span>
            <span style="color: #F56C6C" v-if="scope.row.status == 2">审核拒绝</span>
          </template>
        </el-table-column>
        <el-table-column prop="action" label="操作" show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              <span style="color: #409eff; cursor: pointer" v-if="scope.row.status == 2" @click="handleView(scope.row)">查看</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagization-box">
        <CustomPagization
          :background="true"
          :currentPage="current"
          :total="total"
          :pageSize="size"
          @current-change="currentChange"
        />
      </div>
    </el-card>
    <!-- 表单弹窗 -->
    <el-dialog
      title="拒绝原因"
      append-to-body
      :visible.sync="viewVisible"
      width="600px"
      :before-close="closeViewDialog"
    >
      <RechargeView ref="formModal" v-if="viewVisible" :refusal_cause='refusal_cause' append-to-body />
    </el-dialog>
    <el-dialog
      title="申请充值"
      append-to-body
      :visible.sync="formVisible"
      width="1000px"
      :before-close="closeDialog"
    >
      <RechargeForm ref="formModal" v-if="formVisible" :id="id" @ok="editSuccess" append-to-body />
    </el-dialog>
  </div>
</template>

<script>
import { rechargeList } from "@/api/ltl/finance";
import RechargeForm from "./rechargeForm";
import RechargeView from "./rechargeView";
export default {
  components: {
    RechargeForm,
    RechargeView
  },
  data() {
    return {
      searchForm: {},
      current: 1,
      size: 10,
      total: 1,
      dataList: [],
      formVisible: false,
      viewVisible: false,
      id: "",
      refusal_cause: ''
    };
  },
  computed: {
    status() {
      return (status) => {
        switch (status) {
          case "0":
            return "待审核";
          case "1":
            return "审核通过";
          case "2":
            return "审核拒绝";
        }
      };
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    // 关闭弹框
    closeDialog() {
      this.formVisible = false;
    },
    //新增、修改成功的回调
    editSuccess() {
      this.formVisible = false;
      this.viewVisible = false;
      this.init(); //刷新列表
    },
    //视频监控站点新增
    handleAdd() {
      this.formVisible = true;
    },
    // 关闭详情弹框
    closeViewDialog() {
      this.viewVisible = false;
    },
    //视频监控站点新增
    handleView(row) {
      this.refusal_cause = row.refusal_cause;
      this.viewVisible = true;
    },
    // 获取列表
    init() {
      let queryForm = {
        ...this.searchForm,
        start_time: this.searchForm.createtime ? this.searchForm.createtime[0] : '',
        end_time: this.searchForm.createtime ? this.searchForm.createtime[1] : '',
      };
      delete queryForm.createtime;
      rechargeList({
        u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
        page: this.current,
        limit: this.size,
        ...queryForm,
      }).then((res) => {
        if (res.code == 1) {
          this.dataList = [];
          this.dataList = res.data.data;
          this.total = res.data.total;
        }
      });
    },
    // table首列序号
    indexMethod(index) {
      return (this.current - 1) * this.size + index + 1;
    },
    // 页数变化
    currentChange(current) {
      this.current = current;
      this.init();
    },
    // 重置筛选
    resetSearch() {
      this.$refs["searchForm"].resetFields();
      this.searchForm = {};
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.handleSearch();
    },
    // 去筛选
    handleSearch() {
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
.pagization-box {
  overflow: hidden;
  margin-top: 10px;

  .el-pagination {
    float: right;
  }
}
/deep/ {
  .el-table {
    thead {
      color: #fff;
      font-weight: 500;
      background: #f5f7fa;
      color: #606266;
      & th {
        background-color: transparent;
      }
      & tr {
        background-color: transparent;
      }
    }
  }
}
/deep/.el-dialog__body {
  padding: 0 20px 20px !important;
}
</style>
