<template>
  <div class="check">
    <p>PDF链接：<span class="link" @click="handleToFile">{{ morePdfs }}</span></p>
    <div class="check-btn">
      <el-button type="primary" @click="handleSure" size='small'>确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    morePdfs: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      
    }
  },
  methods: {
    handleSure(){
      this.$emit('closeMorePds')
    },
    handleToFile(){
      this.$emit('closeMorePds')
      window.open(this.morePdfs, '_blank');
    }
  }
}
</script>
<style lang="scss" scoped>
  .check{
    font-size: 14px;
    p{
      font-size: 14px;
      line-height: 16px;
    }
  }
  .check-btn{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
  }
  .link{
    color: #409EFF;
    cursor: pointer;
  }
</style>