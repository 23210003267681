<template>
  <div class="">
    <!-- 发票列表 -->
    <el-card class="box-card">
      <el-table :data="dataList" border size="small" >
        <el-table-column type="index" :index="indexMethod" label="序号" width="60"></el-table-column>
        <el-table-column prop="realname" label="订单号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="mobile" label="运单号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="province" label="创建时间" show-overflow-tooltip></el-table-column>
        
        <el-table-column prop="action" label="操作" width="140">
          <template slot-scope="scope">
            <div>
              <span style="color: #409eff; cursor: pointer" @click="handleInvoice(scope.row)">查看发票</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagization-box">
        <CustomPagization
          :background="true"
          :currentPage="current"
          :total="total"
          :pageSize="size"
          @current-change="currentChange"
        />
      </div>
    </el-card>
    <!-- 发票弹窗 -->
    <el-dialog
      title='查看发票'
      append-to-body
      :visible.sync="invoiceVisible"
      width="900px"
      :before-close="closeDialog"
    >
      <InvoiceView
        v-if="invoiceVisible"
        append-to-body
      />
    </el-dialog>
  </div>
</template>

<script>
import { invoiceList } from '@/api/finance'
import InvoiceView from './components/invoiceView.vue';
export default {
  components:{
    InvoiceView
  },
  data() {
    return {
      invoiceVisible: false,
      searchForm: {
        keywords: "",
        address_type: "",
      },
      current: 1,
      size: 10,
      total: 1,
      dataList: [],
    };
  },
  mounted(){
    this.getInvoiceList()
  },
  methods: {
    // 查看发票
    handleInvoice(){
      this.invoiceVisible = true
    },
    // 关闭弹窗
    closeDialog() {
      this.invoiceVisible = false;
    },
    // 获取列表
    getInvoiceList(){
      invoiceList({
        user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code,
        page: this.current,
        limit: this.size,
        ...this.searchForm
      }).then(res => {
        if(res.code == 1){
          this.dataList = []
          this.dataList = res.data.data
          this.total = res.data.total
        }
      })
    },
    // table首列序号
    indexMethod(index) {
      return (this.current - 1) * this.size + index + 1;
    },
    // 页数变化
    currentChange(current) {
      this.current = current;
      this.getInvoiceList();
    },
    // 重置筛选
    resetSearch() {
      this.$refs["searchForm"].resetFields();
      this.searchForm = {}
      this.value1 = []
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.handleSearch();
    },
    // 去筛选
    handleSearch() {
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.getInvoiceList();
    },
  },
};
</script>
<style lang="scss" scoped>
.pagization-box {
  overflow: hidden;
  margin-top: 10px;

  .el-pagination {
    float: right;
  }
}
/deep/ {
  .el-table {
    thead {
      color: #fff;
      font-weight: 500;
      background: #F5F7FA;
      color: #606266;
      & th {
        background-color: transparent;
      }
      & tr {
        background-color: transparent;
      }
    }
  }
}
</style>
