<template>
  <div class="">
    <!-- <Result></Result> -->
    <div @click="ce">测试</div>
     <div @click="ce2">测试2</div>
  </div>
</template>

<script>
import Result from './order/result.vue'
export default {
  components: {
    Result
  },
  data () {
    return {
      
    }
  },
  methods: {
    ce(){
      // this.$router.push({ path: '/ltl/orderPlace'});
       this.$router.push({ path: '/ltl/orderResult'});
    },
        ce2(){
      this.$router.push({ path: '/ltl/orderOrder'});
    }
  }
}
</script>
<style lang="scss" scoped>
  
</style>