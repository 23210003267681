<template>
  <div class="place">
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="90px"
      class="demo-form"
      size="small"
    >
      <!-- 地址信息 -->
      <el-row :gutter="10" class="border-col">
        <el-col :span="12">
          <div class="place-title">地址信息</div>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="发货地址" prop="originAddressId">
            <el-select
              v-model="originAddressName"
              placeholder="请选择发货地址"
              remote
              reserve-keyword
              filterable
              style="width: 100%"
              @change="handleSelectFa"
              :remote-method="remoteFaMethod"
            >
              <el-option
                v-for="(item, index) in falist"
                :key="index"
                :label="`${item.addressName},${item.address1},${item.address2}${item.address2 == '' ? '' : ','}${item.city},${item.province} ${item.zipCode}`"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="地址类型" prop="originType">
            <el-select
              v-model="form.originType"
              placeholder="请选择地址类型"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in addressType"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="设备类别">
            <el-select
              v-model="value1"
              placeholder="请选择设备类别"
              style="width: 100%"
            >
              <el-option label="Dry Van" value="Dry Van"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="收件地址" prop="destinationAddressId">
            <el-select
              v-model="destinationLocationName"
              placeholder="请选择收件地址"
              remote
              reserve-keyword
              filterable
              style="width: 100%"
              @change="handleSelectShou"
              :remote-method="remoteShouMethod"
            >
              <el-option
                v-for="(item, index) in shoulist"
                :key="index"
                :label="`${item.addressName},${item.address1},${item.address2}${item.address2 == '' ? '' : ','}${item.city},${item.province} ${item.zipCode}`"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="地址类型" prop="destinationType">
            <el-select
              v-model="form.destinationType"
              placeholder="请选择地址类型"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in addressType"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="设备类别">
            <el-select
              v-model="value2"
              placeholder="请选择省设备类别"
              style="width: 100%"
            >
              <el-option label="Van - 53 Feet" value="Van - 53 Feet"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="取货日期" prop="originDate">
            <el-date-picker
              v-model="form.originDate"
              type="date"
              placeholder="请选择取货日期"
              class="w100"
              format='yyyy-MM-dd'
              value-format='yyyy-MM-dd'
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 包裹 -->
      <el-row :gutter="10" class="border-col">
        <el-col :span="12">
          <div class="place-title" style="display: flex">装运信息
            <el-radio-group v-model="unitType" size="mini" style="margin-left: 10px">
              <el-radio-button label="in/lb"></el-radio-button>
              <el-radio-button label="cm/kg"></el-radio-button>
            </el-radio-group>
          </div>
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-button type="success" @click="selectProduct" size="small" class="add-select-btn"
            >选择产品</el-button
          >
        </el-col>
      </el-row>
      <div class="parcel-item" v-for="(item, index) in form.commodityList" :key="index">
        <div class="parcel-top parcel-top-pad">
          <span class="parcel-title">{{ index + 1 }}-包裹信息</span>
          <i
            style="font-size: 20px; margin: 0 10px; color: #409dfe; cursor: pointer"
            class="el-icon-circle-plus-outline"
            @click="addParcel"
          ></i>
          <i
            style="font-size: 20px; color: #f56c6c; cursor: pointer; margin-right: 10px"
            class="el-icon-remove-outline"
            v-if="form.commodityList.length > 1"
            @click="delParcel(index)"
          ></i>
        </div>
        <el-row :gutter="10" class="parcel-mid-pad">
          <el-col :span="4">
            <el-form-item
              label="商品名称"
              :prop="`commodityList[${index}][commodity]`"
              :rules="rules.commodity"
              class="special"
            >
              <el-input v-model="item.commodity"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="商品数量"
              :prop="`commodityList[${index}][quantity]`"
              :rules="rules.quantity"
              class="special"
            >
              <el-input v-model.number="item.quantity"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="商品单位" class="special" :prop="`commodityList[${index}][packageTypeId]`" :rules="rules.packageTypeId">
              <el-select v-model="item.packageTypeId" style="width: 100%">
                <el-option
                  :label="item.label"
                  :value="item.value"
                  v-for="(item, index) in productUnit"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="打托数量"
              :prop="`commodityList[${index}][handlingUnits]`"
              :rules="rules.handlingUnits"
              class="special"
            >
              <el-input v-model.number="item.handlingUnits" @blur='calculation(index)'></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="打托类型" class="special" :prop="`commodityList[${index}][unitTypeId]`" :rules="rules.unitTypeId">
              <el-select v-model="item.unitTypeId" style="width: 100%">
                <el-option
                  :label="item.name"
                  :value="item.id.toString()"
                  v-for="(item, index) in JudgePalletTypeArr"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="货值"
              class="special"
              :prop="`commodityList[${index}][declaredValue]`"
              :rules="rules.declaredValue"
            >
              <el-input v-model="item.declaredValue"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              :label="`长(${unitType == 'in/lb' ? 'in' : 'cm'})`"
              :prop="`commodityList[${index}][length]`"
              :rules="rules.length"
              class="special"
            >
              <el-input v-model.number="item.length" @blur='calculation(index)'></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              :label="`宽(${unitType == 'in/lb' ? 'in' : 'cm'})`"
              :prop="`commodityList[${index}][width]`"
              :rules="rules.width"
              class="special"
            >
              <el-input v-model.number="item.width" @blur='calculation(index)'></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              :label="`高(${unitType == 'in/lb' ? 'in' : 'cm'})`"
              :prop="`commodityList[${index}][height]`"
              :rules="rules.height"
              class="special"
            >
              <el-input v-model.number="item.height" @blur='calculation(index)'></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="4">
            <el-form-item
              :label="`总重量(${unitType == 'in/lb' ? 'lb' : 'kg'})`"
              :prop="`commodityList[${index}][weight]`"
              :rules="rules.weight"
              class="special"
            >
              <el-input v-model.number="item.weight" @blur='calculation(index)'></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="等级" class="special dis">
              <el-input v-model="item.dengji" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="NMFC"
              class="special"
            >
              <el-input v-model="item.nmfcNumber"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10" class="parcel-mid-pad" v-if="item.isHazmat == 1">
          <el-col :span="4">
            <el-form-item
              label="危险品UN编号"
              :prop="`commodityList[${index}][unNumber]`"
              :rules="rules.unNumber"
              label-width="200px"
              class="special"
            >
              <el-input v-model="item.unNumber"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="危险产品类别别号"
              :prop="`commodityList[${index}][bclass]`"
              :rules="rules.bclass"
              label-width="200px"
              class="special"
            >
              <el-select v-model="item.bclass" style="width: 100%">
                <el-option
                  :label="index+1"
                  :value="index+1"
                  v-for="(item, index) in 9"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="危险品数量"
              label-width="200px"
              class="special dis"
            >
              <el-input v-model="item.handlingUnits" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="是否有毒"
              :prop="`commodityList[${index}][poison]`"
              :rules="rules.poison"
              class="special"
            >
              <el-select v-model="item.poison" style="width: 100%">
                <el-option label="无毒" :value="0"></el-option>
                <el-option label="有毒" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="紧急联系人"
              :prop="`commodityList[${index}][hazmatContactName]`"
              :rules="rules.hazmatContactName"
              label-width="200px"
              class="special"
            >
              <el-input v-model="item.hazmatContactName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="手机号"
              :prop="`commodityList[${index}][hazmatPhone]`"
              :rules="rules.hazmatPhone"
              class="special"
            >
              <el-input v-model="item.hazmatPhone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10" class="parcel-bot-pad">
          <div class="page-calc">
            <div class="page-calc-item">
              <el-form-item>
                <el-checkbox
                  v-model="item.isHazmat"
                  :checked="item.isHazmat == 1 ? true : false"
                  true-label="1"
                  false-label="0"
                  name="isHazmat"
                  >是否危险品</el-checkbox
                >
              </el-form-item>
            </div>
            <div class="page-calc-item">总体积：{{ calcList[index] ? calcList[index].allTi : 0.00 }} ft³</div>
            <div class="page-calc-item">总重量：{{ calcList[index] ? calcList[index].weight : 0 }} lb</div>
            <div class="page-calc-item">总密度：{{ calcList[index] ? calcList[index].ami : 0 }} lb/ft³</div>
          </div>
        </el-row>
      </div>

      <!-- 按钮 -->
      <el-row :gutter="10">
        <el-col :span="24" class="bttn">
          <el-button
            type="primary"
            size="small"
            @click="submitForm('form')"
            :loading="orderLoading"
            >获取报价</el-button
          >
          <!-- <el-button @click="resetForm('form')" size="small">重置</el-button> -->
        </el-col>
      </el-row>
    </el-form>
    <el-dialog
      title="选择产品"
      append-to-body
      :visible.sync="productVisible"
      width="1000px"
      :before-close="closeProductDialog"
    >
      <ProductList
        ref="formModal"
        :isType='true'
        v-if="productVisible"
        @ok="editSuccess"
        append-to-body
      />
    </el-dialog>
  </div>
</template>

<script>
import { rateInfo, zltlRate, rapiddealsClass } from "@/api/ltl/order";
import { list, zipCode } from "@/api/ltl/address";
import { addressType, freightLevel, productUnit, JudgePalletTypeArr, validatenull } from "@/common/common";
import ProductList from "../../product/list";
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  components: {
    ProductList
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      // 没有传参的占位数据
      value1: '',
      value2: '',
      value3: '',
      value4: '',
      value5: '',
      value6: '',
      unitType: 'in/lb',
      productVisible: false, // 产品弹框
      orderLoading: false, // 获取报价
      falist: [],// 发货地址
      shoulist: [], //收货地址
      addressType: addressType(), //地址类型
      freightLevel: freightLevel(), // 运费等级
      productUnit: productUnit(), //商品单位
      JudgePalletTypeArr: JudgePalletTypeArr(), // 打托类型
      form: {
        destinationAddressLine1: "", //收货地址1
        destinationCityName: "", //收货城市
        destinationContactEmail: "", //收货人邮箱
        destinationContactName: "", //收货联系人
        destinationContactPhone: "", //收货人电话
        destinationLocationName: "", //收货地址名称
        destinationStateCode: "", //收货地址州code
        destinationZipCode: "", //收货地址邮政编码
        destinationType: "BUSINESS", //收货地址类型，BUSINESS,RESIDENTIAL,CONSTRUCTIONSITE,TRADESHOW
        destinationAddressId: "", //收件地址ID

        originAddressLine1: "", //发货地址1
        originCityName: "", //发货城市
        originContactEmail: "", //发货联系人邮箱
        originContactName: "", //发货联系人
        originContactPhone: "", //发货人电话
        originLocationName: "", //发货地址名称
        originStateCode: "", //发货州代码
        originZipCode: "", //发货邮编
        originDate: "", //发货时间，“2020-10-10”
        originType: "BUSINESS", //发货地址类型，BUSINESS,RESIDENTIAL,CONSTRUCTIONSITE,TRADESHOW
        originAddressId: "", //发件地址ID
        commodityList: [{
          commodity: "", //货物描述
          handlingUnits: "", //托运数量
          height: "", //高 (in)
          length: "", //长（in）
          packageTypeId: 2, //包装类型 id (固定传11)
          quantity: "", //货物数量
          unitTypeId: "3", //托运类型id （3=PALLETS，8=CRATES）
          weight: "", //总重量（lb）
          width: "", //宽 (in)
          isHazmat: 0, // 是否危险品 0无1有
          declaredValue: "", //货值
          nmfcNumber: "", //NMFC编号
          dengji: '', // 等级
        }], //包裹信息
      },
      originAddressName: '',// 发货地址名称 
      destinationLocationName: '',// 收货地址名称
      calcList: [], // 计算的值
      rules: {
        originAddressId: [{ required: true, message: "请选择发货地址", trigger: "cahnge" }],
        originType: [{ required: true, message: "请选择地址类型", trigger: "change" }],
        destinationAddressId: [{ required: true, message: "请选择收件地址", trigger: "change" }],
        destinationType: [{ required: true, message: "请选择地址类型", trigger: "change" }],
        originDate: [{ required: true, message: "请选择取货日期", trigger: "change" }],
 
        commodity: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        quantity: [{ required: true, message: "请输入商品数量", trigger: "blur" }],
        packageTypeId: [{ required: true, message: "请选择商品单位", trigger: "change" }],
        handlingUnits: [{ required: true, message: "请输入打托数量", trigger: "blur" }],
        unitTypeId: [{ required: true, message: "请选择打托类型", trigger: "change" }],
        declaredValue: [{required: true, message: "请输入货值", trigger: "blur"}],
        length: [{ required: true, message: "请输入长", trigger: "blur" }],
        width: [{ required: true, message: "请输入宽", trigger: "blur" }],
        height: [{ required: true, message: "请输入高", trigger: "blur" }],
        weight: [{ required: true, message: "请输入重量", trigger: "blur" }],
        unNumber: [{ required: true, message: "请输入危险品UN编号", trigger: "blur" }],
        bclass: [{ required: true, message: "请选择危险产品类别别号", trigger: "change" }],
        poison: [{ required: true, message: "请选择是否有毒", trigger: "change" }],
        hazmatContactName: [{ required: true, message: "请输入紧急联系人", trigger: "blur" }],
        hazmatPhone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
      },
    };
  },
  mounted() {
    if(this.id){
      if(this.id == 'A1'){
        this.getRateResult()
      }else{
        this.getRateInfo(this.id)
      }
    }
  },
  created() {
    this.getAddressList()
  },
  methods: {
    // 计算
    calculation(index) {
      this.calcList = this.form.commodityList.map(item => {
        var allTi = 0 // 总体积
        var ami = 0
        if(item.length != '' && item.width != '' && item.height != '' && item.handlingUnits != ''){
          allTi = (Number(item.length) * Number(item.width) * Number(item.height) * Number(item.handlingUnits) / 1728).toFixed(2)
          if(item.weight != '' && allTi != 0){
            ami = (Number(item.weight) / Number(allTi)).toFixed(2)
          }
        }
        
        return({
          allTi,
          ami,
          weight: item.weight,
        })
      })
      if(index || index == 0){
        var obj = this.form.commodityList[index]
        if(obj.length != '' && obj.width != '' && obj.height != '' && obj.handlingUnits != '' && obj.weight != ''){
          rapiddealsClass({
            length: obj.length, //单个托盘的 长 （整型）
            width: obj.width, //单个托盘的 宽 （整型）
            height: obj.height, //单个托盘的 高（整型）
            weight: obj.weight, //总重量  （整型）
            unitType: this.unitType == "in/lb" ? 0 : 1, //计算类型  0 （in/lb） 1 (cm/kg) （整型）
            handlingUnits: obj.handlingUnits, //托盘数量 （整型）
          }).then(res => {
            this.form.commodityList[index].dengji = res.data.suggestedClass
          })
        }
      }
    },
    // 获取地址
    getAddressList(){
      list({
        u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
        page: 1,
        limit: 200,
      }).then(res => {
        if(res.code == 1){
          this.falist = res.data.data.filter(item => item.addressFunction == 0)
          this.shoulist = res.data.data.filter(item => item.addressFunction == 1)
        }
      })
    },
    // 选择产品
    selectProduct(){
      this.productVisible = true;
    },
    // 关闭产品弹窗
    closeProductDialog(){
      this.productVisible = false;
    },
    // 获取产品
    editSuccess(row){
      this.productVisible = false;
      let obj = {
        commodity: row.name, //货物描述
        handlingUnits: 1, //托运数量
        height: Number(row.height).toFixed(0), //高 (in)
        length: Number(row.length).toFixed(0), //长（in）
        packageTypeId: row.product_unit ? row.product_unit : 2, //包装类型 id (固定传11)
        quantity: 1, //货物数量
        unitTypeId: row.battering_type ? row.battering_type : '3', //托运类型id （3=PALLETS，8=CRATES）
        weight: Number(row.weight).toFixed(0), //总重量（lb）
        width: Number(row.width).toFixed(0), //宽 (in)
        isHazmat: 0, // 是否危险品 0无1有
        declaredValue: "", //货值
        nmfcNumber: "", //NMFC编号
        dengji: "", // 等级
      };
      this.form.commodityList.push(obj);
      this.calculation(this.form.commodityList.length - 1)
    },
    // 选择发件收件地址
    handleSelectFa(value){
      if(this.falist.find(item => item.id == value)){
        let obj = this.falist.find(item => item.id == value)
        this.form.originAddressLine1 = obj.address1
        this.form.originCityName = obj.city
        this.form.originContactEmail = obj.email
        this.form.originContactName = obj.userName
        this.form.originContactPhone = obj.phone
        this.form.originLocationName = obj.addressName
        this.form.originStateCode = obj.province
        this.form.originZipCode = obj.zipCode
        this.form.originAddressId = obj.id
      }
    },
    // 通过输入邮编获取库里存储的发件地址
    remoteFaMethod(value){
      zipCode({
        zipCode: value
      }).then(res => {
        if(res.code == 1){
          this.form.originAddressLine1 = res.data.address1
          this.form.originCityName = res.data.city
          this.form.originContactEmail = res.data.email
          this.form.originContactName = res.data.userName
          this.form.originContactPhone = res.data.phone
          this.form.originLocationName = res.data.addressName
          this.form.originStateCode = res.data.province
          this.form.originZipCode = res.data.zipCode
          this.form.originAddressId = res.data.id
          this.originAddressName = `${res.data.city},${res.data.province} ${res.data.zipCode}`
        }
      })
    },
    // 选择收货地址
    handleSelectShou(value){
      if(this.shoulist.find(item => item.id == value)){
        let obj = this.shoulist.find(item => item.id == value)
        this.form.destinationAddressLine1 = obj.address1
        this.form.destinationCityName = obj.city
        this.form.destinationContactEmail = obj.email
        this.form.destinationContactName = obj.userName
        this.form.destinationContactPhone = obj.phone
        this.form.destinationLocationName = obj.addressName
        this.form.destinationStateCode = obj.province
        this.form.destinationZipCode = obj.zipCode
        this.form.destinationAddressId = obj.id
      }
    },
    // 通过输入邮编获取库里存储的收货地址
    remoteShouMethod(value){
      zipCode({
        zipCode: value
      }).then(res => {
        if(res.code == 1){
          this.form.destinationAddressLine1 = res.data.address1
          this.form.destinationCityName = res.data.city
          this.form.destinationContactEmail = res.data.email
          this.form.destinationContactName = res.data.userName
          this.form.destinationContactPhone = res.data.phone
          this.form.destinationLocationName = res.data.addressName
          this.form.destinationStateCode = res.data.province
          this.form.destinationZipCode = res.data.zipCode
          this.form.destinationAddressId = res.data.id
          this.destinationLocationName = `${res.data.city},${res.data.province} ${res.data.zipCode}`
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = {
            ...this.form,
            u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
            platformId: window.localStorage.getItem("platform_id"), //平台ID 登录接口返回了此值 
          };
          this.orderLoading = true;
          zltlRate(form).then((res) => {
              this.orderLoading = false;
              if (res.code == 1) {
                this.$message.success(res.msg);
                window.localStorage.setItem("orderResultInfo", JSON.stringify(res));
                this.$router.push({ path: '/ltl/orderResult'});
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.orderLoading = false;
              this.$message.error("获取报价失败");
            });
        } else {
          return false;
        }
      });
    },
    // 添加包裹
    addParcel() {
      let obj = {
        commodity: "", //货物描述
        handlingUnits: "", //托运数量
        height: "", //高 (in)
        length: "", //长（in）
        packageTypeId: 2, //包装类型 id (固定传11)
        quantity: "", //货物数量
        unitTypeId: "3", //托运类型id （3=PALLETS，8=CRATES）
        weight: "", //总重量（lb）
        width: "", //宽 (in)
        isHazmat: 0, // 是否危险品 0无1有
        declaredValue: "", //货值
        nmfcNumber: "", //NMFC编号
        dengji: '',// 等级
      };
      this.form.commodityList.push(obj);
    },
    // 删除包裹
    delParcel(num) {
      this.form.commodityList.splice(num, 1);
      this.calculation();
    },
    // 报价列表编辑数据
    getRateInfo(id){
      rateInfo({
        u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
        id
      }).then(res => {
        if(res.data.selectMode == 'TL'){
          let p = res.data
          this.form = {
            destinationAddressLine1: p.receiver_address_address1, //收货地址1
            destinationCityName: p.receiver_address_city, //收货城市
            destinationContactEmail: p.receiver_address_email, //收货人邮箱
            destinationContactName: p.receiver_address_userName, //收货联系人
            destinationContactPhone: p.receiver_address_phone, //收货人电话
            destinationLocationName: p.receiver_address_addressName, //收货地址名称
            destinationStateCode: p.receiver_address_province, //收货地址州code
            destinationZipCode: p.receiver_address_zipCode, //收货地址邮政编码
            destinationType: p.destinationType, //收货地址类型，BUSINESS,RESIDENTIAL,CONSTRUCTIONSITE,TRADESHOW
            destinationAddressId: p.receiver_address_id, //收件地址ID

            originAddressLine1: p.send_address_address1, //发货地址1
            originCityName: p.send_address_city, //发货城市
            originContactEmail: p.send_address_email, //发货联系人邮箱
            originContactName: p.send_address_userName, //发货联系人
            originContactPhone: p.send_address_phone, //发货人电话
            originLocationName: p.send_address_addressName, //发货地址名称
            originStateCode: p.send_address_province, //发货州代码
            originZipCode: p.send_address_zipCode, //发货邮编
            originDate: p.originDate, //发货时间，“2020-10-10”
            originType: p.originType, //发货地址类型，BUSINESS,RESIDENTIAL,CONSTRUCTIONSITE,TRADESHOW
            originAddressId: p.send_address_id, //发件地址ID

            commodityList: p.info, //包裹信息
          }
          this.originAddressName = `${p.send_address_addressName},${p.send_address_address1},${p.send_address_city},${p.send_address_province} ${p.send_address_zipCode}`
          this.destinationLocationName = `${p.receiver_address_addressName},${p.receiver_address_address1},${p.receiver_address_city},${p.receiver_address_province} ${p.receiver_address_zipCode}`
          this.calculation()
        }
      })
    },
    // 从报价结果编辑回显数据
    getRateResult(){
      var res = JSON.parse(window.localStorage.getItem("orderResultInfo"));
      if(res.send_array.selectMode == 'TL'){
        this.form = {
          ...res.send_array
        }
        this.originAddressName = `${p.originLocationName},${p.originAddressLine1},${p.originCityName},${p.originStateCode} ${p.originZipCode}`
        this.destinationLocationName = `${p.destinationLocationName},${p.destinationAddressLine1},${p.destinationCityName},${p.destinationStateCode} ${p.destinationZipCode}`
        this.calculation()
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.place {
  background: #fff;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  overflow-y: scroll;
  .place-title {
    width: 100%;
    font-size: 15px;
    line-height: 24px;
    text-align: left;
    color: #409eff;
  }
  .border-col {
    border-bottom: 1px solid #409eff;
    margin-bottom: 10px;
    padding-bottom: 5px;
  }
}
/deep/.el-form-item__content {
  text-align: left;
}
.add-select-btn {
  margin-left: 30px;
}
// 包裹信息
.parcel-item {
  width: 100%;
  background: rgba(64, 158, 255, 0.1);
  border-radius: 5px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .parcel-top {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #bee6f7;
    margin-bottom: 10px;
    padding-bottom: 10px;
    .parcel-title {
      font-size: 14px;
    }
  }
  .parcel-lwh {
    display: flex;
    align-items: center;
    /deep/ input::-webkit-outer-spin-button,
    /deep/ input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }
    /deep/ input[type="number"] {
      -moz-appearance: textfield;
    }
  }
  .place-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .parcel-top-pad{
    box-sizing: border-box;
    padding: 10px;
    margin: 0 !important;
  }
  .parcel-mid-pad{
    box-sizing: border-box;
    padding: 0 10px 10px !important;
    margin: 0 !important;
  }
  .parcel-bot-pad{
    box-sizing: border-box;
    margin: 0 !important;
    border-radius: 0 0 5px 5px;
    overflow: hidden;
  }
}
.bttn {
  text-align: right;
  margin-top: 10px;
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.check-dialog {
  /deep/.el-dialog__body {
    padding: 0 20px !important;
  }
}

// 新增样式
// 修改原生el-form-item单个label的位置
.special {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px !important;
  /deep/.el-form-item__label {
    flex: 0 0 auto;
    text-align: left;
    margin-left: 0 !important;
  }
  /deep/.el-form-item__content {
    flex: 1;
    margin-left: 0 !important;
  }
}
// 修改弹窗的样式
/deep/.el-dialog__body{
  padding: 0 !important;
}
.page-calc{
  width: 100%;
  height: 30px;
  background: #F5F7FA;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .page-calc-item{
    font-size: 12px;
    flex: 1;
    flex-shrink: 0;
    /deep/.el-form-item__content{
      margin-top: 20px;
      margin-left: 10px !important;
    }
  }
}
.dis {
  /deep/.el-input__inner {
    background: #f5f7fa;
  }
}
</style>
