<template>
  <div class="">
    <div class="com-title">收款人信息</div>
    <el-descriptions size="small" :column="3" border>
      <el-descriptions-item label="收款人姓名"></el-descriptions-item>
      <el-descriptions-item label="收款人地址"></el-descriptions-item>
      <el-descriptions-item label="账号"></el-descriptions-item>
      <el-descriptions-item label="收款人开户行"></el-descriptions-item>
      <el-descriptions-item label="路由号码"></el-descriptions-item>
      <el-descriptions-item label="汇款码"></el-descriptions-item>
      <el-descriptions-item label="银行地址"></el-descriptions-item>
      <el-descriptions-item label="参考文献"></el-descriptions-item>
    </el-descriptions>
    <div class="com-title" style="margin-top: 10px">回复信息</div>
    <div>
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="100px"
        class="demo-form"
        label-position="top"
        size="small"
      >
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="8">
            <el-form-item label="提交金额" prop="money">
              <el-input v-model="form.money" placeholder="请输入提交金额"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="汇款人" prop="name">
              <el-input v-model="form.name" placeholder="请输入汇款人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="汇款银行" prop="bank">
              <el-input v-model="form.bank" placeholder="请输入汇款银行"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="汇款账号" prop="account">
              <el-input v-model="form.account" placeholder="请输入汇款账号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="电汇确认号" prop="notarize">
              <el-input v-model="form.notarize" placeholder="请输入电汇确认号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="汇款日期" prop="date">
              <el-date-picker
                v-model="form.date"
                type="date"
                style="width: 100%"
                placeholder="请输入汇款日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="form.remark" placeholder="请输入备注"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="营业执照" prop="file">
              <el-upload
                :class="fileList.length >= 1 ? 'no-upload-file' : ''"
                action="https://admin.bbtexus.com/api/common/upload"
                list-type="picture-card"
                :on-success="handleSuccess"
                :on-remove="handleRemove"
                :file-list="fileList"
                :limit='1'
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item style="text-align: right">
          <el-button size="small" type="primary" @click="submitForm('form')">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { remittanceData, recharge } from "@/api/ltl/finance";
export default {
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      info: {},
      form: {},
      rules: {
        money: [{ required: true, message: "请输入提交金额", trigger: "blur" }],
        name: [{ required: true, message: "请输入汇款人", trigger: "blur" }],
        bank: [{ required: true, message: "请输入汇款银行", trigger: "blur" }],
        account: [{ required: true, message: "请输入汇款账号", trigger: "blur" }],
        notarize: [{ required: true, message: "请输入电汇确认号", trigger: "blur" }],
        date: [{ required: true, message: "请输入汇款日期", trigger: "change" }],
      },
      fileList: [],
    };
  },
  computed: {
    status() {
      return (status) => {
        switch (status) {
          case "1":
            return "进行中";
          case "2":
            return "已解决";
          case "3":
            return "已闭合";
          case "4":
            return "已回复";
        }
      };
    },
  },
  mounted() {
    this.getRemittanceData();
  },
  methods: {
    handleBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isLt2M;
    },
    handleSuccess(res) {
      let list = [{
        label: res.data.url,
        value: res.data.fullurl,
        url: res.data.fullurl
      }];
      this.fileList = list;
    },
    handleRemove(file) {
      this.fileList = [];
    },
    getRemittanceData() {
      remittanceData({
        u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
      }).then((res) => {
        this.form = {
          ...res.data
        }
      });
    },
    handleFile(file) {
      window.open(file, "_blank");
    },
    handlePictureCardPreview(){},
    handleRemove(){},
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = this.form;
          recharge({
            u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
            ...form,
            file: this.fileList.length > 0 ? this.fileList[0].label : "",
          })
            .then((res) => {
              if (res.code == 1) {
                this.$message.success(res.msg);
                this.$emit("ok");
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err.msg);
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.com-title {
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  line-height: 28px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
.com-content {
  height: 300px;
  width: 100%;
  overflow: hidden;
  .com-content-item {
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    background: #dcf2fa;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    .com-content-mid {
      flex: 1;
      box-sizing: border-box;
      padding: 0 10px;
    }
    .com-content-time {
      width: 150px;
      font-size: 14px;
      color: #706c6c;
      text-align: right;
    }
    .com-content-mid {
      flex: 1;
      .com-content-name {
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
      }
      .com-content-nei {
        font-size: 14px;
        line-height: 20px;
      }
      .com-content-file {
        width: 250px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }
  .x-content-reverse {
    flex-direction: row-reverse;
  }
  .x-content-right {
    text-align: right;
  }
  .x-content-rr {
    flex-direction: row-reverse;
    text-align: right;
    float: right;
  }
}
/deep/.el-descriptions-item__cell{
  width: 50px !important;
}
/deep/.el-form-item__label{
  padding: 0 !important;
}
/deep/.el-form-item{
  margin-bottom: 10px;
}
::v-deep .no-upload-file .el-upload--picture-card {
  display: none !important;
}
/deep/.el-upload--picture-card{
  width: 100px;
  height: 100px;
  line-height: 98px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item{
  width: 100px;
  height: 100px;
}
</style>
