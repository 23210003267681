<template>
  <el-card class="box-card">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部" name="0">
        <OrderList @changelist="changelist" :activeName='activeName' v-if='activeName==0' />
      </el-tab-pane>
      <el-tab-pane label="待打单" name="4">
        <OrderList @changelist="changelist" :activeName='activeName' v-if='activeName==4' />
      </el-tab-pane>
      <el-tab-pane label="待发货" name="1">
        <OrderList @changelist="changelist" :activeName='activeName' v-if='activeName==1' />
      </el-tab-pane>
      <el-tab-pane label="已发货" name="2">
        <OrderList @changelist="changelist" :activeName='activeName' v-if='activeName==2' />
      </el-tab-pane>
      <el-tab-pane label="已取消" name="3">
        <OrderList @changelist="changelist" :activeName='activeName' v-if='activeName==3' />
      </el-tab-pane>
      <el-tab-pane label="已退款" name="5">
        <OrderList @changelist="changelist" :activeName='activeName' v-if='activeName==5' />
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
  import OrderList from './components/orderList.vue';
  export default {
    components: {
      OrderList
    },
    data() {
      return {
        activeName: '0'
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
      changelist(num){
        console.log(num)
        this.activeName = num
      }
    }
  };
</script>