/**
 * 判断是否为空
 */
export function validatenull(val) {
  if (typeof val == "boolean") {
    return false;
  }
  if (typeof val == "number") {
    return false;
  }
  if (val instanceof Array) {
    if (val.length == 0) return true;
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === "{}") return true;
  } else {
    if (val == "null" || val == null || val == "undefined" || val == undefined || val == "")
      return true;
    return false;
  }
  return false;
}

// 国家-城市数据格式转换
export const transformCountryCity = (list) => {
  // 定义转换后的数组
  let newCClist = [];
  // 遍历原始数据
  for (const item of list) {
    // 对于每个国家，创建一个新的选项对象
    const countryOption = {
      value: item.name,
      label: item.name,
      children: [],
    };
    // 遍历省份列表，并为每个省份创建子选项
    for (const province of item.province_list) {
      countryOption.children.push({
        value: province,
        label: province,
      });
    }
    // 将国家选项添加到转换后的数组
    newCClist.push(countryOption);
  }
  return newCClist;
};

// 获取州省列表
export const provinceListValue = (list, value) => {
  let prlist = list.find((item) => {
    return item.label == value;
  })
    ? list.find((item) => {
        return item.label == value;
      }).children
    : [];
  return prlist;
};

// 下载-base64转excel
export const downloadBase64AsExcel = (base64String, fileName) => {
  let base64 = base64String.split("base64,")[1]; // 去除之前的数据
  // 解码Base64字符串
  const byteCharacters = atob(base64);
  // 创建一个8位的字符数组
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  // 将整数转换为typed array
  const byteArray = new Uint8Array(byteNumbers);
  // 创建一个blob对象
  const blob = new Blob([byteArray], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // 创建URL对象
  const url = URL.createObjectURL(blob);

  // 创建下载链接
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName || "download.xlsx"; // 设置下载文件的默认文件名

  // 模拟点击
  document.body.appendChild(link);
  link.click();

  // 清理
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

// 下载本地文件
export const createDownloadLink = (href, fileName) => {
  let link = document.createElement("a");
  link.href = href;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// 货运等级
export const freightLevel = () => {
  return [50, 55, 60, 65, 70, 77.5, 85, 92.5, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500];
};

// 将托盘类型转换成数组
export const JudgePalletTypeArr = () => {
  let objlistArr =
    window.localStorage.getItem("platform_name") == "Rapiddeals"
      ? JSON.parse(window.localStorage.getItem("RapiddealsPallet"))
      : JSON.parse(window.localStorage.getItem("Priority1Pallet"));
  let PalletTypeArr = [];

  for (var key in objlistArr) {
    let obj = {};
    obj.name = key;
    obj.id = objlistArr[key];
    PalletTypeArr.push(obj);
  }
  return PalletTypeArr;
};

// 将托盘类型转换成数组查找
export const JudgePalletTypeArrFind = (val) => {
  let objlistArr =
    window.localStorage.getItem("platform_name") == "Rapiddeals"
      ? JSON.parse(window.localStorage.getItem("RapiddealsPallet"))
      : JSON.parse(window.localStorage.getItem("Priority1Pallet"));
  let PalletTypeArr = [];

  for (var key in objlistArr) {
    let obj = {};
    obj.name = key;
    obj.id = objlistArr[key];
    PalletTypeArr.push(obj);
  }

  let batName = "";
  batName = PalletTypeArr.find((item) => {
    return item.id == val;
  })
    ? PalletTypeArr.find((item) => {
        return item.id == val;
      }).name
    : "";
  return batName;
};

// 商品单位
export const productUnit = () => {
  return [
    {
      value: 1,
      label: "BAG",
    },
    {
      value: 2,
      label: "BOXES",
    },
    {
      value: 3,
      label: "CARTONS",
    },
    {
      value: 4,
      label: "CASE",
    },
    {
      value: 5,
      label: "DRUMS",
    },
    {
      value: 6,
      label: "KEGS",
    },
    {
      value: 7,
      label: "REELS",
    },
    {
      value: 8,
      label: "ROLLS",
    },
    {
      value: 9,
      label: "TOTE",
    },
    {
      value: 10,
      label: "TUBES",
    },
    {
      value: 11,
      label: "PALLETS",
    },
    {
      value: 12,
      label: "PIECES",
    },
    {
      value: 13,
      label: "CYLINDERS",
    },
    {
      value: 14,
      label: "CRATE",
    },
  ];
};

// 商品单位查找
export const productUnitFind = (val) => {
  let list = [
    {
      value: 1,
      label: "BAG",
    },
    {
      value: 2,
      label: "BOXES",
    },
    {
      value: 3,
      label: "CARTONS",
    },
    {
      value: 4,
      label: "CASE",
    },
    {
      value: 5,
      label: "DRUMS",
    },
    {
      value: 6,
      label: "KEGS",
    },
    {
      value: 7,
      label: "REELS",
    },
    {
      value: 8,
      label: "ROLLS",
    },
    {
      value: 9,
      label: "TOTE",
    },
    {
      value: 10,
      label: "TUBES",
    },
    {
      value: 11,
      label: "PALLETS",
    },
    {
      value: 12,
      label: "PIECES",
    },
    {
      value: 13,
      label: "CYLINDERS",
    },
    {
      value: 14,
      label: "CRATE",
    },
  ];
  let unitName = "";
  unitName = list.find((item) => {
    return item.value == val;
  })
    ? list.find((item) => {
        return item.value == val;
      }).label
    : "";

  return unitName;
};

// 整车地址类型
export const addressType = (val) => {
  const list = [
    {
      value: "BUSINESS",
      label: "BUSINESS",
    },
    {
      value: "RESIDENTIAL",
      label: "RESIDENTIAL",
    },
    {
      value: "CONSTRUCTIONSITE",
      label: "CONSTRUCTIONSITE",
    },
    {
      value: "TRADESHOW",
      label: "TRADESHOW",
    },
  ];
  return list;
};

// 发货特殊需求
export const originSpeci = () => {
  const list = [
    {
      value: "1",
      label: "INSIDEPICKUP",
    },
    {
      value: "2",
      label: "LIFTGATEREQUIRED",
    },
  ];
  return list;
};

// 获取发货特殊需求的输入value 返回label
export const originSpeciFind = (values) => {
  let specList = [
    {
      value: "1",
      label: "INSIDEPICKUP",
    },
    {
      value: "2",
      label: "LIFTGATEREQUIRED",
    },
  ];

  // 将逗号隔开的字符串转换为数组
  const valueArray = values.split(',');

  return valueArray
    .map((value) => {
      // 确保 value 是字符串类型
      const item = specList.find((item) => item.value === value.trim());
      return item ? item.label : null;
    })
    .filter((label) => label !== null); // 过滤掉未找到的项
};

// 这个函数接受一个label数组，返回对应的value数组，然后转换成以逗号隔开的字符串
export const originSpeciFindValues = (labels) => {
  let specList = [
    {
      value: "1",
      label: "INSIDEPICKUP",
    },
    {
      value: "2",
      label: "LIFTGATEREQUIRED",
    },
  ];
  const values = labels
    .map((label) => {
      const item = specList.find((item) => item.label === label);
      return item ? item.value : null;
    })
    .filter((value) => value !== null); // 过滤掉未找到的项
  return values.join(","); // 将数组转换成以逗号隔开的字符串
};
// 收货特殊需求
export const destSpeci = () => {
  const list = [
    {
      value: "3",
      label: "INSIDEDELIVERY",
    },
    {
      value: "4",
      label: "LIFTGATEREQUIRED",
    },
    {
      value: "5",
      label: "APPOINTMENTFEE",
    },
  ];
  return list;
};
// 获取发货特殊需求的输入value 返回label
export const destSpeciFind = (values) => {
  let specList = [
    {
      value: "3",
      label: "INSIDEDELIVERY",
    },
    {
      value: "4",
      label: "LIFTGATEREQUIRED",
    },
    {
      value: "5",
      label: "APPOINTMENTFEE",
    },
  ];
  // 将逗号隔开的字符串转换为数组
  const valueArray = values.split(',');
  return valueArray
    .map((value) => {
      const item = specList.find((item) => item.value === value.toString());
      return item ? item.label : null;
    })
    .filter((label) => label !== null); // 过滤掉未找到的项
};

// 这个函数接受一个label数组，返回对应的value数组，然后转换成以逗号隔开的字符串
export const destSpeciFindValues = (labels) => {
  let specList = [
    {
      value: "3",
      label: "INSIDEDELIVERY",
    },
    {
      value: "4",
      label: "LIFTGATEREQUIRED",
    },
    {
      value: "5",
      label: "APPOINTMENTFEE",
    },
  ];
  const values = labels
    .map((label) => {
      const item = specList.find((item) => item.label === label);
      return item ? item.value : null;
    })
    .filter((value) => value !== null); // 过滤掉未找到的项
  return values.join(","); // 将数组转换成以逗号隔开的字符串
};
/*
 **  经销商筛选数据1
 **  attr:根据该属性排序；rev:升序1或降序-1，不填默认1
 */
export const sortBy = (attr, rev) => {
  if (rev == undefined) {
    rev = 1;
  } else {
    rev ? 1 : -1;
  }
  return function (a, b) {
    a = a[attr];
    b = b[attr];
    if (a < b) {
      return rev * -1;
    }
    if (a > b) {
      return rev * 1;
    }
    return 0;
  };
};

// 经销商筛选数据2
export const filterData = (list, num) => {
  let newlist = [];
  if (num < 4) {
    list.forEach((item) => {
      if (item.carrierTransitDays == num) {
        newlist.push(item);
      }
    });
  } else {
    list.forEach((item) => {
      if (item.carrierTransitDays >= num) {
        newlist.push(item);
      }
    });
  }
  return newlist;
};

// 创建快递面单读取最后可用时间
export const findTimeIndex = (list,startTime) => {
  let index = list.indexOf(startTime);
  if (index !== -1) {
    return list.slice(index);
  }
  return []; // 如果找不到时间，返回null
}