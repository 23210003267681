<template>
  <div class="">
    <!-- 公告列表 -->
    <el-card class="box-card">
      <div class="search-form" style="text-align: left">
        <el-form ref="searchForm" :inline="true" size="small" :model="searchForm" label-width="40px" label-position="left"
          class="demo-form-inline">
          <el-form-item label="时间">
            <el-date-picker
              style="width:320px;"
              v-model="searchForm.createtime"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
            <el-button icon="el-icon-refresh-right" type="primary" size="small" @click="resetSearch">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="dataList" border size="small" height="600">
        <el-table-column type="index" :index="indexMethod" label="序号" width="60"></el-table-column>
        <el-table-column prop="orderId" label="订单号" show-overflow-tooltip width="200"></el-table-column>
        <el-table-column prop="type" label="消费类型" show-overflow-tooltip width="140">
          <template slot-scope="scope">
            {{ status(scope.row.type) }}
          </template>
        </el-table-column>
        <el-table-column prop="pay_type" label="支付方式" show-overflow-tooltip width="140"></el-table-column>
        <el-table-column prop="money" label="交易金额" show-overflow-tooltip width="140"></el-table-column>
        <el-table-column prop="variation_later" label="账户余额" show-overflow-tooltip width="140"></el-table-column>
        <el-table-column prop="remark" label="备注" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column prop="username" label="操作人" show-overflow-tooltip></el-table-column> -->
        <el-table-column prop="createtime" label="操作时间" show-overflow-tooltip width="140"></el-table-column>
      </el-table>
      <div class="pagization-box">
        <CustomPagization
          :background="true"
          :currentPage="current"
          :total="total"
          :pageSize="size"
          @current-change="currentChange"
        />
      </div>
    </el-card>
  </div>
</template>

<script>
import { balanceList } from '@/api/ltl/finance'
export default {
  data() {
    return {
      detailVisible: false,
      searchForm: {},
      current: 1,
      size: 10,
      total: 1,
      dataList: [],
      id: ''
    };
  },
  mounted(){
    this.init()
  },
    computed: {
    status() {
      return (status) => {
        switch (status) {
          case "1":
            return "充值";
          case "2":
            return "扣款";
          case "3":
            return "退款";
        }
      };
    }
  },
  methods: {
    // 打开详情
    handleView(row){
      this.detailVisible = true;
      this.id = row.id
    },
    // 关闭详情
    closeDialog() {
      this.detailVisible = false;
    },
    // 获取列表
    init(){
      let queryForm = {} 
      if(this.searchForm.createtime){
        queryForm = {
          begin_time: this.searchForm.createtime[0],
          end_time:this.searchForm.createtime[1]
        }
      }else{
        queryForm = {}
      }
      balanceList({
        u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
        page: this.current,
        limit: this.size,
        ...queryForm
      }).then(res => {
        if(res.code == 1){
          this.dataList = []
          this.dataList = res.data.data
          this.total = res.data.total
        }
      })
    },
    // table首列序号
    indexMethod(index) {
      return (this.current - 1) * this.size + index + 1;
    },
    // 页数变化
    currentChange(current) {
      this.current = current;
      this.init();
    },
    // 重置筛选
    resetSearch() {
      this.$refs["searchForm"].resetFields();
      this.searchForm = {}
      this.value1 = []
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.handleSearch();
    },
    // 去筛选
    handleSearch() {
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
.pagization-box {
  overflow: hidden;
  margin-top: 10px;

  .el-pagination {
    float: right;
  }
}
/deep/ {
  .el-table {
    thead {
      color: #fff;
      font-weight: 500;
      background: #F5F7FA;
      color: #606266;
      & th {
        background-color: transparent;
      }
      & tr {
        background-color: transparent;
      }
    }
  }
}
</style>
