import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    balance: 0,
    money: 0
  },
  mutations: {
    // LTL余额
    UPDATE_BALANCE(state, amount) {
      state.balance = amount;
    },

    // 打单余额
    UPDATE_MONEY(state, amount) {
      state.money = amount;
    }
  },
  actions: {
    updateBalance({ commit }, amount) {
      // 这里可以执行异步操作，例如 API 调用
      // 然后提交 mutation
      commit('UPDATE_BALANCE', amount);
    },
    updateMoney({ commit }, amount) {
      // 这里可以执行异步操作，例如 API 调用
      // 然后提交 mutation
      commit('UPDATE_MONEY', amount);
    }
  }
});