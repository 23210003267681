<template>
  <div class="">
    <iframe :src="filePath" width="100%" height="500px"></iframe>
  </div>
</template>

<script>
export default {
  props: {
    filePath: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>
<style lang="scss" scoped>
  
</style>