<template>
  <el-card class="min-box">
    <el-form :model="form" :rules="rules" ref="form" label-width="0" class="demo-form" size="small">
      <el-row :gutter="10">
        <el-col :span="4">
          <el-form-item prop="start_country">
            <el-select v-model="form.start_country" style="width: 100%">
              <el-option v-for="(item, index) in countryList" :key="index" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item prop="start_zip_code">
            <el-input v-model="form.start_zip_code" placeholder="ZipCode"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="1">
          <img class="jian" :src="require('@/assets/common/arr.png')" alt="" />
        </el-col>
        <el-col :span="4">
          <el-form-item prop="destination_country">
            <el-select v-model="form.destination_country" style="width: 100%">
              <el-option v-for="(item, index) in countryList" :key="index" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item prop="destination_zip_code">
            <el-input v-model="form.destination_zip_code" placeholder="ZipCode"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-form-item prop="is_default">
            <el-checkbox v-model="form.is_default" :checked="form.is_default == 1 ? true : false" :true-label="1"
              :false-label="0" name="is_default">is Residential?</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-radio-group v-model="form.pa_unit" size="small">
            <el-radio-button label="lb/in" :value="1"></el-radio-button>
            <el-radio-button label="kg/cm" :value="2"></el-radio-button>
          </el-radio-group>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="parcel-lwh">
        <el-col :span="20" style="
            background: #dfdfdf;
            box-sizing: border-box;
            padding-top: 20px;
            border-radius: 10px;
          ">
          <el-row :gutter="10">
            <el-col :span="4">
              <el-form-item prop="pa_lb">
                <el-input v-model="form.pa_lb" type="number" :controls="false"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1" style="box-sizing: border-box; padding-top: 10px">lb</el-col>
            <el-col :span="4">
              <el-form-item prop="oz">
                <el-input v-model="form.oz" type="number" :controls="false"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1" style="box-sizing: border-box; padding-top: 10px">oz L</el-col>
            <el-col :span="4">
              <el-form-item prop="pa_length">
                <el-input v-model="form.pa_length" type="number" :controls="false"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1" style="box-sizing: border-box; padding-top: 10px">W</el-col>
            <el-col :span="4">
              <el-form-item prop="pa_width">
                <el-input v-model="form.pa_width" type="number" :controls="false"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1" style="box-sizing: border-box; padding-top: 10px">H</el-col>
            <el-col :span="4">
              <el-form-item prop="pa_height">
                <el-input v-model="form.pa_height" type="number" :controls="false"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="1" style="box-sizing: border-box; padding-top: 0px"> x </el-col>
        <el-col :span="3" style="box-sizing: border-box; padding-top: 20px">
          <el-form-item prop="pack_num">
            <el-input v-model="form.pack_num" type="number" :controls="false"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" style="margin-top: 10px">
        <el-col :span="12" style="text-align: left">
          <el-radio-group v-model="search.pa_unit" size="small" @change="changeSearch">
            <el-radio-button label="All" :value="1"></el-radio-button>
            <el-radio-button label="FedEx" :value="2"></el-radio-button>
          </el-radio-group>
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-button size="small" type="primary" @click="submitForm('form')" :loading="calcLoading">Get Rates</el-button>
          <el-button size="small" @click="resetForm('form')">Reset</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table :data="dataList" border size="small" height="600" style="margin-top: 10px">
      <el-table-column prop="service" label="Service" width="300" show-overflow-tooltip></el-table-column>
      <el-table-column prop="name" label="Package Type" width="200" show-overflow-tooltip></el-table-column>
      <el-table-column prop="money" label="Rate" show-overflow-tooltip></el-table-column>
      <el-table-column prop="action" label="Action" width="140">
        <template slot-scope="scope">
          <div>
            <span style="color: #409eff; cursor: pointer" @click="handleEdit(scope.row)"><i
                class="el-icon-more"></i></span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagization-box">
      <CustomPagization :background="true" :currentPage="current" :total="total" :pageSize="size"
        @current-change="currentChange" />
    </div>
  </el-card>
</template>

<script>
import { orderw } from "@/api/calc";
import { City } from "@/api/common";
import { transformCountryCity } from "@/common/common.js";
export default {
  data() {
    return {
      calcLoading: false,
      form: {
        user_code: "1", //用户编码
        start_zip_code: "", //发件人邮编
        destination_zip_code: "", //收件人邮编
        start_country: "United States", //发件人国家
        destination_country: "United States", //收件人国家
        pa_unit: 'lb/in', //单位 1:lb2:kg
        pa_lb: 0, //重量
        pa_length: 0, //长
        pa_width: 0, //宽
        pa_height: 0, //高
        pack_num: 1, //包裹数量
        oz: 0,
      },
      current: 1,
      size: 20,
      total: 1,
      dataList: [],
      AllList: [],
      search: {
        pa_unit: 'All'
      },
      rules: {
        start_country: [{ required: true, message: "请选择发货国家", trigger: "change" }],
        start_zip_code: [{ required: true, message: "请输入发货邮编", trigger: "blur" }],
        destination_country: [{ required: true, message: "请选择收货国家", trigger: "blur" }],
        destination_zip_code: [{ required: true, message: "请输入收货邮编", trigger: "change" }],
        pa_lb: [{ required: true, message: "请输入重量", trigger: "blur" }],
        pa_length: [{ required: true, message: "请输入长", trigger: "blur" }],
        pa_width: [{ required: true, message: "请输入宽", trigger: "blur" }],
        pa_height: [{ required: true, message: "请输入高", trigger: "blur" }],
      },
      countryList: [], // 国家-城市
    };
  },
  mounted() {
    this.getCity();
  },
  methods: {
    // 获取城市
    getCity() {
      City().then((res) => {
        this.countryList = transformCountryCity(res.data);
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = {
            ...this.form,
            pa_unit: this.form.pa_unit == 'lb/in' ? 1 : 2
          }
          this.calcLoading = true
          orderw({
            user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code,
            ...form,
          })
            .then((res) => {
              this.calcLoading = false
              if (res.code == 1) {
                this.$message.success(res.msg);
                this.dataList = res.data.success;
                this.AllList = res.data.success
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.calcLoading = false
              this.$message.error(err.msg);
            });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.form = {
        user_code: "1", //用户编码
        start_zip_code: "", //发件人邮编
        destination_zip_code: "", //收件人邮编
        start_country: "United States", //发件人国家
        destination_country: "United States", //收件人国家
        pa_unit: 'lb/in', //单位 1:lb2:kg
        pa_lb: 0, //重量
        pa_length: 0, //长
        pa_width: 0, //宽
        pa_height: 0, //高
        pack_num: 1, //包裹数量
        oz: 0,
      };
      this.dataList = []
      this.AllList = []
    },
    // 页数变化
    currentChange(current) {
      this.current = current;
      this.getAddress();
    },
    // 筛选列表
    changeSearch(val){
      if(val == 'All'){
        this.dataList = this.AllList
      }else{
        this.dataList = this.AllList.filter(item => item.service.toLowerCase().includes(val.toLowerCase()));
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.min-box {
  min-width: 1200px;

  .jian {
    width: 30px;
    height: 30px;
  }
}

.pagization-box {
  overflow: hidden;
  margin-top: 10px;

  .el-pagination {
    float: right;
  }
}

/deep/ {
  .el-table {
    thead {
      color: #fff;
      font-weight: 500;
      background: #f5f7fa;
      color: #606266;

      & th {
        background-color: transparent;
      }

      & tr {
        background-color: transparent;
      }
    }
  }
}

.parcel-lwh {
  display: flex;
  align-items: center;

  /deep/ input::-webkit-outer-spin-button,
  /deep/ input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  /deep/ input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
