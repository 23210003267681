<template>
  <div class="x-ly-top">
    <div class="top-bar__left">
      <div class="x-ly-breadcrumb">
        <i class="el-icon-s-unfold" v-if="isCollapse" @click="changeIsCollapse(false)"></i>
        <i class="el-icon-s-fold" v-else @click="changeIsCollapse(true)"></i>
      </div>
    </div>
    <div class="top-bar__title"></div>
    <div class="top-bar__right">
      <img class="calc" :src="require('@/assets/common/calc.png')" alt="" @click="hanldeCalc"/>
      <el-popover
        placement="bottom"
        width="350"
        style="line-height: 24px;"
        trigger="hover"
        >
        <div class="mes-i" slot="reference">
          <span class="mes-red" v-if='newsList.length > 0'></span>
          <i class="el-icon-bell" style="line-height: 24px"></i>
        </div>
        <Message :newsList='newsList' v-if="newsList.length > 0"></Message>
        <el-empty description="暂无新通知消息~" v-else></el-empty>
      </el-popover>
      <div class="top-money-info">
        <div style="margin-right: 20px">打单余额：${{ money }}</div>
      </div>
      <div class="top-money-info">
        <div style="margin-right: 20px">LTL余额：${{ balance }}</div>
      </div>
      <img class="top-bar__img" :src="userInfo.avatar ? userInfo.avatar : url" @click="gotoUser" />
      <el-dropdown>
        <span class="el-dropdown-link" @click="gotoUser">
          {{ userInfo.username }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="handleLogOut"> 退出登录 </el-dropdown-item>
          <!-- <el-dropdown-item @click.native="logout"
                            divided>退出登录</el-dropdown-item> -->
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import { usersInfo } from "@/api/user";
import Message from '@/components/message.vue';
import { afficheList } from '@/api/ltl/news'
import { validatenull } from "@/common/common.js"
import { mapState, mapActions } from 'vuex';
export default {
  name: "top",
  props: ["triggerMethod"],
  components:{
    Message
  },
  data() {
    return {
      isCollapse: false, //是否折叠左侧菜单栏  false不折叠
      userInfo: {},
      url: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      // balance: 0,
      newsList: []
    };
  },
   computed: {
    ...mapState({
      balance: state => state.balance,
      money: state => state.money,
    })
  },
  created() {
    this.userInfo = JSON.parse(window.localStorage.getItem("userInfo1"));
    this.getUsersInfo();
    this.getNewss()
  },
  methods: {
    ...mapActions(['updateBalance','updateMoney']),
    // 获取用户信息
    getUsersInfo() {
      usersInfo({ user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code }).then((res) => {
        if (res.code == 1) {
          this.updateBalance(res.data.userinfo.balance);
          this.updateMoney(res.data.userinfo.money)
        }
      });
    },
    changeIsCollapse(e) {
      this.isCollapse = e;
      this.triggerMethod(e);
    },
    handleLogOut() {
      window.localStorage.removeItem("userInfo1");
      this.$router.push("/login");
    },
    gotoUser() {
      // this.$router.push('/info')
      // this.$router.push({ path: '/ltl/index'});
    },
    // 点击计算器
    hanldeCalc(){
      this.$emit('calc')
    },
    // 获取通知公告
    getNewss(){
      afficheList({
        u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
        page: 1,
        limit: 1000,
      }).then(res => {
        if(res.code == 1){
          this.newsList = []
          res.data.data.forEach(item => {
            if(validatenull(item.read)){
              this.newsList.push(item)
            }
          })
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.x-ly-top {
  position: relative;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  color: #fff;
  font-size: 28px;
  height: 50px;
  line-height: 50px;
  box-sizing: border-box;
  white-space: nowrap;
  background: #fff;
}
.x-ly-breadcrumb {
  height: 100%;
  i {
    font-size: 28px !important;
    cursor: pointer;
  }
  &--active {
    transform: rotate(90deg);
  }
}
.top-bar__img {
  margin: 0 5px;
  padding: 2px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  box-sizing: border-box;
  border: 1px solid #eee;
  vertical-align: middle;
  cursor: pointer;
}

.top-bar__left,
.top-bar__right {
  height: 50px;
  position: absolute;
  margin-top: 2px;
  top: 0;
  color: #409eff;
  i {
    line-height: 50px;
  }
  .el-dropdown-link {
    color: #409eff;
  }
}

.top-bar__left {
  left: 10px;
}

.top-bar__right {
  right: 10px;
  display: flex;
  align-items: center;
  /deep/.x-ly-top {
    line-height: 20px !important;
  }
  .calc{
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
}
.top-bar__title {
  height: 100%;
  padding-left: 30px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: inherit;
  font-weight: 400;
}
.top-money-info {
  display: flex;
  font-size: 14px;
}
.mes-i {
  font-size: 17px;
  margin-right: 10px;
  position: relative;
  width: 25px;
  height: 25px;
  .mes-red {
    width: 6px;
    height: 6px;
    background: #f56c6c;
    border-radius: 50%;
    position: absolute;
    right: -2px;
    top: 2px;
  }
}
</style>
