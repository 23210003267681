import { render, staticRenderFns } from "./invoiceView.vue?vue&type=template&id=00d5b2bb&scoped=true"
import script from "./invoiceView.vue?vue&type=script&lang=js"
export * from "./invoiceView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00d5b2bb",
  null
  
)

export default component.exports