<template>
  <div class="cause">
    {{ refusal_cause }}
  </div>
</template>

<script>
export default {
  props: {
    refusal_cause: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>
<style lang="scss" scoped>
  .cause{
    width: 100%;
    font-size: 14px;
  }
</style>