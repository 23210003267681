<template>
  <div class="">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      size="small"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="项目名称" prop="name">
            <el-input
              v-model="ruleForm.name"
              placeholder="请输入项目名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="货运等级" prop="freight_class">
            <el-select
              v-model="ruleForm.freight_class"
              placeholder="请选择货运等级"
              style="width: 100%"
            >
              <el-option v-for="(item,index) in classList" :key="index" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="长" prop="length">
            <el-input
              v-model.number="ruleForm.length"
              :controls='false'
              :min="0"
              placeholder="请输入长"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="宽" prop="width">
            <el-input
              v-model.number="ruleForm.width"
              placeholder="请输入宽"
              :controls='false'
              :min="0"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="高" prop="height">
            <el-input
              v-model.number="ruleForm.height"
              placeholder="请输入高"
              :controls='false'
              :min="0"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="重量" prop="weight">
            <el-input
              v-model.number="ruleForm.weight"
              placeholder="请输入重量"
              :controls='false'
              :min="0"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="打托类型" prop="battering_type">
            <el-select
              v-model="ruleForm.battering_type"
              placeholder="请选择打托类型"
              style="width: 100%"
            >
              <el-option v-for="(item, index) in battList" :key="index" :label="item.name" :value="item.id.toString()" ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="商品单位" prop="product_unit">
            <el-select
              v-model="ruleForm.product_unit"
              placeholder="请选择商品单位"
              style="width: 100%"
            >
              <el-option v-for="(item, index) in unitList" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="货物类别编码">
            <el-input
              placeholder="请输入货物类别编码"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="子类别编码">
            <el-input
              placeholder="请输入子类别编码"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- <el-form-item prop="isDefault">
        <el-checkbox
          v-model="ruleForm.isDefault"
          :checked="ruleForm.is_default == 1 ? true : false"
          :true-label="1"
          :false-label="0"
          name="isDefault"
          >设置成默认地址</el-checkbox
        >
      </el-form-item> -->
      <el-form-item style="text-align: right">
        <el-button size="small" type="primary" @click="submitForm('ruleForm')"
          >提交</el-button
        >
        <el-button size="small" @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { productAdd, productUpdate } from "@/api/ltl/product";
import { validatenull, freightLevel, JudgePalletTypeArr, productUnit } from "@/common/common.js";
export default {
  props: {
    record: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ruleForm: {},
      rules: {
        name: [{ required: true, message: "请输入项目名称", trigger: "blur" }],
        length: [{ required: true, message: "请输入长", trigger: "blur" }],
        width: [{ required: true, message: "请输入宽", trigger: "blur" }],
        height: [{ required: true, message: "请输入高", trigger: "blur" }],
        weight: [{ required: true, message: "请输入重量", trigger: "blur" }],
      },
      id: "",
      classList: freightLevel(),
      battList: JudgePalletTypeArr(),
      unitList: productUnit()
    };
  },
  mounted() {
    if (!validatenull(this.record)) {
      this.id = this.record.id;
      this.ruleForm = {
        ...this.record,
        product_unit: this.record.product_unit > 0 ? this.record.product_unit : ''
      };
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = this.ruleForm;
          if (this.id) {
            productUpdate({
              id: this.id,
              u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
              ...form,
            })
              .then((res) => {
                if (res.code == 1) {
                  this.$message.success(res.msg);
                  this.$emit("ok");
                } else {
                  this.$message.error(res.msg);
                }
              })
              .catch((err) => {
                this.$message.error(err.msg);
              });
          } else {
            productAdd({
              u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
              ...form,
            })
              .then((res) => {
                if (res.code == 1) {
                  this.$message.success(res.msg);
                  this.$emit("ok");
                } else {
                  this.$message.error(res.msg);
                }
              })
              .catch((err) => {
                this.$message.error(err.msg);
              });
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.ruleForm = {};
    },
  },
};
</script>
<style lang="scss" scoped>
  /deep/ input::-webkit-outer-spin-button,
  /deep/ input::-webkit-inner-spin-button {
    -webkit-appearance: none!important;
  }
</style>
