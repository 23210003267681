<template>
  <div class="box">
    <div
      style="width: 100mm;height: 150mm;background: #fff;margin: 0 auto;box-sizing: border-box;padding: 1mm 1.2mm;margin-bottom: 10px;" v-for="(item,index) in package_list" :key="index">
      <!-- 第一行 -->
      <div style="width: 100%;height: 17mm;border-bottom: 1px solid #000;">
        <div style="width:57.6mm;height: 100%;border-right: 1px solid #000;float: left;transform: scale(0.95);">
          <p style="font-size: 13px;line-height: 14px;">FROM:</p>
          <div style="width: 50mm;height: 4mm;">
            <p style="font-size: 12px;float: left;line-height: 13px;">{{ send_data.realname }}</p>
            <p style="font-size: 12px;float: left;line-height: 13px;margin-left: 15px;">{{ send_data.mobile }}</p>
          </div>
          <p style="font-size: 12px;line-height: 13px;">{{ send_data.address_one }}</p>
          <p style="font-size: 12px;line-height: 13px;">{{ send_data.city }} {{ send_data.province }} {{ send_data.zcode }}</p>
        </div>
        <div style="width: 39.4mm;height: 100%;float: left;transform: scale(0.9);">
          <p style="font-size: 12px;line-height: 13px;color: #666;">SHIP DATE: 21 JUN21</p>
          <p style="font-size: 12px;line-height: 13px;color: #666;">ACTWGT: {{ item.pa_lb }} {{ item.pa_unit == 1 ? 'LB' :'KG' }}</p>
          <p style="font-size: 12px;line-height: 13px;color: #666;">DIMMED: {{ item.pa_length }} X {{ item.pa_width }} X {{ item.pa_height }} {{ item.pa_unit == 1 ? 'IN' :'cm' }}</p>
          <p style="font-size: 12px;line-height: 13px;color: #666;margin-top: 6px;">BILL SENDER</p>
        </div>
      </div>
      <!-- 第二行 -->
      <div style="height: 32.9mm;width: 100%;border-bottom: 1px solid #000;box-sizing: border-box;padding-left: 5px;">
        <p style="font-size: 13px;line-height: 14px;">TO:</p>
        <p style="font-size: 12px;line-height: 14px;">{{ receive_data.realname }}</p>
        <p style="font-size: 12px;line-height: 14px;margin-top: 5px;">{{ receive_data.address_one }}</p>
        <p style="font-size: 13px;line-height: 18px;margin-top: 20px;">{{ receive_data.city }} {{ receive_data.province }} {{ receive_data.zcode }}</p>
        <p style="font-size: 13px;line-height: 18px;">{{ receive_data.mobile }}</p>
      </div>
      <!-- 第三行 -->
      <div style="width: 100%;height:36.4mm;border-bottom: 1px solid #000;box-sizing: border-box;">
        <div
          style="width:38.6mm;height:43.2mm;border: 1px solid #000;float: left;transform: scale(0.5);box-sizing: border-box;padding-left: 6px;">
          <p style="font-size: 15px;line-height: 50px;">{{ send_data.province }}</p>
          <p style="font-size: 15px;line-height: 50px;">{{ send_data.city }}</p>
          <p style="font-size: 15px;line-height: 50px;">{{ send_data.zcode }}</p>
        </div>
        <img style="width:38.6mm;height:43.2mm;float: left;transform: scale(0.5);margin-left: -30px;"
          :src="require('@/assets/common/jian.png')"></img>
        <div
          style="width:38.6mm;height:43.2mm;border: 1px solid #000;float: left;transform: scale(0.5);box-sizing: border-box;padding-left: 10px;margin-left: -40px;">
          <p style="font-size: 15px;line-height: 50px;">{{ receive_data.province }}</p>
          <p style="font-size: 15px;line-height: 50px;">{{ receive_data.city }}</p>
          <p style="font-size: 15px;line-height: 50px;">{{ receive_data.zcode }}</p>
        </div>
      </div>
      <!-- 第四行 -->
      <div style="width: 100%;height: 61.5mm;">
        <div style="width: 100%;height: 8mm;box-sizing: border-box;padding-top: 10px;">
          <div style="font-size: 12px; float: left;">TRK#</div>
          <div style="font-size: 14px;width: 70mm;float: left;margin-left: 6px;height: 5mm;">{{ item.porder_sn }}</div>
          <div style="font-size: 14px;float: left;">{{ index + 1 }} OF {{ package_list.length }}</div>
        </div>
        <div style="width: 100%;margin-top: 16mm;display: flex;justify-content: center;align-items: center;">
          <barcode v-if="item.porder_sn" :value="item.porder_sn" :options="{ format: 'EAN-13' }" class="barcode"></barcode>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeinfo } from '@/api/order'
import Barcode from 'vue-barcode';
export default {
  components: {
    barcode: Barcode
  },
  props: {
    order_id: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      receive_data: {},
      send_data: {},
      goods_info: {},
      package_list: [],
    }
  },
  mounted(){
    this.getItoreinfo()
  },
  methods: {
    getItoreinfo() {
      storeinfo({
        user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code,
        order_id: this.order_id
      }).then(res => {
        this.receive_data = res.data.receive_data
        this.send_data = res.data.send_data
        this.goods_info = res.data.goods_info
        this.package_list = res.data.package_list
      })
    }
  }
}
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.box {
  width: 100%;
  height: 152mm;
  overflow-y: scroll;
}
/* 针对 Webkit 浏览器 */
.box::-webkit-scrollbar {
  width: 0; /* 隐藏垂直滚动条 */
  height: 0; /* 隐藏水平滚动条 */
}

/* 针对 Internet Explorer */
.box {
  -ms-overflow-style: none;
}

/* 针对 Firefox */
.box {
  scrollbar-width: none; /* Firefox */
}
.barcode-svg{
  width:84.3mm;
  height: 25.7mm;
  margin: 0 auto;
}
.vue-barcode-element{
  width:84.3mm;
  height: 25.7mm;
}
</style>