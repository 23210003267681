<template>
  <div class="barcode">
    <barcode :value="platform_order_sn" :options="{ format: 'EAN-13' }"></barcode>
  </div>
</template>

<script>
import Barcode from 'vue-barcode';

export default {
  components: {
    barcode: Barcode
  },
  props: {
    platform_order_sn: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // platform_order_sn: '1234567890128' // 你的条形码值
    };
  }
};
</script>
<style lang='scss' scoped>
  .barcode{
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>