<template>
  <div class="login-container">
    <div class="background-image"></div>
    <div class="login-form">
      <el-image
        style="width: 157px; height: 50px;margin: 30px auto 10px;"
        :src="require('@/assets/logo.png')"
        fit="contain"
      ></el-image>
      <!-- <p class="login-tip">账号登录</p> -->
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
        label-width="50px"
      >
        <el-form-item label='账号' prop="username">
          <el-input v-model="ruleForm.username" clearable placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label='密码' prop="password">
          <el-input v-model="ruleForm.password" type='password' autocomplete="off" clearable placeholder="请输入密码"></el-input>
        </el-form-item>
        <!-- <div class="no-pass" @click="formVisible = true">忘记密码</div> -->
        <div class="no-pass">
          <span class="no-pass-btn" @click="goregister">注册账号</span>
        </div> 
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')" style="width: 100%">登录</el-button>
          <!-- <div @click='linshi'>临时登录</div> -->
        </el-form-item>
      </el-form>
    </div>
    <!-- <div class="x-tips">CTS GLOBAL SUPPLY CHAIN SOLUTIONS</div> -->
    <!-- 忘记密码 -->
    <el-dialog
      title="忘记密码"
      append-to-body
      :visible.sync="formVisible"
      width="500px"
      :before-close="closeDialog"
    >
      <Pass
        ref="formModal"
        :record="record"
        v-if="formVisible"
        append-to-body
      />
    </el-dialog>
  </div>
</template>

<script>
import { usersLogin } from '@/api/login'
// import { usersLogin } from '@/api/ltl/login'
import Pass from './components/pass.vue';
export default {
  components: {
    Pass
  },
  data() {
    return {
      formVisible: false,
      ruleForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    goregister(){
      this.$router.push({ path: '/register'})
    },
    // 关闭
    closeDialog(){
      this.formVisible = false
    },
    // 登录/注册
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          usersLogin(this.ruleForm).then(res => {
            if(res.code == 1){
              window.localStorage.setItem("userInfo1",JSON.stringify(res.data.userinfo)) //cts
              // window.localStorage.setItem("userInfo1",JSON.stringify(res.data))  // ltl
              this.$message.success(res.msg)
              this.$router.push({ path: '/ltl/index'});
            }else{
              this.$message.error(res.msg)
            }
          })
        } else {
          return false;
        }
      });
    },
    // 临时注册
    linshi(){
      let data = {
        address: "1212",
        district: "长安区",
        email: "1@1.com",
        id: 69,
        nickname: "178****5718",
        phone: "12345678900",
        province: "河北省",
        sex: 1,
        usernames: "vr",
        zip_code: "1231321"
      }
      window.localStorage.setItem("userInfo1",JSON.stringify(data))
      this.$router.push({ path: '/bill/place'});
    }
  },
};
</script>

<style lang='scss' scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/bg.jpg");
  background-size: cover;
  /* opacity: 0.5; */
}

.login-form {
  width: 450px;
  height: 400px;
  z-index: 99;
  box-sizing: border-box;
  padding: 0 40px;
  background:#fff !important;
  border-radius: 8px;
  .code{
    font-size: 14px;
    cursor: pointer;
    color: #409EFF;
    margin-left: 10px;
    flex: 1;
    text-align: right;
    width: 100px;
    float: right;
  }
  .login-tip{
    width: 100%;
    font-size: 14px;
    padding: 0;
    margin: 0;
    height: 30px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 30px;
  }
  .demo-ruleForm{
    width: 350px;
    height: 300px;
  }
}

/deep/.el-input--prefix .el-input__inner{
  padding-left: 40px !important;
}
.no-pass{
  width: 100%;
  text-align: right;
  margin-bottom: 10px;
  .no-pass-btn{
    line-height: 28px;
    font-size: 14px;
    cursor: pointer;
    color: #409EFF;
  }
}
.x-tips{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  text-align: center;
  font-size: 16px;
  color: #fff;
}
</style>
