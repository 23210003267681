<template>
  <el-card class="box-card">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="`全部(${count.all_count})`" name="0">
        <OrderList @changelist="changelist" :activeName="activeName" v-if="activeName == 0" />
      </el-tab-pane>
      <el-tab-pane :label="`待审核(${count.check_pending})`" name="1">
        <OrderList @changelist="changelist" :activeName="activeName" v-if="activeName == 1" />
      </el-tab-pane>
      <el-tab-pane :label="`审核拒绝(${count.approval_rejection})`" name="2">
        <OrderList @changelist="changelist" :activeName="activeName" v-if="activeName == 2" />
      </el-tab-pane>
      <el-tab-pane :label="`待提货(${count.to_be_picked})`" name="3">
        <OrderList @changelist="changelist" :activeName="activeName" v-if="activeName == 3" />
      </el-tab-pane>
      <el-tab-pane :label="`运输中(${count.in_transit_up})`" name="4">
        <OrderList @changelist="changelist" :activeName="activeName" v-if="activeName == 4" />
      </el-tab-pane>
      <el-tab-pane :label="`送达(${count.delivered})`" name="5">
        <OrderList @changelist="changelist" :activeName="activeName" v-if="activeName == 5" />
      </el-tab-pane>
      <el-tab-pane :label="`订单取消(${count.cancelled})`" name="6">
        <OrderList @changelist="changelist" :activeName="activeName" v-if="activeName == 6" />
      </el-tab-pane>
      <el-tab-pane :label="`拒签(${count.reject})`" name="7">
        <OrderList @changelist="changelist" :activeName="activeName" v-if="activeName == 7" />
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import OrderList from "./components/orderList.vue";
export default {
  components: {
    OrderList,
  },
  data() {
    return {
      activeName: "0",
      count: {
        all_count: 0,
        approval_rejection: 0,
        cancelled: 0,
        check_pending: 0,
        delivered: 0,
        in_transit_up: 0,
        reject: 0,
        to_be_picked: 0,
      },
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    changelist(type, num) {
      if (type == "数量") {
        this.count = num
      } else {
        this.activeName = num;
      }
    },
  },
};
</script>
