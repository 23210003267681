<template>
  <div class="info">
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="100px"
      class="demo-form"
      label-position="top"
    >
      <el-row :gutter="10">
        <el-col :span="12">
          <div class="place-title">
            <span>个人信息</span>
          </div>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="名" prop="name">
                <el-input v-model="form.name" placeholder="请输入名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="姓" prop="surname">
                <el-input v-model="form.surname" placeholder="请输入姓"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号" prop="phone">
                <el-input v-model="form.phone" placeholder="请输入手机号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电话分机" prop="phone_call">
                <el-input v-model="form.phone_call" placeholder="请输入电话分机"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="place-title">公司信息</div>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="公司名称" prop="company_name">
                <el-input v-model="form.company_name" placeholder="请输入公司名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="公司地址" prop="company_address">
                <el-input v-model="form.company_address" placeholder="请输入公司地址"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="营业执照" prop="file">
                <el-upload
                  :class="fileList.length >= 1 ? 'no-upload-file' : ''"
                  action="https://admin.bbtexus.com/api/common/upload"
                  list-type="picture-card"
                  :on-success="handleSuccess"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  :limit='1'
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="place-title">密码</div>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="当前密码" prop="before_password">
                <el-input v-model="form.before_password" placeholder="请输入当前密码"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="新密码" prop="password">
                <el-input v-model="form.password" placeholder="请输入新密码"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="确认密码" prop="qpassword">
                <el-input v-model="form.qpassword" placeholder="请输入确认密码"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button type="primary" @click="submitForm('form')" style="width: 150px">确认</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { usersInfo, usersUpdate } from "@/api/ltl/user";
const dataCity = require("@/common/city.json");
export default {
  data() {
    return {
      value: [],
      dataCity,
      url: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      form: {},
      rules: {
        name: [{ required: true, message: "请输入名", trigger: "blur" }],
        surname: [{ required: true, message: "请输入姓", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
      },
      fileList: [],
    };
  },
  created() {
    this.getUsersInfo();
  },
  methods: {
    handleBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isLt2M;
    },
    handleSuccess(res) {
      let list = [{
        label: res.data.url,
        value: res.data.fullurl,
        url: res.data.fullurl
      }];
      this.fileList = list;
    },
    handleRemove(file) {
      this.fileList = [];
    },
    // 获取用户信息
    getUsersInfo() {
      usersInfo({ u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id }).then((res) => {
        if (res.code == 1) {
          this.form = res.data;
          if(res.data.business_license){
            this.fileList = [{
              label: res.data.business_license,
              value: res.data.business_license_http,
              url: res.data.business_license_http
            }];
          }
        }
      });
    },
    // 编辑用户信息
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = {
            ...this.form,
            business_license: this.fileList.length > 0 ? this.fileList[0].label : "",
          };
          delete form.business_license_http;
          usersUpdate({
            id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
            nickname: this.form.nickname,
            business_license: this.fileList.length > 0 ? this.fileList[0].label : "",
            ...form
          }).then((res) => {
            if (res.code == 1) {
              this.$message.success(res.msg);
              this.getUsersInfo();
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.info {
  background: #fff;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}
/deep/.el-form-item__content {
  text-align: left;
}
.place-title {
  width: 100%;
  font-size: 15px;
  line-height: 24px;
  text-align: left;
  color: #409eff;
  border-bottom: 1px solid #409eff;
  margin-bottom: 10px;
  padding-bottom: 5px;
}
.dis {
  /deep/.el-input__inner {
    background: #f5f7fa;
  }
}
/deep/.el-form-item__label {
  padding: 0 !important;
  text-align: left;
  width: 100%;
}
/deep/.el-form-item {
  margin-bottom: 10px;
}
.user-name {
  font-size: 13px;
  color: #000;
}
::v-deep .no-upload-file .el-upload--picture-card {
  display: none !important;
}
/deep/.el-upload--picture-card{
  width: 100px;
  height: 100px;
  line-height: 98px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item{
  width: 100px;
  height: 100px;
}
/deep/.el-form-item--small.el-form-item{
  margin-bottom: 10px;
}
</style>
