<template>
  <div class="">
    <!-- 公告列表 -->
    <el-card class="box-card">
      <div class="search-form" style="text-align: left">
        <el-form ref="searchForm" :inline="true" size="small" :model="searchForm" label-width="80px" label-position="left"
          class="demo-form-inline">
          <el-form-item label="通知时间">
            <el-date-picker
              style="width:320px;"
              v-model="searchForm.createtime"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
            <el-button icon="el-icon-refresh-right" type="primary" size="small" @click="resetSearch">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="dataList" border size="small" height="600">
        <el-table-column type="index" :index="indexMethod" label="序号" width="60"></el-table-column>
        <el-table-column prop="title" label="通知标题" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createtime" label="通知时间" width='150' show-overflow-tooltip></el-table-column>
        <el-table-column prop="action" label="操作" width='120' show-overflow-tooltip>
          <template slot-scope="scope">
            <div>
              <span style="color: #409eff; cursor: pointer" @click="handleView(scope.row)">查看</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagization-box">
        <CustomPagization
          :background="true"
          :currentPage="current"
          :total="total"
          :pageSize="size"
          @current-change="currentChange"
        />
      </div>
    </el-card>
    <!-- 详情弹窗 -->
    <el-dialog
      title="通知详情"
      append-to-body
      :visible.sync="detailVisible"
      width="1000px"
      :before-close="closeDialog"
    >
      <NewsDetail
        ref="formModal"
        v-if="detailVisible"
        :id='id'
        append-to-body
      />
    </el-dialog>
  </div>
</template>

<script>
import { afficheList } from '@/api/ltl/news'
import NewsDetail from "./newsDetail";
export default {
  components: {
    NewsDetail
  },
  data() {
    return {
      detailVisible: false,
      searchForm: {},
      current: 1,
      size: 10,
      total: 1,
      dataList: [],
      id: ''
    };
  },
  mounted(){
    this.init()
  },
  methods: {
    // 打开详情
    handleView(row){
      this.detailVisible = true;
      this.id = row.id
    },
    // 关闭详情
    closeDialog() {
      this.detailVisible = false;
    },
    // 获取列表
    init(){
      let queryForm = {} 
      if(this.searchForm.createtime){
        queryForm = {
          start_time: this.searchForm.createtime[0],
          end_time:this.searchForm.createtime[1]
        }
      }else{
        queryForm = {}
      }
      afficheList({
        u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
        page: this.current,
        limit: this.size,
        ...queryForm
      }).then(res => {
        if(res.code == 1){
          this.dataList = []
          this.dataList = res.data.data
          this.total = res.data.total
        }
      })
    },
    // table首列序号
    indexMethod(index) {
      return (this.current - 1) * this.size + index + 1;
    },
    // 页数变化
    currentChange(current) {
      this.current = current;
      this.init();
    },
    // 重置筛选
    resetSearch() {
      this.$refs["searchForm"].resetFields();
      this.searchForm = {}
      this.value1 = []
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.handleSearch();
    },
    // 去筛选
    handleSearch() {
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
.pagization-box {
  overflow: hidden;
  margin-top: 10px;

  .el-pagination {
    float: right;
  }
}
/deep/ {
  .el-table {
    thead {
      color: #fff;
      font-weight: 500;
      background: #F5F7FA;
      color: #606266;
      & th {
        background-color: transparent;
      }
      & tr {
        background-color: transparent;
      }
    }
  }
}
/deep/.el-dialog__body{
  padding: 0 20px 30px;
}
</style>
