<!--防洪体系-->
<template>
  <div class="box-card">
    <div class="search-form" style="text-align: left">
      <el-form
        ref="searchForm"
        :inline="true"
        :model="searchForm"
        label-width="85px"
        label-position="left"
        class="demo-form-inline"
        size="small"
      >
        <el-form-item label="平台订单号">
          <el-input
            clearable
            size="small"
            v-model="searchForm.orderId"
            placeholder="请输入平台订单号"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户订单号">
          <el-input
            clearable
            size="small"
            v-model="searchForm.bolplNumber"
            placeholder="请输入客户订单号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh-right" type="primary" size="small" @click="resetSearch"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="" style="margin-bottom: 15px; text-align: left">
      <el-button type="primary" size="small" @click="handleAdd">在线报价</el-button>
    </div>
    <el-table :data="dataList" ref="multipleTable" border size="small" height="500">
      <el-table-column type="index" :index="indexMethod" label="序号" width="50"></el-table-column>
      <el-table-column prop="order_sn" label="基本信息" show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="order-info">
            <div class="order-item">
              <span class="order-name">平台订单号：</span>
              <span class="order-value" style="cursor: pointer;" @click="handleView(scope.row.id, scope.row.orderId)">{{
                scope.row.orderId
              }}</span>
              <i
                class="el-icon-document-copy"
                style="color: #409eff; margin-left: 4px"
                @click="copy(scope.row.orderId)"
              ></i>
            </div>
            <div class="order-item">
              <span class="order-name">PRO #：</span>
              <span class="order-value">{{ scope.row.pro }}</span>
            </div>
            <div class="order-item">
              <span class="order-name">查询编码：</span>
              <span class="order-value"></span>
            </div>
            <div class="order-item">
              <span class="order-name">客户订单号：</span>
              <span class="order-value">{{ scope.row.bolplNumber }}</span>
            </div>
            <div class="order-item">
              <span class="order-name">REF：</span>
              <span class="order-value">{{ scope.row.referenceNumber }}</span>
            </div>
            <div class="order-item" style="margin-top: 20px">
              <span class="order-name">创建时间：</span>
              <span class="order-value">{{ scope.row.createtime }}</span>
            </div>
            <div class="order-item">
              <span class="order-name">账号：</span>
              <span class="order-value">{{ userInfo.username ? userInfo.username : "" }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="hb_order_sn" label="订单详情" show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="order-info">
            <div class="order-item order-item-flex">
              <div>
                <span class="order-name">承运商：</span>
                <span class="order-value">{{ scope.row.carrierName }}</span>
              </div>
              <div>
                <span class="order-name">状态：</span>
                <span class="order-value">{{ status(scope.row.orderStatus) }}</span>
              </div>
            </div>
            <div class="order-item">
              <span class="order-name">模式：</span>
              <span class="order-value">{{ scope.row.selectMode }}</span>
            </div>
            <div class="order-item">
              <span class="order-name">发货时间：</span>
              <span class="order-value" style="margin-right: 5px">{{ scope.row.originDate }}</span
              >|
              <span class="order-name">下单来源：</span>
              <span class="order-value">网站</span>
            </div>
            <div class="order-item">
              <span class="order-name">订单文件：</span>
              <span class="order-value">BOL | SHIPPING</span>
            </div>
            <div class="order-item">
              <span class="order-name">订单类型：</span>
              <span class="order-value">普通文件</span>
            </div>
            <div class="order-item" style="margin-top: 20px">
              <span class="order-name">货物信息：</span>
              <p v-for="(item, index) in scope.row.info" :key="index">
                {{ item.commodity }} / {{ item.quantity }}
                {{ orderOrderCheck(item.packageTypeId) }} / {{ item.handlingUnits }} /
                {{ item.length }} in X {{ item.width }} in X {{ item.height }} in /
                {{ item.weight }} lb
              </p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="hb_order_sn" label="发货信息" show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="order-info">
            <div class="order-item">
              <span class="order-name">发货信息：</span>
              (<span class="order-value"
                >特殊服务：{{
                  scope.row.originSpecificIds == 0 ? "无" : scope.row.originSpecificIds
                }}</span
              >)
            </div>
            <div class="order-item">
              <p>
                {{ scope.row.originLocationName }} / {{ scope.row.originAddressLine1 }},
                {{ scope.row.originCityName }}, {{ scope.row.originStateCode }}
                {{ scope.row.originZipCode }} {{ scope.row.originContactName }}
                {{ scope.row.originContactPhone }}
              </p>
            </div>
            <div class="order-item" style="margin-top: 20px">
              <span class="order-name">发货信息：</span>
              (<span class="order-value"
                >特殊服务：{{
                  scope.row.destinationSpecificIds == 0 ? "无" : scope.row.destinationSpecificIds
                }}</span
              >)
            </div>
            <div class="order-item">
              <p>
                {{ scope.row.destinationLocationName }} / {{ scope.row.destinationAddressLine1 }},
                {{ scope.row.destinationCityName }}, {{ scope.row.destinationStateCode }}
                {{ scope.row.destinationZipCode }} {{ scope.row.destinationContactName }}
                {{ scope.row.destinationContactPhone }}
              </p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="hb_order_sn" label="订单信息" show-overflow-tooltip width="150">
        <template slot-scope="scope">
          <div class="order-info">
            <div class="order-item">
              <span class="order-name">运费：</span>
              <span class="order-value">${{ scope.row.totalCharge }}</span>
            </div>
            <div class="order-item">
              <span class="order-name">订单金额：</span>
              <span class="order-value">${{ scope.row.totalCharge }}</span>
            </div>
            <div class="order-item">
              <span class="order-name">保险费：</span>
              <span class="order-value">$</span>
            </div>
            <div class="order-item">
              <span class="order-name">补贴金额：</span>
              <span class="order-value">$</span>
            </div>
            <div class="order-item">
              <span class="order-name">总价：</span>
              <span class="order-value">${{ scope.row.totalChargeRate }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="hb_order_sn"
        label="操作"
        show-overflow-tooltip
        width="110"
        v-if="activeName == 1 || activeName == 0"
      >
        <template slot-scope="scope">
          <el-button
            type="danger"
            size="small"
            plain
            @click="handleCancel(scope.row.orderId)"
            v-if="scope.row.orderStatus == 1"
            >取消订单</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagization-box">
      <CustomPagization
        :background="true"
        :currentPage="current"
        :total="total"
        :pageSize="size"
        @current-change="currentChange"
      />
    </div>
    <!-- 详情 -->
    <el-dialog title="订单详情" append-to-body :visible.sync="viewVisible" width="1200px">
      <OrderView ref="formModal" v-if="viewVisible" :id="id" :orderId="orderId" append-to-body />
    </el-dialog>
  </div>
</template>

<script>
import { list, orderCancel } from "@/api/ltl/order";
import { productUnitFind } from "@/common/common.js";
import OrderView from "./orderView";
import { mapState, mapActions } from 'vuex';
import { usersInfo } from "@/api/user";
export default {
  props: {
    activeName: {
      type: [Number, String],
      default: 0,
    },
  },
  components: {
    OrderView,
  },
  data() {
    return {
      viewVisible: false,
      searchForm: {},
      current: 1,
      size: 20,
      total: 1,
      dataList: [],
      userinfo: {},
      id: "",
      userInfo: {}
    };
  },
  computed: {
    status() {
      return (status) => {
        switch (status) {
          case "1":
            return "待审核";
          case "2":
            return "审核拒绝";
          case "3":
            return "待提货";
          case "4":
            return "运输中";
          case "5":
            return "送达";
          case "6":
            return "订单取消";
          case "7":
            return "拒签";
        }
      };
    },
    orderOrderCheck() {
      return (val) => {
        return productUnitFind(val);
      };
    },
  },
  created() {
    this.userInfo = JSON.parse(window.localStorage.getItem("userInfo1"));
    this.init();
    this.userinfo = JSON.parse(window.localStorage.getItem("userInfo1"));
    this.getUsersInfo();
  },
  methods: {
    ...mapActions(['updateBalance','updateMoney']),
    // 获取用户信息
    getUsersInfo() {
      usersInfo({ user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code }).then((res) => {
        if (res.code == 1) {
          this.updateBalance(res.data.userinfo.balance);
          this.updateMoney(res.data.userinfo.money)
        }
      });
    },
    // 取消订单
    handleCancel(id) {
      this.$confirm("确定要取消订单？", "温馨提示", {
        type: "warning",
      })
        .then(() => {
          orderCancel({
            // u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
            orderId: id,
          }).then((res) => {
            if (res.code == 1) {
              this.current = 1;
              this.$message.success(res.msg);
              this.getUsersInfo()
              this.init();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    // 复制订单号
    copy(num) {
      let oInput = document.createElement("input");
      oInput.value = num;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      this.$message.success("复制成功");
      oInput.remove();
    },
    // 创建订单
    handleAdd() {
      this.$router.push({ path: "/ltl/orderPlace" });
    },
    // 详情
    handleView(id, orderId) {
      this.id = id;
      this.orderId = orderId;
      this.viewVisible = true;
    },
    // 获取列表
    init() {
      list({
        u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
        page: this.current,
        limit: this.size,
        orderStatus: this.activeName,
        ...this.searchForm,
      }).then((res) => {
        if (res.code == 1) {
          this.dataList = res.data.data;
          this.total = res.data.total;
          this.$emit("changelist", "数量", res.count);
        }
      });
    },
    // table首列序号
    indexMethod(index) {
      return (this.current - 1) * this.size + index + 1;
    },
    // 页数变化
    currentChange(current) {
      this.current = current;
      this.init();
    },
    // 重置筛选
    resetSearch() {
      this.$refs["searchForm"].resetFields();
      this.searchForm = {};
      this.value1 = [];
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.handleSearch();
    },
    // 去筛选
    handleSearch() {
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.pagization-box {
  overflow: hidden;
  margin-top: 10px;

  .el-pagination {
    float: right;
  }
}
/deep/ {
  .el-table {
    thead {
      color: #fff;
      font-weight: 500;
      background: #f5f7fa;
      color: #606266;
      & th {
        background-color: transparent;
      }
      & tr {
        background-color: transparent;
      }
    }
  }
}
/deep/.el-dialog__body {
  padding: 20px !important;
}
.copy-icon-btn {
  font-size: 16px;
  margin-left: 10px;
  color: #409eff;
  cursor: pointer;
}
// 样式
.order-info {
  .order-item {
    .order-name {
      font-size: 12px;
      color: #000;
    }
    .order-value {
      font-size: 12px;
      color: #409eff;
    }
    p {
      padding: 0;
      margin: 0;
    }
  }
  .order-item-flex {
    display: flex;
    justify-content: space-between;
  }
}
</style>
