<template>
  <div class="">
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="100px"
      class="demo-form"
      size="small"
    >
      <el-form-item label="工单标题" prop="title">
        <el-input v-model="form.title" placeholder="请输入工单标题"></el-input>
      </el-form-item>
      <el-form-item label="订单号" prop="order_sn">
        <el-input v-model="form.order_sn" placeholder="请输入订单号"></el-input>
      </el-form-item>
      <el-form-item label="描述信息" prop="describe">
        <el-input type="textarea" v-model="form.describe" placeholder="请输入描述信息"></el-input>
      </el-form-item>
      <el-form-item label="上传文件" prop="file">
        <el-upload
          :class="fileList.length >= 1 ? 'no-upload-file' : ''"
          action="https://admin.bbtexus.com/api/common/upload"
          list-type="picture-card"
          :on-success="handleSuccess"
          :on-remove="handleRemove"
          :file-list="fileList"
          :limit='1'
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item style="text-align: right;">
        <el-button size="small" type="primary" @click="submitForm('form')">提交</el-button>
        <el-button size="small" @click="resetForm('form')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { workAdd } from '@/api/ltl/work'
export default {
  props:{
    record: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: {},
      rules: {
        title: [{ required: true, message: "请输入工单标题", trigger: "blur" }],
        order_sn: [{ required: true, message: "请输入订单号", trigger: "blur" }],
        describe: [{ required: true, message: '请输入描述信息', trigger: 'blur' }],
      },
      id:'',
      fileList: [],
    };
  },
  methods: {
    handleBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isLt2M;
    },
    handleSuccess(res) {
      let list = [{
        label: res.data.url,
        value: res.data.fullurl,
        url: res.data.fullurl
      }];
      this.fileList = list;
    },
    handleRemove(file) {
      this.fileList = [];
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = this.form
          workAdd({
            u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
            ...form,
            file: this.fileList.length > 0 ? this.fileList[0].label : "",
          }).then(res => {
            if(res.code == 1){
              this.$message.success(res.msg)
              this.$emit('ok')
            }else{
              this.$message.error(res.msg)
            }
          }).catch(err => {
            this.$message.error(err.msg)
          })
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.form = {}
    },
  },
};
</script>
<style lang="scss" scoped>
  ::v-deep .no-upload-file .el-upload--picture-card {
    display: none !important;
  }
  /deep/.el-upload--picture-card{
    width: 100px;
    height: 100px;
    line-height: 98px;
  }
  /deep/.el-upload-list--picture-card .el-upload-list__item{
    width: 100px;
    height: 100px;
  }
</style>
