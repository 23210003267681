import { get, postj } from '@/utils/request';

export const list = (param) => get('order/list',param); // 订单列表

export const create = (data) => postj('order/create',data); // 下单接口
export const detail = (data) => postj('order/detail',data); // 订单详情
export const track = (data) => postj('order/tracking',data); // 订单跟踪
export const saveRate = (data) => postj('order/saveRate',data); // 保存报价
export const rateInfo = (data) => postj('order/rateInfo',data); // 报价详情
export const ltlRate = (data) => postj('order/ltlRate',data); // 零担LTL预测报价
export const zltlRate = (data) => postj('order/rates',data); // 整车TL预测报价
export const rapiddealsClass = (data) => postj('order/rapiddealsClass',data); // 整车TL预测报价
export const orderCancel = (param) => get('order/rapiddealsCancel',param); // 取消订单

export const orderLabel = (param) => get('order/label',param); // 获取面单
export const orderTracking = (data) => postj('order/tracking',data); // 获取面单