<template>
  <div class="">
    <!-- 报价列表 -->
    <el-card class="box-card">
      <div class="search-form" style="text-align: left">
        <el-form
          ref="searchForm"
          :inline="true"
          size="small"
          :model="searchForm"
          label-width="80px"
          label-position="left"
          class="demo-form-inline"
        >
          <el-form-item label="发货地址">
            <el-input
              clearable
              size="small"
              v-model="searchForm.receiver_address_addressName"
              placeholder="请输入发货地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="	收货地址">
            <el-input
              clearable
              size="small"
              v-model="searchForm.send_address_addressName"
              placeholder="请输入收货地址"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch"
              >查询</el-button
            >
            <el-button icon="el-icon-refresh-right" type="primary" size="small" @click="resetSearch"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="" style="margin-bottom: 15px; text-align: left">
        <el-button type="primary" size="small" @click="handleAdd">在线报价</el-button>
      </div>
      <el-table :data="dataList" border size="small" height="600">
        <el-table-column type="index" :index="indexMethod" label="序号" width="60">
        </el-table-column>
        <el-table-column prop="createtime" label="报价号" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="order-info">
              <p>{{ scope.row.rateId }}</p>
              <p>{{ scope.row.selectMode }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="num" label="发货地址" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="order-info">
              <p>{{ scope.row.send_address_addressName }}</p>
              <p>{{ scope.row.send_address_address1 }}</p>
              <p>{{ scope.row.send_address_city }} {{ scope.row.send_address_province }} {{ scope.row.send_address_zipCode }}</p>
              <p>取货日期: -</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="before_num" label="收货地址" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="order-info">
              <p>{{ scope.row.receiver_address_addressName }}</p>
              <p>{{ scope.row.receiver_address_address1 }}</p>
              <p>{{ scope.row.receiver_address_city }} {{ scope.row.receiver_address_province }} {{ scope.row.receiver_address_zipCode }}</p>
              <p>交货日期: -</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="later_num" label="报价日期" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="order-info">
              <p>{{ scope.row.createtime }}</p>
              <p>报价价格: ${{ scope.row.totalChargeRate }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="action" label="操作" width="140">
          <template slot-scope="scope">
            <div>
              <span style="color: #409eff; cursor: pointer" @click="handleEdit(scope.row)">编辑</span>
              <el-divider direction="vertical"></el-divider>
              <span style="color: #f40000; cursor: pointer" @click="handleDel(scope.row)">删除</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagization-box">
        <CustomPagization
          :background="true"
          :currentPage="current"
          :total="total"
          :pageSize="size"
          @current-change="currentChange"
        />
      </div>
    </el-card>
  </div>
</template>

<script>
import { list, rateDel } from "@/api/ltl/price";
export default {
  data() {
    return {
      searchForm: {},
      current: 1,
      size: 10,
      total: 1,
      dataList: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    // 创建订单
    handleAdd(row) {
      this.$router.push({ path: "/ltl/orderPlace" });
    },
    //删除
    handleDel(record) {
      this.$confirm("确认删除?", "温馨提示", {
        type: "warning",
      })
        .then(() => {
          rateDel({ 
            u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
            id: record.id 
          }).then(res => {
            if(res.code == 1){
              this.current = 1
              this.$message.success(res.msg);
              this.init();
            }else{
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    //编辑
    handleEdit(row) {
      if(row.selectMode == 'LTL'){
        this.$router.push({ path: "/ltl/orderPlace", query: { id: row.id, active: '1' } });
      }else{
        this.$router.push({ path: "/ltl/orderPlace", query: { id: row.id, active: '2' } });
      }
    },
    // 获取列表
    init() {
      let queryForm = {};
      if (this.searchForm.createtime) {
        queryForm = {
          ...this.searchForm,
          createtime: this.searchForm.createtime.join("/"),
        };
      } else {
        queryForm = {
          ...this.searchForm,
        };
      }
      list({
        u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
        page: this.current,
        limit: this.size,
        ...queryForm,
      }).then((res) => {
        if (res.code == 1) {
          this.dataList = [];
          this.dataList = res.data.data;
          this.total = res.data.total;
        }
      });
    },
    // table首列序号
    indexMethod(index) {
      return (this.current - 1) * this.size + index + 1;
    },
    // 页数变化
    currentChange(current) {
      this.current = current;
      this.init();
    },
    // 重置筛选
    resetSearch() {
      this.$refs["searchForm"].resetFields();
      this.searchForm = {};
      this.value1 = [];
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.handleSearch();
    },
    // 去筛选
    handleSearch() {
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
.pagization-box {
  overflow: hidden;
  margin-top: 10px;

  .el-pagination {
    float: right;
  }
}
/deep/ {
  .el-table {
    thead {
      color: #fff;
      font-weight: 500;
      background: #f5f7fa;
      color: #606266;
      & th {
        background-color: transparent;
      }
      & tr {
        background-color: transparent;
      }
    }
  }
}
.order-info {
  p {
    padding: 0;
    margin: 0;
  }
}
</style>
