<template>
  <div class="result">
    <el-card>
      <div class="result-title">
        <span>报价的承运商结果:{{ orderId }}</span>
        <el-button type="primary" size="small" @click="handleEdit">编辑</el-button>
      </div>
      <div class="result-address">
        <div class="result-address-item">
          <span class="result-address-label">发货地址</span>
          <div class="result-address-content">
            <span class="result-address-info">{{ info.originLocationName }}</span>
            <span class="result-address-info">{{ info.originAddressLine1 }}</span>
            <span class="result-address-info"
              >{{ info.originCityName }} {{ info.originStateCode }} {{ info.originZipCode }}</span
            >
            <span class="result-address-info">{{ info.selectMode }}</span>
          </div>
        </div>
        <div class="result-address-item">
          <span class="result-address-label">收货地址</span>
          <div class="result-address-content">
            <span class="result-address-info">{{ info.destinationLocationName }}</span>
            <span class="result-address-info">{{ info.destinationAddressLine1 }}</span>
            <span class="result-address-info"
              >{{ info.destinationCityName }} {{ info.destinationStateCode
              }} {{ info.destinationZipCode }}</span
            >
          </div>
        </div>
        <div class="result-address-item">
          <span class="result-address-label">取货日期</span>
          <div class="result-address-content">
            <span class="result-address-info">{{ info.originDate }}</span>
          </div>
        </div>
      </div>
      <el-table :data="commodityList" border size="small">
        <el-table-column prop="handlingUnits" label="Unit" show-overflow-tooltip></el-table-column>
        <el-table-column prop="units" label="Pkg" show-overflow-tooltip></el-table-column>
        <el-table-column prop="lwh" label="Dimensions" show-overflow-tooltip></el-table-column>
        <el-table-column prop="weight" label="Weight(lb)" show-overflow-tooltip></el-table-column>
        <el-table-column prop="mo" label="Class" show-overflow-tooltip></el-table-column>
        <el-table-column
          prop="aTi"
          label="TOTAL VOLUME(ft³)"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="allWeight"
          label="TOTAL WEIGHT(lb)"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="aMi"
          label="TOTAL DENSITY(ft³)"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>
    </el-card>
    <el-card style="margin-top: 10px">
      <el-row :gutter="20">
        <el-col :span="4">
          <div class="operator-title">排序依据</div>
          <el-select
            v-model="searchForm.price"
            placeholder="选择方式"
            filterable
            size="small"
            @change="handleScreen"
            style="margin-top: 20px"
          >
            <el-option label="价格（从低到高）" value="1"></el-option>
            <el-option label="运输时间（从低到高）" value="2"></el-option>
          </el-select>
          <el-select
            v-model="searchForm.day"
            placeholder="选择时间"
            filterable
            size="small"
            style="margin-top: 10px"
            @change="handleScreen"
          >
            <el-option label="1天" value="1"></el-option>
            <el-option label="2天" value="2"></el-option>
            <el-option label="3天" value="3"></el-option>
            <el-option label="4天" value="4"></el-option>
          </el-select>
        </el-col>
        <el-col :span="20">
          <div class="operator">
            <div class="operator-title">{{ ratesList.length }}运营商</div>
            <div class="operator-box">
              <div class="operator-item" v-for="(item, index) in ratesList" :key="index">
                <div class="operator-item-title">{{ item.carrierName }}</div>
                <div class="operator-item-content">
                  <div class="operator-item-content-c">
                    <div class="operator-item-content-c-name">取件时间</div>
                    <div class="operator-item-content-c-value">{{ info.originDate }}</div>
                  </div>
                  <div class="operator-item-content-c">
                    <div class="operator-item-content-c-name">预估运输时间（工作日）</div>
                    <div class="operator-item-content-c-value">
                      {{ item.carrierTransitDays >= 0 ? item.carrierTransitDays : "-" }}天
                    </div>
                  </div>
                  <div class="operator-item-content-c">
                    <div class="operator-item-content-c-name">总价</div>
                    <div class="operator-item-content-c-value">
                      $ {{ item.totalChargeRate }} <span style="color: #409EFF">（含保险费：$ 0.00）</span> 
                    </div>
                  </div>
                  <div class="operator-item-content-c operator-item-content-cd">
                    <el-button
                      type="success"
                      size="small"
                      style="width: 80px"
                      @click="handleReserve(item)"
                      >预定</el-button
                    >
                    <el-button
                      type="primary"
                      size="small"
                      style="width: 80px; margin: 10px 0 0 0"
                      @click="handleSave(item)"
                      >保存报价</el-button
                    >
                  </div>
                </div>
                <el-tabs v-model="tabList[index].activeName">
                  <el-tab-pane label="成本" name="1">
                    <div class="operator-free-item">
                      <span class="operator-free-item-info">描述</span>
                      <span class="operator-free-item-info">金额</span>
                    </div>
                    <div class="operator-free-item">
                      <span class="operator-free-item-title">订单金额</span>
                      <span class="operator-free-item-title">${{ item.totalChargeRate }}</span>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="额外费" name="2">
                    <div
                      class="operator-free-item"
                      v-for="(item1, index1) in item.accesoriesList"
                      :key="index1"
                    >
                      <span class="operator-free-item-title">{{ item1.serviceName }}</span>
                      <span class="operator-free-item-info">$ {{ item1.chargeAmount }}</span>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="赔付标准" name="3">
                    <div class="operator-free-item">
                      <span class="operator-free-item-title">卡车公司赔偿金额</span>
                      <span class="operator-free-item-info">$ 0.00</span>
                    </div>
                    <div class="operator-free-item">
                      <span class="operator-free-item-title">保险公司赔偿金额</span>
                      <span class="operator-free-item-info">$225.82</span>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
// const result = require("./result.json");
import { sortBy, filterData, JudgePalletTypeArrFind } from "@/common/common";
import { saveRate } from "@/api/ltl/order";
export default {
  data() {
    return {
      orderId: "",
      info: {},
      ratesList: [],
      primevalRates: [], //原始经销商列表
      commodityList: [],
      searchForm: {},
      tabList: [],
      selectMode: ''
    };
  },
  created() {
    let res = JSON.parse(window.localStorage.getItem("orderResultInfo"));
    this.ratesList = res.data.rates;
    this.primevalRates = res.data.rates;
    this.orderId = res.data.orderId;
    this.info = res.send_array;
    this.selectMode = res.send_array.selectMode
    this.getTab();
    this.getInfo(res.send_array);
  },
  methods: {
    // 点击编辑数据
    handleEdit(){
      if(this.selectMode == 'LTL'){
        this.$router.push({ path: "/ltl/orderPlace", query: { id: 'A1', active: '1' } });
      }else{
        this.$router.push({ path: "/ltl/orderPlace", query: { id: 'A1', active: '2' } });
      }
    },
    // 格式化数据
    getInfo(send_array) {
      this.commodityList = send_array.commodityList.map((item) => {
        let allWeight = item.handlingUnits * item.weight;
        let lwh = `${item.length} in X ${item.width} in X ${item.height} in`;
        let mo = 0;
        let aTi = 0;
        let aMi = 0;
        if (
          item.length != "" &&
          item.width != "" &&
          item.height != "" &&
          item.handlingUnits != ""
        ) {
          aTi =
            (Number(item.length) *
              Number(item.width) *
              Number(item.height) *
              Number(item.handlingUnits)) /
            1728;
          aMi = Number(item.weight) / aTi;
        }
        let units = JudgePalletTypeArrFind(item.unitTypeId)
          ? JudgePalletTypeArrFind(item.unitTypeId)
          : "";
        return {
          ...item,
          allWeight,
          lwh,
          mo,
          aTi: aTi.toFixed(2),
          aMi: aMi.toFixed(2),
          units,
        };
      });
    },
    // 筛选
    handleScreen() {
      let objNewList = [];
      if (this.primevalRates.length > 0 && this.searchForm.price == 1) {
        if (this.searchForm.day) {
          objNewList = filterData(
            this.primevalRates.sort(sortBy("totalChargeRate")),
            this.searchForm.day
          );
        } else {
          this.primevalRates.sort(sortBy("totalChargeRate"));
          objNewList = this.primevalRates;
        }
      } else if (this.primevalRates.length > 0 && this.searchForm.price == 2) {
        if (this.searchForm.day) {
          objNewList = filterData(
            this.primevalRates.sort(sortBy("carrierTransitDays")),
            this.searchForm.day
          );
        } else {
          this.primevalRates.sort(sortBy("carrierTransitDays"));
          objNewList = this.primevalRates;
        }
      } else {
        objNewList = filterData(this.primevalRates, this.searchForm.day);
      }
      this.ratesList = objNewList;
      this.getTab();
    },
    // 计算tab
    getTab() {
      for (var i = 0; i < this.ratesList.length; i++) {
        this.tabList.push({
          activeName: "1",
        });
      }
    },
    // 预定
    handleReserve(data) {
      window.localStorage.setItem("operation", JSON.stringify(data));
      this.$router.push({ path: "/ltl/orderOrder" });
    },
    // 保存报价
    handleSave(data) {
      let form = {
        u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
        orderId: this.orderId,
        rateId: data.rateId,
        carrierSCAC: data.carrierSCAC,
        carrierName: data.carrierName,
        carrierGuarantee: data.carrierGuarantee,
        totalCharge: data.totalCharge,
        originDate: this.info.originDate,
        send_address_id: this.info.originAddressId,
        receiver_address_id: this.info.destinationAddressId,
        originType: this.info.originType,
        destinationType: this.info.destinationType,
        selectMode: this.info.selectMode,
        platformId: window.localStorage.getItem("platform_id"),
        rate_money: data.rate_money,
        totalChargeRate: data.totalChargeRate,
        commodityList: this.info.commodityList,
        originSpecificIds: this.info.selectMode == "TL" ? 0 : this.info.originSpecificIds,
        destinationSpecificIds: this.info.selectMode == "TL" ? 0 : this.info.destinationSpecificIds,
      };
      saveRate(form).then((res) => {
          if (res.code == 1) {
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.result {
  .result-title {
    font-size: 18px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    font-weight: bold;
  }
  .result-address {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    background: #f5f7fa;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    .result-address-item {
      flex: 1;
      flex-shrink: 0;
      display: flex;
      line-height: 20px;
      .result-address-label {
        font-size: 16px;
        width: 80px;
      }
      .result-address-content {
        font-size: 16px;
        border-left: 1px solid #ccc;
        box-sizing: border-box;
        padding-left: 10px;
        display: flex;
        flex-direction: column;
        .result-address-info {
          font-size: 16px;
        }
      }
    }
  }
  .operator {
    .operator-box {
      width: 100%;
      box-sizing: border-box;
      padding: 0 10px;
      .operator-item {
        width: 100%;
        margin-top: 10px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        padding: 10px;
        .operator-item-title {
          font-size: 16px;
          text-align: left;
          line-height: 28px;
          color: #409EFF;
          font-weight: 600;
        }
        .operator-item-content {
          display: flex;
          justify-content: space-between;
          .operator-item-content-c {
            flex: 2;
            flex-shrink: 0;
            text-align: left;
            .operator-item-content-c-name {
              font-size: 15px;
              font-weight: bold;
              line-height: 20px;
            }
            .operator-item-content-c-value {
              font-size: 15px;
              line-height: 20px;
            }
          }
          .operator-item-content-cd {
            display: flex;
            flex-direction: column;
            flex: 1;
          }
        }
        .operator-free-item {
          display: flex;
          justify-content: space-between;
          .operator-free-item-title {
            font-size: 15px;
            line-height: 25px;
            font-weight: 600;
          }
          .operator-free-item-info {
            font-size: 15px;
            line-height: 25px;
          }
        }
      }
    }
  }
  .operator-title {
    font-size: 16px;
    text-align: left;
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid #ccc;
    font-weight: bold;
  }
}
/deep/ {
  .el-table {
    thead {
      color: #fff;
      font-weight: 500;
      background: #f5f7fa;
      color: #606266;
      & th {
        background-color: transparent;
      }
      & tr {
        background-color: transparent;
      }
    }
  }
}
</style>
