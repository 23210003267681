<template>
  <div class="info">
    <div class="info-title">个人信息</div>
    <div class="info-user">
      <img class="info-user-img"
           :src="ruleForm.avatar ? ruleForm.avatar : url">
      <div class="info-user-box">
        <p>登录用户：{{ ruleForm.username }}</p>
        <!-- <p>余额：{{ ruleForm.username }}</p> -->
      </div>
    </div>
    <div class="info-title">基本信息</div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      size="small"
    >
      <el-form-item label="用户名" prop="username">
        <el-input v-model="ruleForm.username" placeholder="请输入用户名" disabled></el-input>
      </el-form-item>
      <el-form-item label="昵称" prop="nickname">
        <el-input v-model="ruleForm.nickname" placeholder="请输入昵称"></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <div class="form-box">
          <el-input v-model="ruleForm.email" placeholder="请输入邮箱" disabled></el-input>
        </div>
      </el-form-item>
      <el-form-item label="联系方式" prop="mobile">
        <div class="form-box">
          <el-input v-model="ruleForm.mobile" placeholder="请输入联系方式" disabled></el-input>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')" style="width: 150px">确认</el-button>
      </el-form-item>
    </el-form>
    <!-- 邮箱 -->
    <el-dialog
      title="修改邮箱"
      append-to-body
      :visible.sync="emailVisible"
      width="500px"
      :before-close="closeEmaiDialog"
    >
      <Email
        ref="emailModal"
        :record="record"
        v-if="emailVisible"
        @ok="editSuccess"
        append-to-body
      />
    </el-dialog>
    <!-- 手机号 -->
    <el-dialog
      title="修改手机号"
      append-to-body
      :visible.sync="phoneVisible"
      width="500px"
      :before-close="closePhoneDialog"
    >
      <Phone
        ref="formModal"
        :record="record"
        v-if="phoneVisible"
        @ok="editSuccess"
        append-to-body
      />
    </el-dialog>
  </div>
</template>

<script>
import { usersInfo, usersUpdate } from '@/api/user'
import Email from './components/email.vue';
import Phone from './components/phone.vue';
const  dataCity = require('@/common/city.json')
export default {
  components: {
    Email,
    Phone
  },
  data() {
    return {
      emailVisible: false,
      phoneVisible: false,
      value: [],
      dataCity,
      url: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      ruleForm: {},
      rules: {},
    };
  },
  created(){
    this.getUsersInfo()
  },
  methods: {
    // 修改邮箱
    handleEmail(){
      this.emailVisible = true
    },
    // 关闭邮箱弹窗
    closeEmaiDialog(){
      this.emailVisible = false
    },
    // 修改手机号
    handlePhone(){
      this.phoneVisible = true
    },
    // 关闭手机号弹窗
    closePhoneDialog(){
      this.phoneVisible = false
    },
    handleChange(value) {
      this.ruleForm.province = value[0]
      this.ruleForm.city = value[1]
      this.ruleForm.district = value[2]
    },
    // 获取用户信息
    getUsersInfo(){
      usersInfo({user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code}).then(res => {
        if(res.code == 1){
          this.ruleForm = res.data.userinfo
        }
      })
    },
    // 编辑用户信息
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          usersUpdate({
            user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code,
            nickname: this.ruleForm.nickname
          }).then(res => {
            if(res.code == 1){
              this.$message.success(res.msg)
              this.getUsersInfo()
            }else{
              this.$message.error(res.msg)
            }
          })
        } else {
          return false;
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
  .info{
    background: #fff;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    .demo-ruleForm{
      width: 50%;
    }
    .form-box{
      display: flex;
      .form-edit{
        margin-left: 10px;
      }
    }
  }
  /deep/.el-form-item__content{
    text-align: left;
  }
  .info-title{
    width: 100%;
    font-size: 16px;
    line-height: 32px;
    text-align: left;
    font-weight: bold;
  }
  .info-user{
    height: 60px;
    width: 100%;
    border-bottom: 3px solid #ccc;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 10px;
    .info-user-img{
      margin: 0 20px 0 10px;
      padding: 2px;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      box-sizing: border-box;
      border: 1px solid #eee;
      vertical-align: middle;
    }
    .info-user-box{
      flex: 1;
      p{
        font-size: 14px;
        line-height: 28px;
        text-align: left;
        margin: 0;
        padding: 0;
      }
    }
  }
</style>