import { get, post, postj } from '@/utils/request1';

export const orderList = (data) => post('orderw/orderList',data); // 订单列表
export const orderInfo = (data) => post('orderw/order_info',data); // 订单详情
export const cancelOrder = (data) => post('index/cancelorder',data); // 取消订单
export const orderDel = (data) => post('orderw/order_del',data); // 删除订单
export const batchOrder = (data) => post('index/batchOrder',data); // 批量打单
export const batchOrderRate = (data) => post('orderw/batchOrderRate',data); // 批量预估运费
export const orderCreate = (data) => postj('index/createOrder',data); // 创建运单
export const saveOrder = (data) => postj('orderw/saveOrder',data); // 保存草稿
export const fbaList = (data) => post('index/fbaList',data); // FBA地址列表
export const rates = (data) => postj('orderw/rates',data); // 预测费率
export const orderExport = (data) => post('orderw/orderexport',data); // 导出表格
export const importOrder = (data) => post('orderw/importOrder',data); // 导入表格
export const packageInfo = (data) => post('orderw/packageInfo',data); // 包裹信息
export const labelinfo = (data) => post('index/labelinfo',data); // 获取面单
export const facilitatorList = (data) => post('orderw/splist',data); // 服务商列表
export const cityInfo = (data) => post('orderw/getCityInfo',data); // 邮编识别州
export const storeOrder = (data) => postj('orderw/storeOrder',data); // 门店发货
export const storeinfo = (data) => post('orderw/storeinfo',data); // 门店发货详情
export const packageTypeList = (data) => post('orderw/package_type',data); // 包裹类型
export const print_pdf = (data) => post('orderw/print_pdf',data); // 批量打印
export const checkaddress = (data) => post('orderw/batch_checkaddress',data); // 批量校验地址