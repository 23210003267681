<template>
  <div class="">
    <!-- 顶部信息 -->
    <div class="view-top">
      <span class="view-top-title">{{ info.selectMode }} Shipment</span>
      <span class="view-top-num">{{ info.orderId }}</span>
    </div>
    <!-- 顶部状态 -->
    <div class="view-status">
      <el-row :gutter="10">
        <el-col :span="18" class="step">
          <div class="step-item complete">
            <span>待审核</span>
          </div>
          <div class="step-item">
            <span>待提货</span>
          </div>
          <div class="step-item">
            <span>运输中</span>
          </div>
          <div class="step-item">
            <span>已送达</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="view-status-more">
            <!-- <el-dropdown trigger="click" @command="handleLabel" @visible-change='visibleChange'>
              <el-button type="primary">
                获取面单<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in labelList"
                  :key="index"
                  :command="item.url"
                >
                  {{ item.type }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
            <el-dropdown split-button type="primary" @command="handleLabel" @click="visibleChange">
              获取面单
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in labelList"
                  :key="index"
                  :command="item.url"
                >
                  {{ item.type }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 地址信息 -->
    <div class="view-address">
      <el-row :gutter="10">
        <el-col :span="9">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>发货地</span>
            </div>
            <div class="view-card-cont">
              <div class="view-card-cont-item">
                <div class="view-card-cont-item-label label60">
                  <div class="view-card-cont-item-title">地址名称</div>
                  <div class="view-card-cont-item-cont">{{ info.originLocationName }}</div>
                </div>
                <div class="view-card-cont-item-label label40">
                  <div class="view-card-cont-item-title">地址类型</div>
                  <div class="view-card-cont-item-cont">{{ info.originType }}</div>
                </div>
              </div>
              <div class="view-card-cont-item">
                <div class="view-card-cont-item-label label60">
                  <div class="view-card-cont-item-title">地址</div>
                  <div class="view-card-cont-item-cont">
                    {{ info.originAddressLine1 }} {{ info.originCityName }}
                    {{ info.originStateCode }} {{ info.originZipCode }}
                  </div>
                </div>
                <div class="view-card-cont-item-label label40">
                  <div class="view-card-cont-item-title">联系</div>
                  <div class="view-card-cont-item-cont">{{ info.originContactName }}</div>
                  <div class="view-card-cont-item-cont">{{ info.originContactPhone }}</div>
                  <div class="view-card-cont-item-cont">{{ info.originContactEmail }}</div>
                </div>
              </div>
              <div class="view-card-cont-item">
                <div class="view-card-cont-item-label label60">
                  <div class="view-card-cont-item-title">取件时间</div>
                  <div class="view-card-cont-item-cont">{{ info.originDate }}</div>
                </div>
                <div class="view-card-cont-item-label label40">
                  <div class="view-card-cont-item-title">取货码</div>
                  <div class="view-card-cont-item-cont">{{ info.pickupNumber }}</div>
                </div>
              </div>
              <div class="view-card-cont-item">
                <div class="view-card-cont-item-label labe30">
                  <div class="view-card-cont-item-title">特殊服务</div>
                  <div class="view-card-cont-item-cont">
                    {{ info.originSpecificIds == 0 ? "无" : info.originSpecificIds }}
                  </div>
                </div>
                <div class="view-card-cont-item-label labe30">
                  <div class="view-card-cont-item-title">REF #</div>
                  <div class="view-card-cont-item-cont">{{ info.referenceNumber }}</div>
                </div>
                <div class="view-card-cont-item-label label40">
                  <div class="view-card-cont-item-title">客户订单号</div>
                  <div class="view-card-cont-item-cont">{{ info.bolplNumber }}</div>
                </div>
              </div>
              <div class="view-card-cont-item">
                <div class="view-card-cont-item-label label00">
                  <div class="view-card-cont-item-title">备注</div>
                  <div class="view-card-cont-item-cont">{{ info.originMemo }}</div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="9">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>收货地</span>
            </div>
            <div class="view-card-cont">
              <div class="view-card-cont-item">
                <div class="view-card-cont-item-label label60">
                  <div class="view-card-cont-item-title">地址名称</div>
                  <div class="view-card-cont-item-cont">{{ info.destinationLocationName }}</div>
                </div>
                <div class="view-card-cont-item-label label40">
                  <div class="view-card-cont-item-title">地址类型</div>
                  <div class="view-card-cont-item-cont">{{ info.destinationType }}</div>
                </div>
              </div>
              <div class="view-card-cont-item">
                <div class="view-card-cont-item-label label60">
                  <div class="view-card-cont-item-title">地址</div>
                  <div class="view-card-cont-item-cont">
                    {{ info.destinationAddressLine1 }} {{ info.destinationCityName
                    }} {{ info.destinationStateCode }} {{ info.destinationZipCode }}
                  </div>
                </div>
                <div class="view-card-cont-item-label label40">
                  <div class="view-card-cont-item-title">联系</div>
                  <div class="view-card-cont-item-cont">{{ info.destinationContactName }}</div>
                  <div class="view-card-cont-item-cont">{{ info.destinationContactPhone }}</div>
                  <div class="view-card-cont-item-cont">{{ info.destinationContactEmail }}</div>
                </div>
              </div>
              <div class="view-card-cont-item">
                <div class="view-card-cont-item-label label60">
                  <div class="view-card-cont-item-title">预估运输时间（工作日）</div>
                  <div class="view-card-cont-item-cont"></div>
                </div>
                <div class="view-card-cont-item-label label40">
                  <div class="view-card-cont-item-title">交货码</div>
                  <div class="view-card-cont-item-cont">{{ info.deliveryNumber }}</div>
                </div>
              </div>
              <div class="view-card-cont-item">
                <div class="view-card-cont-item-label labe60">
                  <div class="view-card-cont-item-title">特殊服务</div>
                  <div class="view-card-cont-item-cont">
                    {{ info.destinationSpecificIds == 0 ? "无" : info.destinationSpecificIds }}
                  </div>
                </div>
                <div class="view-card-cont-item-label label40">
                  <div class="view-card-cont-item-title">预计送达时间</div>
                  <div class="view-card-cont-item-cont">{{ info.estimated_delivery_date }}</div>
                </div>
              </div>
              <div class="view-card-cont-item">
                <div class="view-card-cont-item-label label00">
                  <div class="view-card-cont-item-title">备注</div>
                  <div class="view-card-cont-item-cont">{{ info.destinationMemo }}</div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>轨迹</span>
            </div>
            <div class="view-card-cont">
              <el-timeline v-if='trackList.length > 0'>
                <el-timeline-item
                  v-for="(item, index) in trackList"
                  :key="index"
                  :color="item.color"
                  :timestamp="item.timestamp"
                >
                  {{ item.event }}{{ item.location }}
                </el-timeline-item>
              </el-timeline>
              <el-empty description="暂无数据~" v-else></el-empty>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div class="view-address">
      <el-row :gutter="10">
        <el-col :span="18">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>物品</span>
            </div>
            <div class="view-card-cont">
              <el-table :data="info.info" ref="multipleTable" border size="small">
                <el-table-column
                  prop="commodity"
                  label="Commodity name"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  prop="handlingUnits"
                  label="Pieces"
                  width="60"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    {{ scope.row.handlingUnits }}{{ bat(scope.row.packageTypeId) }}
                  </template>
                </el-table-column>
                <el-table-column prop="quantity" label="Pkg" show-overflow-tooltip width="140">
                  <template slot-scope="scope">
                    {{ scope.row.quantity }}{{ unit(scope.row.unitTypeId) }}
                  </template>
                </el-table-column>
                <el-table-column prop="length" label="Per Unit" show-overflow-tooltip>
                  <template slot-scope="scope">
                    {{ scope.row.length }} in X {{ scope.row.width }} in X {{ scope.row.height }} in
                  </template>
                </el-table-column>
                <el-table-column
                  prop="weight"
                  label="Total Weight"
                  show-overflow-tooltip
                  width="100"
                >
                  <template slot-scope="scope"> {{ scope.row.weight }} lb </template>
                </el-table-column>
                <el-table-column
                  prop="quantity"
                  label="Class"
                  width="60"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  prop="quantity"
                  label="Declared"
                  width="80"
                  show-overflow-tooltip
                ></el-table-column>
              </el-table>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>运费</span>
            </div>
            <div class="view-card-cont">
              <div class="view-cont-item">
                <div class="view-cont-name">运费</div>
                <div class="view-cont-num">${{ info.totalCharge }}</div>
              </div>
              <div class="view-cont-item">
                <div class="view-cont-name">订单金额</div>
                <div class="view-cont-num">${{ info.totalCharge }}</div>
              </div>
              <div class="view-cont-item">
                <div class="view-cont-name">保险费</div>
                <div class="view-cont-num">$</div>
              </div>
              <div class="view-cont-item">
                <div class="view-cont-name">补贴金额</div>
                <div class="view-cont-num">$</div>
              </div>

              <div class="view-cont-item">
                <div class="view-cont-name">总价</div>
                <div class="view-cont-num">${{ info.totalChargeRate }}</div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div class="view-address">
      <el-row :gutter="10">
        <el-col :span="18">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>运营商信息</span>
            </div>
            <div class="view-card-cont">
              <div class="view-card-cont-item">
                <div class="view-card-cont-item-label labe30">
                  <div class="view-card-cont-item-title">定制货运系统公司</div>
                  <div class="view-card-cont-item-cont">{{ info.carrierName }}</div>
                  <div class="view-card-cont-item-cont">{{ info.pro }}</div>
                </div>
                <div class="view-card-cont-item-label labe30">
                  <div class="view-card-cont-item-title">始发地</div>
                  <div class="view-card-cont-item-cont">{{ info.originLocationName }}</div>
                  <div class="view-card-cont-item-cont">{{ info.originAddressLine1 }}</div>
                  <div class="view-card-cont-item-cont">
                    {{ info.originCityName }} {{ info.originStateCode }} {{ info.originZipCode }}
                  </div>
                  <div class="view-card-cont-item-cont">{{ info.originContactPhone }}</div>
                </div>
                <div class="view-card-cont-item-label label40">
                  <div class="view-card-cont-item-title">目的地</div>
                  <div class="view-card-cont-item-cont">{{ info.destinationLocationName }}</div>
                  <div class="view-card-cont-item-cont">{{ info.destinationAddressLine1 }}</div>
                  <div class="view-card-cont-item-cont">
                    {{ info.destinationCityName }} {{ info.destinationStateCode
                    }} {{ info.destinationZipCode }}
                  </div>
                  <div class="view-card-cont-item-cont">{{ info.destinationContactPhone }}</div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
    <div class="view-address">
      <el-row :gutter="10">
        <el-col :span="18">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>运营商信息</span>
            </div>
            <div class="view-card-cont">
              <el-table :data="dataList" ref="multipleTable" border size="small">
                <el-table-column
                  prop="order_sn"
                  label="扣款原因"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  prop="order_sn"
                  label="扣款金额"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  prop="order_sn"
                  label="证明文件"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  prop="order_sn"
                  label="操作人信息"
                  show-overflow-tooltip
                ></el-table-column>
              </el-table>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6"></el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { detail, track, orderLabel, orderTracking } from "@/api/ltl/order";
import { JudgePalletTypeArrFind, productUnitFind } from "@/common/common";
export default {
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
    orderId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      info: {},
      trackList: [],
      dataList: [],
      activities: [
        {
          content: "支持使用图标",
          timestamp: "2018-04-12 20:46",
          color: "#0bbd87",
        },
        {
          content: "支持自定义颜色",
          timestamp: "2018-04-03 20:46",
        },
        {
          content: "支持自定义尺寸",
          timestamp: "2018-04-03 20:46",
        },
        {
          content: "默认样式的节点",
          timestamp: "2018-04-03 20:46",
        },
      ],
      labelList: [],
    };
  },
  computed: {
    bat() {
      return (val) => {
        let name = JudgePalletTypeArrFind(val);
        return name;
      };
    },
    unit() {
      return (val) => {
        let name = productUnitFind(val);
        return name;
      };
    },
  },
  mounted() {
    this.getDetail();
    this.getTrack()
  },
  methods: {
    visibleChange(val){
      if(val){
        this.getOrderLabel()
      }
    },
    handleLabel(file) {
      window.open(file, "_blank");
    },
    // 获取轨迹
    getTrack() {
      track({
        orderId: this.orderId,
        u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
      }).then((res) => {
        if(res.code == 1){
          this.trackList = res.data.data.trackingInfo;
        }
      });
    },
    // 获取详情
    getDetail() {
      detail({
        id: this.id,
        u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
      }).then((res) => {
        if (res.code == 1) {
          this.info = res.data;
        }
      });
    },
    // 获取面单
    getOrderLabel() {
      orderLabel({
        orderId: this.orderId,
        // orderId: 'FB240715572082',
        u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
      }).then((res) => {
        if (res.code == 1) {
          if(res.data.length > 0){
            this.$message.success("获取成功");
            this.labelList = res.data;
          }else{
            this.$message.error("暂无面单");
          }
        }else{
          this.$message.error(res.msg);
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.view-top {
  height: 40px;
  .view-top-title {
    font-size: 28px;
    font-weight: 400;
    font-family: Poppins-Regular;
    color: #303133;
  }
  .view-top-num {
    font-size: 28px;
    font-weight: 400;
    color: rgb(64, 158, 255);
    font-family: Poppins-Regular;
    margin-left: 20px;
  }
}
.view-status {
  .step {
    // width: 98.7%;
    height: 40px;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-align: center;
    line-height: 40px;
    margin: 10px 0;
    .step-item {
      width: 24%;
      background: #f7f7f7;
      border: 1px solid #bbb;
      color: #999;
      font-size: 14px;
      position: relative;
      &::before {
        content: "";
        width: 0;
        width: 28px;
        height: 28px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        overflow: hidden;
        border-width: 1px 1px 0 0;
        border-color: #bbb;
        border-style: solid;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 5px;
        background: #fff;
        left: -14px;
      }
      &::after {
        background: #f7f7f7;
        right: -15px;
        z-index: 9;
        content: "";
        width: 0;
        width: 28px;
        height: 28px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        overflow: hidden;
        border-width: 1px 1px 0 0;
        border-color: #bbb;
        border-style: solid;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 5px;
      }
    }
    .complete {
      background: #ebffec !important;
      border-color: #3daf2c !important;
      color: #3daf2c !important;
      &::after {
        background: #ebffec !important;
        border-color: #3daf2c !important;
        color: #3daf2c !important;
      }
      &::before {
        border-color: #3daf2c;
      }
    }
  }
  .view-status-more {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}
.view-address {
  margin-top: 10px;
  .view-card-cont {
    .view-card-cont-item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ebeef5;
      box-sizing: border-box;
      padding: 5px;
      .view-card-cont-item-label {
        width: 50%;
        .view-card-cont-item-title {
          font-size: 14px;
          line-height: 24px;
          font-weight: 600;
        }
        .view-card-cont-item-cont {
          font-size: 14px;
          line-height: 24px;
        }
      }
      .label00 {
        width: 100%;
      }
      .label60 {
        width: 60%;
      }
      .label40 {
        width: 40%;
      }
      .labe30 {
        width: 30%;
      }
      &:last-child {
        border: none;
      }
    }
    .view-cont-item {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 5px;
      .view-cont-name {
        font-size: 14px;
        font-weight: 600;
      }
      .view-cont-num {
        font-size: 14px;
      }
    }
  }
}
/deep/.el-card__header {
  padding: 10px;
  font-weight: 600;
  background: #f5f7fa;
}
/deep/.el-card__body {
  padding: 5px;
}
/deep/.el-empty__image svg{
  width: 80px;
  height: 80px;
}
</style>
