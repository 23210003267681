<template>
  <div class="">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      size="small"
    >
      <!-- <el-form-item label="服务商" prop="service_type">
        <el-select v-model="ruleForm.service_type" placeholder="请选择服务商" style="width: 100%">
          <el-option
            :label="item"
            :value="item"
            v-for="(item, index) in dataList"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="文件" prop="file">
        <el-upload
          class="upload-demo"
          :limit="1"
          :data="{
            user_code: user_code,
          }"
          action="https://uudex.com/wapi/orderw/importOrder"
          :on-success="uploadStripping"
          :on-error="UploadError"
        >
          <el-button type="success" size="small" plain style="margin-right: 5px"
            >选择文件<i class="el-icon-upload el-icon--right"></i>
          </el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="模板下载" style="padding-bottom: 10px">
        <span class="down" @click="downLoad(1)">FedEx导入模板</span>
        <span class="down" @click="downLoad(2)">UPS导入模板</span>
      </el-form-item>
    </el-form>
    <!-- 失败弹框 -->
    <el-dialog
      title="导入提示"
      append-to-body
      :visible.sync="sheetVisible"
      width="500px"
      :before-close="closeSheetDialog"
    >
      <Sheet v-if="sheetVisible" append-to-body :sheetList="sheetList" />
    </el-dialog>
  </div>
</template>

<script>
import { facilitatorList, importOrder } from "@/api/order";
import { createDownloadLink } from "@/common/common.js";
import Sheet from "./sheet.vue";
export default {
  components: {
    Sheet,
  },
  data() {
    return {
      sheetVisible: false,
      sheetList: [],
      user_code: "",
      dataList: [],
      ruleForm: {
        service_type: "",
      },
      rules: {},
    };
  },
  mounted() {
    (this.user_code = JSON.parse(window.localStorage.getItem("userInfo1")).user_code)
      // this.getFacilitatorList();
  },
  methods: {
    downLoad(num) {
      if (num == 1) {
        createDownloadLink(
          "https://uudex.com/static/exceltpl/fedex.xlsx?v=1",
          "FedEx导入模板.xlsx"
        );
      } else {
        createDownloadLink("https://uudex.com/static/exceltpl/ups1.xls?v=1", "UPS导入模板.xlsx");
      }
    },
    uploadStripping(res, file, fileList) {
      if (res.code == 1) {
        this.$message({
          type: "success",
          message: res.msg,
        });
        this.$emit("uploadSuccess");
      } else {
        this.sheetVisible = true;
        this.sheetList = res.data.error_msg;
      }
    },
    closeSheetDialog() {
      this.sheetVisible = false;
      this.sheetList = [];
    },
    UploadError(err, file, fileList) {
      console.log(err,"sdf")
      let myError = err.toString();
      myError = myError.replace("Error: ", "");
      myError = JSON.parse(myError);
      console.log(myError,"SDf")
      this.$message({
        type: "error",
        message: myError.msg,
      });
    },
    // 获取列表
    getFacilitatorList() {
      facilitatorList({
        user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code,
      }).then((res) => {
        if (res.code == 1) {
          let arr = res.data.map((item) => {
            return item.service_type;
          });
          this.dataList = arr.filter((item, index, self) => self.indexOf(item) === index);
          this.ruleForm.service_type = this.dataList.length > 0 ? this.dataList[0] : "";
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          importOrder({
            user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code,
            ...this.ruleForm,
          }).then((res) => {
            if (res.code == 1) {
              this.$message.success(res.msg);
              this.$emit("ok");
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.down {
  font-size: 14px;
  margin-right: 10px;
  color: #409eff;
  text-decoration: none;
  cursor: pointer;
}
/deep/.el-dialog__body{
  padding: 0 20px !important;
}
</style>
