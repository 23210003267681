<template>
  <div class="place">
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="90px"
      class="demo-form"
      size="small"
    >
      <el-row :gutter="30">
        <el-col :span="20">
          <el-row :gutter="30">
            <el-col :span="12">
              <!-- 发件人 -->
              <el-row :gutter="10" class="border-col">
                <el-col :span="24">
                  <div class="place-title">发货信息</div>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="8">
                  <el-form-item label="地址名称" class="special" prop="originLocationName">
                    <el-input
                      v-model="form.originLocationName"
                      placeholder="请输入地址名称"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="地址类型" class="special" prop="originType">
                    <el-select
                      v-model="form.originType"
                      placeholder="请选择地址类型"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="(item, index) in addressType"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="特殊需求" class="special" prop="originSpecificIds">
                    <el-select
                      v-model="originSpecificList"
                      placeholder="请选择特殊需求"
                      multiple
                      style="width: 100%"
                    >
                      <el-option
                        v-for="(item, index) in originSpeci"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="地址1" class="special" prop="originAddressLine1">
                    <el-input
                      v-model="form.originAddressLine1"
                      placeholder="请输入地址1"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="地址2" class="special">
                    <el-input placeholder="请输入地址2"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="邮编" class="special" prop="originZipCode">
                    <el-input
                      v-model="form.originZipCode"
                      placeholder="请输入邮编"
                      readonly
                      class="dis"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="城市" class="special" prop="originCityName">
                    <el-input v-model="form.originCityName" placeholder="请输入城市"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="州" class="special" prop="originStateCode">
                    <el-input
                      v-model="form.originStateCode"
                      placeholder="请输入州"
                      readonly
                      class="dis"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="国家" class="special">
                    <el-input value="US" placeholder="请输入国家" readonly class="dis"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="取货码" class="special" prop="pickupNumber">
                    <el-input v-model="form.pickupNumber" placeholder="请输入取货码"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="16">
                  <el-form-item
                    label="取货时间段"
                    label-width="120px"
                    class="special"
                    prop="originTimeFrom"
                  >
                    <div class="start-time" style="margin-top: 1px">
                      <el-time-select
                        v-model="form.originTimeFrom"
                        style="width: 47%"
                        :max-time="form.originTimeTo"
                        placeholder="开始时间"
                        start="08:00"
                        step="00:30"
                        end="17:00"
                      />-
                      <el-time-select
                        v-model="form.originTimeTo"
                        style="width: 47%"
                        :min-time="form.originTimeFrom"
                        placeholder="结束时间"
                        start="08:00"
                        step="00:30"
                        end="17:00"
                      />
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="联系人" class="special" prop="originContactName">
                    <el-input
                      v-model="form.originContactName"
                      placeholder="请输入联系人"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="16">
                  <el-form-item label="联系电话" prop="originContactPhone" class="special" style="margin-top: 1px">
                    <el-input
                      v-model="form.originContactPhone"
                      placeholder="请输入联系电话"
                      class="input-with-select"
                    >
                      <el-select v-model="form.phoneCode" slot="prepend" placeholder="请选择">
                        <el-option label="United States" value="US"></el-option>
                      </el-select>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="联系邮箱" class="special" prop="originContactEmail">
                    <el-input
                      v-model="form.originContactEmail"
                      placeholder="请输入联系邮箱"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="订单号" class="special" prop="orderId">
                    <el-input v-model="form.orderId" placeholder="请输入订单号"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="参考编号" class="special" prop="referenceNumber">
                    <el-input
                      v-model="form.referenceNumber"
                      placeholder="请输入参考编号"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="备注" class="special" prop="originMemo">
                    <el-input type='textarea' v-model="form.originMemo" placeholder="请输入备注"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="12">
              <!-- 收件人 -->
              <el-row :gutter="10" class="border-col">
                <el-col :span="24">
                  <div class="place-title">收货信息</div>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="8">
                  <el-form-item label="地址名称" class="special" prop="destinationLocationName">
                    <el-input
                      v-model="form.destinationLocationName"
                      placeholder="请输入地址名称"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="地址类型" class="special" prop="destinationType">
                    <el-select
                      v-model="form.destinationType"
                      placeholder="请选择地址类型"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="(item, index) in addressType"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="特殊需求" class="special" prop="destinationSpecificIds">
                    <el-select
                      v-model="destinationSpecificIdsList"
                      placeholder="请选择特殊需求"
                      multiple
                      style="width: 100%"
                    >
                      <el-option
                        v-for="(item, index) in destSpeci"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="地址1" class="special" prop="destinationAddressLine1">
                    <el-input
                      v-model="form.destinationAddressLine1"
                      placeholder="请输入地址1"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="地址2" class="special">
                    <el-input placeholder="请输入地址2"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="邮编" class="special" prop="destinationZipCode">
                    <el-input
                      v-model="form.destinationZipCode"
                      placeholder="请输入邮编"
                      readonly
                      class="dis"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="城市" class="special" prop="destinationCityName">
                    <el-input
                      v-model="form.destinationCityName"
                      placeholder="请输入城市"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="州" class="special" prop="destinationStateCode">
                    <el-input
                      v-model="form.destinationStateCode"
                      placeholder="请输入州"
                      readonly
                      class="dis"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="国家" class="special">
                    <el-input value="US" placeholder="请输入国家" readonly class="dis"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="交货码" class="special" prop="deliveryNumber">
                    <el-input v-model="form.deliveryNumber" placeholder="请输入交货码"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="16">
                  <el-form-item
                    label="交货时间段"
                    label-width="120px"
                    class="special"
                    prop="destinationTimeFrom"
                  >
                    <div class="start-time" style="margin-top: 1px">
                      <el-time-select
                        v-model="form.destinationTimeFrom"
                        style="width: 47%"
                        :max-time="form.destinationTimeTo"
                        placeholder="开始时间"
                        start="08:00"
                        step="00:30"
                        end="17:00"
                      />-
                      <el-time-select
                        v-model="form.destinationTimeTo"
                        style="width: 47%"
                        :min-time="form.destinationTimeFrom"
                        placeholder="结束时间"
                        start="08:00"
                        step="00:30"
                        end="17:00"
                      />
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="联系人" class="special" prop="destinationContactName">
                    <el-input
                      v-model="form.destinationContactName"
                      placeholder="请输入联系人"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="16">
                  <el-form-item label="联系电话" prop="destinationContactPhone" class="special" style="margin-top: 1px">
                    <el-input
                      v-model="form.destinationContactPhone"
                      placeholder="请输入联系电话"
                      class="input-with-select"
                    >
                      <el-select v-model="form.phoneCode" slot="prepend" placeholder="请选择">
                        <el-option label="United States" value="US"></el-option>
                      </el-select>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="联系邮箱" class="special" prop="destinationContactEmail">
                    <el-input
                      v-model="form.destinationContactEmail"
                      placeholder="请输入联系邮箱"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="备注" class="special" prop="destinationMemo">
                    <el-input type="textarea" v-model="form.destinationMemo" placeholder="请输入备注"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <!-- 包裹 -->
          <el-row :gutter="10" class="border-col">
            <el-col :span="12">
              <div class="place-title" style="display: flex">装运信息
                <el-radio-group v-model="unitType" size="mini" style="margin-left: 10px">
                  <el-radio-button label="in/lb"></el-radio-button>
                  <el-radio-button label="cm/kg"></el-radio-button>
                </el-radio-group>
              </div>
            </el-col>
            <el-col :span="12" style="text-align: right">
              <el-button type="success" @click="selectProduct" size="small" class="add-select-btn"
                >选择产品</el-button
              >
            </el-col>
          </el-row>
          <div class="parcel-item" v-for="(item, index) in form.commodityList" :key="index">
            <div class="parcel-top parcel-top-pad">
              <span class="parcel-title">{{ index + 1 }}-包裹信息</span>
              <i
                style="font-size: 20px; margin: 0 10px; color: #409dfe; cursor: pointer"
                class="el-icon-circle-plus-outline"
                @click="addParcel"
              ></i>
              <i
                style="font-size: 20px; color: #f56c6c; cursor: pointer; margin-right: 10px"
                class="el-icon-remove-outline"
                v-if="form.commodityList.length > 1"
                @click="delParcel(index)"
              ></i>
            </div>
            <el-row :gutter="10" class="parcel-mid-pad">
              <el-col :span="4">
                <el-form-item
                  label="商品名称"
                  :prop="`commodityList[${index}][commodity]`"
                  :rules="rules.commodity"
                  class="special"
                >
                  <el-input v-model="item.commodity" @blur="blurType"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item
                  label="商品数量"
                  :prop="`commodityList[${index}][quantity]`"
                  :rules="rules.quantity"
                  class="special"
                >
                  <el-input v-model="item.quantity" @blur="blurType"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item
                  label="商品单位"
                  class="special"
                  :prop="`commodityList[${index}][packageTypeId]`"
                  :rules="rules.packageTypeId"
                >
                  <el-select v-model="item.packageTypeId" style="width: 100%">
                    <el-option
                      :label="item.label"
                      :value="item.value"
                      v-for="(item, index) in productUnit"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item
                  label="打托数量"
                  :prop="`commodityList[${index}][handlingUnits]`"
                  :rules="rules.handlingUnits"
                  class="special"
                >
                  <el-input v-model.number="item.handlingUnits" @blur="calculation1(index)"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item
                  label="打托类型"
                  class="special"
                  :prop="`commodityList[${index}][unitTypeId]`"
                  :rules="rules.unitTypeId"
                >
                  <el-select v-model="item.unitTypeId" style="width: 100%" @change="changeType">
                    <el-option
                      :label="item.name"
                      :value="item.id.toString()"
                      v-for="(item, index) in JudgePalletTypeArr"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="货值" class="special" :prop="`commodityList[${index}][declaredValue]`" :rules="rules.declaredValue">
                  <el-input v-model="item.declaredValue" @blur="blurType"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item
                  :label="`长(${unitType == 'in/lb' ? 'in' : 'cm'})`"
                  :prop="`commodityList[${index}][length]`"
                  :rules="rules.length"
                  class="special"
                >
                  <el-input v-model.number="item.length" @blur="calculation1(index)"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item
                  :label="`宽(${unitType == 'in/lb' ? 'in' : 'cm'})`"
                  :prop="`commodityList[${index}][width]`"
                  :rules="rules.width"
                  class="special"
                >
                  <el-input v-model.number="item.width" @blur="calculation1(index)"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item
                  :label="`高(${unitType == 'in/lb' ? 'in' : 'cm'})`"
                  :prop="`commodityList[${index}][height]`"
                  :rules="rules.height"
                  class="special"
                >
                  <el-input v-model.number="item.height" @blur="calculation1(index)"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="4">
                <el-form-item
                  :label="`总重量(${unitType == 'in/lb' ? 'lb' : 'kg'})`"
                  :prop="`commodityList[${index}][weight]`"
                  :rules="rules.weight"
                  class="special"
                >
                  <el-input v-model.number="item.weight" @blur="calculation1(index)"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="等级" class="special dis">
                  <el-input v-model="item.dengji" readonly></el-input>
                  <!-- <el-select style="width: 100%" v-model="value4">
                    <el-option
                      :label="item"
                      :value="item"
                      v-for="(item, index) in freightLevel"
                      :key="index"
                    ></el-option>
                  </el-select> -->
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="NMFC" class="special">
                  <el-input v-model="item.nmfcNumber" @blur="blurType"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10" class="parcel-mid-pad" v-if="item.isHazmat == 1">
              <el-col :span="4">
                <el-form-item
                  label="危险品UN编号"
                  :prop="`commodityList[${index}][unNumber]`"
                  :rules="rules.unNumber"
                  label-width="200px"
                  class="special"
                >
                  <el-input v-model="item.unNumber" @blur="blurType"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item
                  label="危险产品类别别号"
                  :prop="`commodityList[${index}][bclass]`"
                  :rules="rules.bclass"
                  label-width="200px"
                  class="special"
                >
                  <el-select v-model="item.bclass" style="width: 100%" @change="changeType">
                    <el-option
                      :label="index+1"
                      :value="index+1"
                      v-for="(item, index) in 9"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item
                  label="危险品数量"
                  label-width="200px"
                  class="special dis"
                >
                  <el-input v-model="item.handlingUnits" readonly></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item
                  label="是否有毒"
                  :prop="`commodityList[${index}][poison]`"
                  :rules="rules.poison"
                  class="special"
                >
                  <el-select v-model="item.poison" style="width: 100%" @change="changeType">
                    <el-option label="无毒" :value="0"></el-option>
                    <el-option label="有毒" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item
                  label="紧急联系人"
                  :prop="`commodityList[${index}][hazmatContactName]`"
                  :rules="rules.hazmatContactName"
                  label-width="200px"
                  class="special"
                >
                  <el-input v-model="item.hazmatContactName" @blur="blurType"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item
                  label="手机号"
                  :prop="`commodityList[${index}][hazmatPhone]`"
                  :rules="rules.hazmatPhone"
                  class="special"
                >
                  <el-input v-model="item.hazmatPhone" @blur="blurType"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10" class="parcel-bot-pad">
              <div class="page-calc">
                <div class="page-calc-item">
                  <el-form-item>
                    <el-checkbox
                      v-model="item.isHazmat"
                      :checked="item.isHazmat == 1 ? true : false"
                      true-label="1"
                      false-label="0"
                      name="isHazmat"
                      >是否危险品</el-checkbox
                    >
                  </el-form-item>
                </div>
                <div class="page-calc-item">总体积：{{ calcList[index] ? calcList[index].allTi : 0.00 }} ft³</div>
                <div class="page-calc-item">总重量：{{ calcList[index] ? calcList[index].weight : 0 }} lb</div>
                <div class="page-calc-item">总密度：{{ calcList[index] ? calcList[index].ami : 0 }} lb/ft³</div>
              </div>
            </el-row>
          </div>
        </el-col>
        <el-col :span="4">
          <el-row :gutter="10" class="border-col">
            <el-col :span="24">
              <div class="place-title">摘要</div>
            </el-col>
          </el-row>
          <div class="digest">
            <div class="digest-item">
              <div class="digest-item-name">承运平台：</div>
              <div class="digest-item-value">{{ operation.carrierName }}</div>
            </div>
            <div class="digest-item">
              <div class="digest-item-name">发货地：</div>
              <div class="digest-item-value">{{form.originLocationName}},{{form.originAddressLine1}},{{form.originCityName}},{{form.originStateCode}} {{form.originZipCode}}</div>
            </div>
            <div class="digest-item">
              <div class="digest-item-name">收货地：</div>
              <div class="digest-item-value">{{form.destinationLocationName}},{{form.destinationAddressLine1}},{{form.destinationCityName}},{{form.destinationStateCode}} {{form.destinationZipCode}}</div>
            </div>
            <div class="digest-item">
              <div class="digest-item-name">取货日期：</div>
              <div class="digest-item-value">{{ form.originDate }}</div>
            </div>
            <div class="digest-item">
              <div class="digest-item-name">预估运输时间(工作日)：</div>
              <div class="digest-item-value">{{ operation.carrierTransitDays }}天</div>
            </div>
            <div class="digest-item">
              <div class="digest-item-name">总价：</div>
              <div class="digest-item-value">${{ operation.totalChargeRate }}</div>
            </div>
            <div class="digest-item">
              <div class="digest-item-name">支付方式：</div>
              <div class="digest-item-value">账户余额：${{ user_balance }}</div>
            </div>
            <el-button type="primary" class="digest-btn" size="small" @click="submitForm('form')" :loading="orderLoading" v-if="isPayOrder">预订发货</el-button>
            <el-button type="success" class="digest-btn" size="small" @click="submitEstForm('form')" :loading="orderLoading" v-else>重新估价装运</el-button>
          </div>
        </el-col>
      </el-row>
      <!-- 按钮 -->
      <!-- <el-row :gutter="10">
        <el-col :span="24" class="bttn">
          <el-button type="primary" size="small" @click="submitForm('form')" :loading="orderLoading" v-if="isPayOrder">生成物流运单</el-button>
          <el-button type="primary" size="small" @click="submitEstForm('form')" :loading="orderLoading" v-else>重新估价</el-button>
        </el-col>
      </el-row> -->
    </el-form>
    <!-- 选择产品 -->
    <el-dialog
      title="选择产品"
      append-to-body
      :visible.sync="productVisible"
      width="1000px"
      :before-close="closeProductDialog"
    >
      <ProductList
        ref="formModal"
        :isType="true"
        v-if="productVisible"
        @ok="editSuccess"
        append-to-body
      />
    </el-dialog>
  </div>
</template>

<script>
import { create, zltlRate, ltlRate, rapiddealsClass } from "@/api/ltl/order";
import {
  addressType,
  freightLevel,
  productUnit,
  JudgePalletTypeArr,
  validatenull,
  originSpeci,
  destSpeci,
  originSpeciFindValues, 
  destSpeciFindValues 
} from "@/common/common.js";
import ProductList from "../product/list";
export default {
  components: {
    ProductList,
  },
  props: {
    record: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // 没有传参的占位数据
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      value6: "",
      unitType: 'in/lb',
      productVisible: false, // 产品弹框
      orderLoading: false, // 获取报价
      falist: [], // 发货地址
      shoulist: [], //收货地址
      addressType: addressType(), //地址类型
      freightLevel: freightLevel(), // 运费等级
      productUnit: productUnit(), //商品单位
      JudgePalletTypeArr: JudgePalletTypeArr(), // 打托类型
      originSpeci: originSpeci(), // 发货特殊需求
      destSpeci: destSpeci(), // 收货特殊需求
      calcList: [], // 计算的值
      form: {
        orderId: "", //订单id
        rateId: "", //报价id
        carrierSCAC: "", //承运商code
        carrierGuarantee: "", //承运商服务
        carrierName: "", //承运商名称
        accessorialCharge: "", //额外附加费
        totalCharge: "", //报价价格
        fuelCharge: "", //燃油费
        lineCharge: "", //基础费用
        rate_money: "", //手续费
        totalChargeRate: "", //总运费 加上手续费的

        destinationCityName: "", //收货城市
        destinationContactEmail: "", //收货人邮箱
        destinationAddressLine1: "", //收货地址1
        destinationContactName: "", //收货联系人
        destinationContactPhone: "US", //收货人电话
        destinationLocationName: "", //收货地址名称
        destinationStateCode: "", //收货地址州code
        destinationType: "", //收货地址类型，BUSINESS,RESIDENTIAL,CONSTRUCTIONSITE,TRADESHOW
        destinationZipCode: "", //收货地址邮政编码
        destinationMemo: "", //收货备注
        destinationTimeFrom: "", //送货时间段(开始)
        destinationTimeTo: "", //送货时间段(结束)
        destinationSpecificIds: "", //收货地址特殊要求，“3，4”, 如果 地址类型：RESIDENTIAL 必须勾选“4，5”，其他视需要选择
        deliveryNumber: "", //提货码

        originAddressLine1: "", //发货地址1
        originCityName: "", //发货城市
        originContactEmail: "", //发货联系人邮箱
        originContactName: "", //发货联系人
        originContactPhone: "US", //发货人电话
        originDate: "", //发货时间，“2020-10-10”
        originLocationName: "", //发货地址名称
        originStateCode: "", //发货州代码
        originType: "", //发货地址类型，BUSINESS,RESIDENTIAL,CONSTRUCTIONSITE,TRADESHOW
        originZipCode: "", //发货邮编
        originMemo: "", //发货备注
        originTimeFrom: "", //取货时间段(开始)
        originTimeTo: "", //取货时间段(结束)
        originSpecificIds: "", //发货地址特殊要求（1=INSIDEPICKUP 2=LIFTGATEREQUIRED）如果发货地址类型：RESIDENTIAL  必须勾选 “2”，其他视需要选择
        pickupNumber: "", //取货码

        selectMode: "", //报价类型：LTL=零散，TL=整车
        platformId: "", //平台ID 登录接口返回了此值
        referenceNumber: "", //参数编码

        commodityList: [
          {
            commodity: "", //货物描述
            handlingUnits: "", //托运数量
            height: "", //高 (in)
            length: "", //长（in）
            packageTypeId: 2, //包装类型 id
            quantity: "", //货物数量
            unitTypeId: "3", //托运类型id
            weight: "", //总重量（lb）
            width: "", //宽 (in)
            isHazmat: 0, // 是否危险品 0无1有
            declaredValue: "", //货值
            nmfcNumber: "", //NMFC编号
            dengji: '',// 等级
          },
        ], //货物集合
      },
      originSpecificList: [],// 发货地址特殊要求
      destinationSpecificIdsList: [],// 收货地址特殊要求
      rules: {
        originLocationName: [{ required: true, message: "请输入地址名称", trigger: "blur" }],
        originType: [{ required: true, message: "请选择地址类型", trigger: "change" }],
        originAddressLine1: [{ required: true, message: "请输入地址1", trigger: "blur" }],
        originCityName: [{ required: true, message: "请输入城市", trigger: "blur" }],
        originTimeFrom: [{ required: true, message: "请选择取货时间段", trigger: "change" }],
        pickupNumber: [{ required: true, message: "请输入取货码", trigger: "blur" }],
        originTime: [{ required: true, message: "请输入取货时间段", trigger: "change" }],
        originContactName: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        originContactPhone: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
        originContactEmail: [{ required: true, message: "请输入联系邮箱", trigger: "blur" }],
        orderId: [{ required: true, message: "请输入订单号", trigger: "blur" }],
        referenceNumber: [{ required: true, message: "请输入参考编号", trigger: "blur" }],
        originMemo: [{ required: true, message: "请输入备注", trigger: "blur" }],

        destinationLocationName: [{ required: true, message: "请输入地址名称", trigger: "blur" }],
        destinationType: [{ required: true, message: "请选择地址类型", trigger: "change" }],
        destinationAddressLine1: [{ required: true, message: "请输入地址1", trigger: "blur" }],
        destinationCityName: [{ required: true, message: "请输入城市", trigger: "blur" }],
        deliveryNumber: [{ required: true, message: "请输入交货码", trigger: "blur" }],
        destinationTimeFrom: [{ required: true, message: "请输入交货时间段", trigger: "change" }],
        destinationContactName: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        destinationContactPhone: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
        destinationContactEmail: [{ required: true, message: "请输入联系邮箱", trigger: "blur" }],
        destinationMemo: [{ required: true, message: "请输入备注", trigger: "blur" }],

        commodity: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        quantity: [{ required: true, message: "请输入商品数量", trigger: "blur" }],
        packageTypeId: [{ required: true, message: "请选择商品单位", trigger: "change" }],
        handlingUnits: [{ required: true, message: "请输入打托数量", trigger: "blur" }],
        unitTypeId: [{ required: true, message: "请选择打托类型", trigger: "change" }],
        declaredValue: [{required: true, message: "请输入货值", trigger: "blur"}],
        length: [{ required: true, message: "请输入长", trigger: "blur" }],
        width: [{ required: true, message: "请输入宽", trigger: "blur" }],
        height: [{ required: true, message: "请输入高", trigger: "blur" }],
        weight: [{ required: true, message: "请输入重量", trigger: "blur" }],
        unNumber: [{ required: true, message: "请输入危险品UN编号", trigger: "blur" }],
        bclass: [{ required: true, message: "请选择危险产品类别别号", trigger: "change" }],
        poison: [{ required: true, message: "请选择是否有毒", trigger: "change" }],
        hazmatContactName: [{ required: true, message: "请输入紧急联系人", trigger: "blur" }],
        hazmatPhone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
      },
      id: "",
      info: {},// 本地存储的数据
      operation: {}, //运营商数据
      user_balance: '',
      orderId: '',
      calcList: [], // 计算的值
      isPayOrder: true, // 是否下单订单
      selectMode: ''
    };
  },
  mounted() {
    if (!validatenull(this.record)) {
      this.id = this.record.order_id;
    }
  },
  created() {
    this.addForm()
  },
  methods: {
    changeType(){
      this.isPayOrder = false
    },
    blurType(){
      this.isPayOrder = false   
    },
    calculation1(index){
      this.isPayOrder = false   
      this.calculation(index)
    },
    // 计算
    calculation(index) {
      this.calcList = this.form.commodityList.map(item => {
        var allTi = 0 // 总体积
        var ami = 0
        if(item.length != '' && item.width != '' && item.height != '' && item.handlingUnits != ''){
          allTi = (Number(item.length) * Number(item.width) * Number(item.height) * Number(item.handlingUnits) / 1728).toFixed(2)
          if(item.weight != '' && allTi != 0){
            ami = (Number(item.weight) / Number(allTi)).toFixed(2)
          }
        }
        
        return({
          allTi,
          ami,
          weight: item.weight,
        })
      })
      if(index || index == 0){
        var obj = this.form.commodityList[index]
        if(obj.length != '' && obj.width != '' && obj.height != '' && obj.handlingUnits != '' && obj.weight != ''){
          rapiddealsClass({
            length: obj.length, //单个托盘的 长 （整型）
            width: obj.width, //单个托盘的 宽 （整型）
            height: obj.height, //单个托盘的 高（整型）
            weight: obj.weight, //总重量  （整型）
            unitType: this.unitType == "in/lb" ? 0 : 1, //计算类型  0 （in/lb） 1 (cm/kg) （整型）
            handlingUnits: obj.handlingUnits, //托盘数量 （整型）
          }).then(res => {
            this.form.commodityList[index].dengji = res.data.suggestedClass
          })
        }
      }
    },
    // 选择产品
    selectProduct() {
      this.productVisible = true;
    },
    // 关闭产品弹窗
    closeProductDialog() {
      this.productVisible = false;
    },
    // 获取产品
    editSuccess(row) {
      this.productVisible = false;
      let obj = {
        commodity: row.name, //货物描述
        handlingUnits: 1, //托运数量
        height: Number(row.height).toFixed(0), //高 (in)
        length: Number(row.length).toFixed(0), //长（in）
        packageTypeId: row.product_unit ? row.product_unit : 2, //包装类型 id (固定传11)
        quantity: 1, //货物数量
        unitTypeId: row.battering_type ? row.battering_type : '3', //托运类型id （3=PALLETS，8=CRATES）
        weight: Number(row.weight).toFixed(0), //总重量（lb）
        width: Number(row.width).toFixed(0), //宽 (in)
        isHazmat: 0, // 是否危险品 0无1有
        declaredValue: "", //货值
        nmfcNumber: "", //NMFC编号
        dengji: "", // 等级
      };
      this.form.commodityList.push(obj);
      this.calculation(this.form.commodityList.length - 1)
    },
    // 添加包裹
    addParcel() {
      let obj = {
        commodity: "", //货物描述
        handlingUnits: "", //托运数量
        height: "", //高 (in)
        length: "", //长（in）
        packageTypeId: 2, //包装类型 id (固定传11)
        quantity: "", //货物数量
        unitTypeId: "3", //托运类型id （3=PALLETS，8=CRATES）
        weight: "", //总重量（lb）
        width: "", //宽 (in)
        isHazmat: 0, // 是否危险品 0无1有
        declaredValue: "", //货值
        nmfcNumber: "", //NMFC编号
        dengji: '',// 等级
      };
      this.form.commodityList.push(obj);
    },
    // 删除包裹
    delParcel(num) {
      this.form.commodityList.splice(num, 1);
      this.calculation();
    },
    // 下单 
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = {
            ...this.form,
            ...this.operation,
            originSpecificIds: originSpeciFindValues(this.originSpecificList),
            destinationSpecificIds: destSpeciFindValues(this.destinationSpecificIdsList),
            orderId: this.orderId,
            u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
            platformId: window.localStorage.getItem("platform_id"), //平台ID 登录接口返回了此值
          };
          this.orderLoading = true;
          create(form)
            .then((res) => {
              this.orderLoading = false;
              if (res.code == 1) {
                this.$message.success(res.msg);
                this.$router.push({ path: "/ltl/orderList" });
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.orderLoading = false;
              this.$message.error("获取报价失败");
            });
        } else {
          return false;
        }
      });
    },
    // 重新估算
    submitEstForm(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.orderLoading = true;
          if(this.selectMode == 'LTL'){
            ltlRate({
              ...this.form,
              originSpecificIds: originSpeciFindValues(this.originSpecificList),
              destinationSpecificIds: destSpeciFindValues(this.destinationSpecificIdsList),
              u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
              platformId: window.localStorage.getItem("platform_id"), //平台ID 登录接口返回了此值  
            }).then((res) => {
              this.orderLoading = false;
              if (res.code == 1) {
                this.$message.success(res.msg);
                window.localStorage.setItem("orderResultInfo", JSON.stringify(res));
                this.$router.push({ path: '/ltl/orderResult'});
              } else {
                this.$message.error(res.msg);
              }
            }).catch((err) => {
              this.orderLoading = false;
              this.$message.error("获取报价失败");
            });
          }else{
            zltlRate({
              ...this.form,
              u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
              platformId: window.localStorage.getItem("platform_id"), //平台ID 登录接口返回了此值 
            }).then((res) => {
              this.orderLoading = false;
              if (res.code == 1) {
                this.$message.success(res.msg);
                window.localStorage.setItem("orderResultInfo", JSON.stringify(res));
                this.$router.push({ path: '/ltl/orderResult'});
              } else {
                this.$message.error(res.msg);
              }
            }).catch((err) => {
              this.orderLoading = false;
              this.$message.error("获取报价失败");
            });
          }
        }else {
          return false;
        }
      })
    },
    // 初始化第一次添加数据
    addForm(){
      let res = JSON.parse(window.localStorage.getItem("orderResultInfo"));
      let oper = JSON.parse(localStorage.getItem('operation'))
      this.info = res
      this.operation = oper
      this.user_balance = res.user_balance
      this.orderId = res.data.orderId
      this.selectMode = res.send_array.selectMode
      this.isPayOrder = true
      this.form = {
        ...res.send_array,
        phoneCode: "US", 
      }
      this.originSpecificList = res.send_array.originSpecificIds ? res.send_array.originSpecificIds.split(',') : []
      this.destinationSpecificIdsList = res.send_array.destinationSpecificIds ? res.send_array.destinationSpecificIds.split(',') : []
    },
    // 重新获取报价
    async getRates(){
      let res = await (this.send_array.selectMode == 'LTL' ? ltlRate({
        ...this.form,
        u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
        platformId: window.localStorage.getItem("platform_id"), //平台ID 登录接口返回了此值
        originSpecificIds: originSpeciFindValues(this.originSpecificList),
        destinationSpecificIds: destSpeciFindValues(this.destinationSpecificIdsList),
      }) : zltlRate({
        ...this.form,
        u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
        platformId: window.localStorage.getItem("platform_id"), //平台ID 登录接口返回了此值
      }))
      if(res.code == 1){
        window.localStorage.setItem("orderResultInfo", JSON.stringify(res));
        this.$router.push({ path: '/ltl/orderResult'});
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.place {
  background: #fff;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  overflow-y: scroll;
  .place-title {
    width: 100%;
    font-size: 15px;
    line-height: 24px;
    text-align: left;
    color: #409eff;
  }
  .border-col {
    border-bottom: 1px solid #409eff;
    margin-bottom: 10px;
    padding-bottom: 5px;
  }
}
/deep/.el-form-item__content {
  text-align: left;
}
.add-select-btn {
  margin-left: 30px;
}
// 包裹信息
.parcel-item {
  width: 100%;
  background: rgba(64, 158, 255, 0.1);
  border-radius: 5px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .parcel-top {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #bee6f7;
    margin-bottom: 10px;
    padding-bottom: 10px;
    .parcel-title {
      font-size: 14px;
    }
  }
  .parcel-lwh {
    display: flex;
    align-items: center;
    /deep/ input::-webkit-outer-spin-button,
    /deep/ input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }
    /deep/ input[type="number"] {
      -moz-appearance: textfield;
    }
  }
  .place-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .parcel-top-pad{
    box-sizing: border-box;
    padding: 10px;
    margin: 0 !important;
  }
  .parcel-mid-pad{
    box-sizing: border-box;
    padding: 0 10px !important;
    margin: 0 !important;
  }
  .parcel-bot-pad{
    box-sizing: border-box;
    margin: 0 !important;
    border-radius: 0 0 5px 5px;
    overflow: hidden;
  }
}
.tips {
  font-size: 14px;
  color: red;
  padding-left: 10px;
}
.bttn {
  text-align: right;
  margin-top: 10px;
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.free-rate {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 0 10px;
  span {
    color: #e6a23c;
    font-size: 16px;
  }
}
.check-dialog {
  /deep/.el-dialog__body {
    padding: 0 20px !important;
  }
}
// 修改原生el-form-item单个label的位置
.special {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px !important;
  /deep/.el-form-item__label {
    flex: 0 0 auto;
    text-align: left;
    margin-left: 0 !important;
  }
  /deep/.el-form-item__content {
    flex: 1;
    margin-left: 0 !important;
  }
}

.input-with-select /deep/.el-input-group__prepend {
  width: 90px;
  background-color: #fff;
}
// 摘要
.digest {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  .digest-item {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    .digest-item-name {
      text-align: left;
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
    }
    .digest-item-value {
      text-align: left;
      font-size: 14px;
      line-height: 20px;
      color: #5a5b5c;
    }
  }
  .digest-btn{
    margin-top: 40px;
    width: 100%;
  }
}

// 禁用样式
.dis {
  /deep/.el-input__inner {
    background: #f5f7fa;
  }
}
.page-calc{
  width: 100%;
  height: 30px;
  background: #F5F7FA;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .page-calc-item{
    font-size: 12px;
    flex: 1;
    flex-shrink: 0;
    /deep/.el-form-item__content{
      margin-top: 20px;
      margin-left: 10px !important;
    }
  }
}
.dis {
  /deep/.el-input__inner {
    background: #f5f7fa;
  }
}
.start-time {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
