<template>
  <div class="news">
    <div class="news-name">最新通知公告</div>
    <div class="news-item" v-for="(item, index) in newsList" :key="index">
      <i class="el-icon-message-solid" style="font-size: 20px; color: #409eff"></i>
      <div class="news-itme-con">{{ item.title }}</div>
      <div class="news-item-view" @click="handleView(item)">查看详情</div>
    </div>
    <!-- 详情弹窗 -->
    <el-dialog
      title="通知详情"
      append-to-body
      :visible.sync="detailVisible"
      width="1000px"
      :before-close="closeDialog"
    >
      <NewsDetail ref="formModal" v-if="detailVisible" :id="id" append-to-body />
    </el-dialog>
  </div>
</template>

<script>
import NewsDetail from "@/views/ltl/news/newsDetail";
export default {
  components: {
    NewsDetail,
  },
  props: {
    newsList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      detailVisible: false,
      id: "",
    };
  },
  methods: {
    // 打开详情
    handleView(row) {
      this.detailVisible = true;
      this.id = row.id;
    },
    // 关闭详情
    closeDialog() {
      this.detailVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.news {
  height: 400px;
  overflow-y: scroll;
  overflow: hidden;
  &::-webkit-scrollbar {
    display: none; /* 对于 WebKit 浏览器，隐藏滚动条 */
  }
  .news-name{
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
  .news-item {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #bbb;

    .news-itme-con {
      flex: 1;
      box-sizing: border-box;
      padding: 0 10px;
      font-size: 14px;
      color: #101010;
      overflow: hidden; /* 隐藏超出的内容 */
      white-space: nowrap; /* 不允许文本换行 */
      text-overflow: ellipsis; /* 使用省略号表示超出部分 */
    }
    .news-item-view {
      font-size: 14px;
      color: #409eff;
      width: 80px;
      text-align: right;
      cursor: pointer;
    }
  }
}
</style>
