<template>
  <div class="">
    <div class="com-title">工单信息</div>
    <el-descriptions size='small' direction="vertical" :column="4" border>
      <el-descriptions-item label="工单标题" :span="4">{{ info.title }}</el-descriptions-item>
      <el-descriptions-item label="工单号">{{ info.order_sn }}</el-descriptions-item>
      <el-descriptions-item label="工单状态">{{ status(info.status) }}</el-descriptions-item>
      <el-descriptions-item label="提交时间">{{ info.createtime }}</el-descriptions-item>
      <el-descriptions-item label="订单号">18100000000</el-descriptions-item>
      <el-descriptions-item label="描述信息" :span="4">{{ info.describe }}</el-descriptions-item>
      <el-descriptions-item label="附件" :span="4">
        <el-image
          v-for="(item, index) in info.file"
          :key="index"
          style="width: 100px; height: 100px;margin-rgiht: 10px"
          :src="item"
        ></el-image>
      </el-descriptions-item>
    </el-descriptions>
    <el-row :gutter="20" style='margin-top: 10px'>
      <el-col :span="18">
        <div class="com-title">反馈记录</div>
        <div class="com-content" v-if="reply_list.length > 0">
          <div class="com-content-item" :class="item.u_id ? 'x-content-reverse':''" v-for="(item, index) in reply_list" :key="index">
            <el-avatar :size="40">
              <img :src="item.u_id ? require('@/assets/work/avtor.png') : require('@/assets/work/kf.png')"/>
            </el-avatar>
            <div class="com-content-mid" :class="item.u_id ? 'x-content-right':''">
              <div class="com-content-name">{{item.u_id ? item.users.username : item.user.nickname}}</div>
              <div class="com-content-nei">{{ item.content }}</div>
              <div class="com-content-file" v-for="(item1, index1) in item.file" :key="index1" :class="item.u_id ? 'x-content-rr':''">
                <span>{{ item1.slice(-15) }}</span>
                <span style="color: #409EFF;cursor: pointer;" @click='handleFile(item1)'>附件下载</span>
              </div>
            </div>
            <div class="com-content-time">{{ item.createtime }}</div>
          </div>
        </div>
        <el-empty description="暂无记录哦~" v-else></el-empty>
      </el-col>
      <el-col :span="6">
        <div class="com-title">回复信息</div>
        <div>
          <el-form
            :model="form"
            :rules="rules"
            ref="form"
            label-width="100px"
            class="demo-form"
            label-position='top'
            size="small"
          >
            <el-form-item label="描述信息" prop="content">
              <el-input v-model="form.content" type="textarea" placeholder="请输入描述信息"></el-input>
            </el-form-item>
            <el-form-item label="上传文件" prop="file">
              <el-upload
                :class="fileList.length >= 1 ? 'no-upload-file' : ''"
                action="https://admin.bbtexus.com/api/common/upload"
                list-type="picture-card"
                :on-success="handleSuccess"
                :on-remove="handleRemove"
                :file-list="fileList"
                :limit='1'
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
            <el-form-item style="text-align: right;">
              <el-button size="small" type="primary" @click="submitForm('form')" style="width: 100%">回复</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import { workDetail, workReply } from "@/api/ltl/work";
export default {
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      info: {},
      form: {},
      rules: {
        content: [{ required: true, message: "请输入描述信息", trigger: "blur" }],
      },
      fileList: [],
      reply_list: []
    };
  },
  computed: {
    status() {
      return (status) => {
        switch (status) {
          case "1":
            return "进行中";
          case "2":
            return "已解决";
          case "3":
            return "已闭合";
          case "4":
            return "已回复";
        }
      };
    }
  },
  mounted() {
    this.getWorkDetail();
  },
  methods: {
    handleBeforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isLt2M;
    },
    handleSuccess(res) {
      let list = [{
        label: res.data.url,
        value: res.data.fullurl,
        url: res.data.fullurl
      }];
      this.fileList = list;
    },
    handleRemove(file) {
      this.fileList = [];
    },
    getWorkDetail() {
      workDetail({
        work_order_id: this.id,
        u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
      }).then((res) => {
        this.info = res.data;
        this.reply_list = res.data.reply_list ? res.data.reply_list.reverse() : [];
      });
    },
    handleFile(file){
      window.open(file, '_blank')
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = this.form
          workReply({
            work_order_id:this.id,
            u_id: JSON.parse(window.localStorage.getItem("userInfo1")).user_id,
            ...form,
            file: this.fileList.length > 0 ? this.fileList[0].label : "",
          }).then(res => {
            if(res.code == 1){
              this.$message.success(res.msg)
              this.getWorkDetail()
              this.form = {}
              this.fileList = []
              // this.$emit('ok')
            }else{
              this.$message.error(res.msg)
            }
          }).catch(err => {
            this.$message.error(err.msg)
          })
        } else {
          return false;
        }
      });
    },
  },

};
</script>
<style lang="scss" scoped>
  .com-title{
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    line-height: 28px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }
  .com-content{
    height: 300px;
    width: 100%;
    overflow-y: scroll;
    .com-content-item{
      box-sizing: border-box;
      padding: 10px;
      border-radius: 10px;
      background: #DCF2FA;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      .com-content-mid{
        flex: 1;
        box-sizing: border-box;
        padding: 0 10px;
      }
      .com-content-time{
        width: 150px;
        font-size: 14px;
        color: #706C6C;
        text-align: right;
      }
      .com-content-mid{
        flex: 1;
        .com-content-name{
          font-size: 16px;
          font-weight: bold;
          line-height: 20px;
        }
        .com-content-nei{
          font-size: 14px;
          line-height: 20px;
        }
        .com-content-file{
          width: 250px;
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          margin-top: 10px;
        }
      }
    }
    .x-content-reverse{
      flex-direction: row-reverse;
    }
    .x-content-right{
      text-align: right;
    }
    .x-content-rr{
      flex-direction: row-reverse;
      text-align: right;
      float: right;
    }
  }
  /deep/.el-form-item__label{
    padding: 0 !important;
  }
  /deep/.el-form-item{
    margin-bottom: 10px;
  }
  ::v-deep .no-upload-file .el-upload--picture-card {
    display: none !important;
  }
  /deep/.el-upload--picture-card{
    width: 100px;
    height: 100px;
    line-height: 98px;
  }
  /deep/.el-upload-list--picture-card .el-upload-list__item{
    width: 100px;
    height: 100px;
  }
</style>
